import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useLocation } from "react-router-dom";
const QRCodeComponenttest = () => {
  const location = useLocation();
  const [qrCodeUrl, setQRCodeUrl] = useState("");
  const [smsqrCodeUrl, setSMSQRCodeUrl] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [userCountry, setUserCountry] = useState("");
  const [userIP, setUserIP] = useState("");
  const [uriId, setUriId] = useState(() => {
    const searchParams = new URLSearchParams(location.search);
    const encodedId = searchParams.get("code");
    if (encodedId) {
      return encodedId;
    } else {
      return "";
    }
  });
  useEffect(() => {
    console.log({ uriId });
    const stripNonNumeric = (str) => {
      return str.replace(/\D/g, "");
    };

    // Check if the environment variable is available
    if (!process.env.REACT_APP_REDIRECT_PHONE) {
      console.error("REACT_APP_REDIRECT_PHONE environment variable not found.");
      return;
    }

    // Detect if the user is using a mobile device
    const userAgent = navigator.userAgent;
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      )
    );

    // Fetch user's IP address
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        setUserIP(data.ip);
        // Fetch geolocation information based on the user's IP address
        fetch(
          `https://ipinfo.io/${data.ip}/json?token=${process.env.REACT_APP_IP_TOKEN}`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("data", data);
            setUserCountry(data.country);
            if (data.country === "US") {
              // setQRCodeUrl(
              //   `sms:${process.env.REACT_APP_REDIRECT_PHONE}&body=Please set up a website for me`
              // );
              const phoneNumber = encodeURIComponent(
                process.env.REACT_APP_REDIRECT_PHONE
              );
              const messageBody = encodeURIComponent(`Recommended by ${uriId}`);
              const qrCodeUrl = `sms:+${stripNonNumeric(
                process.env.REACT_APP_REDIRECT_PHONE
              )}?body=${messageBody}`;
              setQRCodeUrl(qrCodeUrl);

              const userAgent =
                navigator.userAgent || navigator.vendor || window.opera;
              if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
                const smsUri = `sms:+${encodeURIComponent(
                  process.env.REACT_APP_REDIRECT_PHONE
                )}&body=${messageBody}`;
                setSMSQRCodeUrl(smsUri);
              } else if (/android/i.test(userAgent)) {
                const smsUri = `sms:+${phoneNumber}?body=${messageBody}`;
                setSMSQRCodeUrl(smsUri);
              } else {
                const smsUri = `sms:+${phoneNumber}?body=${messageBody}`;
                setSMSQRCodeUrl(smsUri);
              }
            } else if (data.country === "IN") {
              setQRCodeUrl(
                `https://wa.me/${stripNonNumeric(
                  process.env.REACT_APP_REDIRECT_PHONE
                )}?text=${encodeURIComponent(`Recommended by ${uriId}`)}`
              );
              setSMSQRCodeUrl(
                `https://wa.me/${stripNonNumeric(
                  process.env.REACT_APP_REDIRECT_PHONE
                )}?text=${encodeURIComponent(`Recommended by ${uriId}`)}`
              );
              // const phoneNumber = encodeURIComponent(
              //   process.env.REACT_APP_REDIRECT_PHONE
              // );
              // const messageBody = encodeURIComponent(
              //   "Please set up a website for me"
              // );
              // const qrCodeUrl = `sms:+${stripNonNumeric(
              //   process.env.REACT_APP_REDIRECT_PHONE
              // )}?body=${messageBody}`;
              // setQRCodeUrl(qrCodeUrl);

              // const userAgent =
              //   navigator.userAgent || navigator.vendor || window.opera;
              // if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
              //   const smsUri = `sms:+${encodeURIComponent(
              //     process.env.REACT_APP_REDIRECT_PHONE
              //   )}&body=${messageBody}`;
              //   setSMSQRCodeUrl(smsUri);
              // } else if (/android/i.test(userAgent)) {
              //   const smsUri = `sms:+${phoneNumber}?body=${messageBody}`;
              //   setSMSQRCodeUrl(smsUri);
              // } else {
              //   const smsUri = `sms:+${phoneNumber}?body=${messageBody}`;
              //   setSMSQRCodeUrl(smsUri);
              // }
            } else {
              setQRCodeUrl(
                `https://wa.me/${stripNonNumeric(
                  process.env.REACT_APP_REDIRECT_PHONE
                )}?text=${encodeURIComponent(`Recommended by ${uriId}`)}`
              );
              setSMSQRCodeUrl(
                `https://wa.me/${stripNonNumeric(
                  process.env.REACT_APP_REDIRECT_PHONE
                )}?text=${encodeURIComponent(`Recommended by ${uriId}`)}`
              );
            }
          })
          .catch((error) => {
            console.error("Error fetching user's country:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching user's IP Address1:", error);
      });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <h1 className="mb-25 text-center">texpress your thoughts</h1>
      <div>
        {/* {console.log({ qrCodeUrl })}
        {console.log({ smsqrCodeUrl })} */}
        {isMobile ? (
          <h5 className="mb-35">Touch the QR code below</h5>
        ) : (
          <h5 className="mb-35">Scan the QR code below</h5>
        )}
      </div>
      {qrCodeUrl && (
        <a href={smsqrCodeUrl} target="_blank" rel="noopener noreferrer">
          <QRCode size={256} value={qrCodeUrl} />
        </a>
      )}
    </div>
  );
};

export default QRCodeComponenttest;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MessengerMedias from "./messenger_medias";

const formatDate = (date, cal) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (cal === "post") {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const givenDate = new Date(date);
    const localTimezoneOffset = givenDate.getTimezoneOffset();
    const localTime = new Date(
      givenDate.getTime() - localTimezoneOffset * 60000
    );
    const day = localTime.getDate();
    const monthIndex = localTime.getMonth();
    const year = localTime.getFullYear();
    let hours = localTime.getHours();
    let minutes = localTime.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedString = `${day} ${months[monthIndex]} ${year}, ${hours}:${minutes}${ampm}`;
    return formattedString;
  } else {
    return `${monthNames[date.getMonth()]} - ${date.getFullYear()}`;
  }
};

const Messenger = () => {
  const navigate = useNavigate();
  const [subdomain, setSubdomain] = useState("");
  const [user, setUser] = useState(false);
  const [listFolders, setListFolders] = useState([]);
  const [messageList, setMessageList] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [contactList, setContactList] = useState(null);
  const [messageText, setMessageText] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [showContactList, setShowContactList] = useState(true);

  useEffect(() => {
    const currentURL = window.location.hostname;
    const parts = currentURL.split(".");
    if (parts.length >= 1) {
      setSubdomain(parts[0]);
    }
  }, []);

  useEffect(() => {
    fetchFolders();
  }, []);

  useEffect(() => {
    if (Object.keys(listFolders).length > 0) {
      const firstContact = Object.keys(listFolders)[0];
      fetchLogs(firstContact, listFolders[firstContact]);
    }
  }, [listFolders]);

  useEffect(() => {
    const stripNonNumeric = (str) => {
      return str.replace(/\D/g, "");
    };

    // Check if the environment variable is available
    if (!process.env.REACT_APP_REDIRECT_PHONE) {
      console.error("REACT_APP_REDIRECT_PHONE environment variable not found.");
      return;
    }

    // Detect if the user is using a mobile device
    const userAgent = navigator.userAgent;
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      )
    );
  }, []);

  const fetchFolders = async () => {
    try {
      const listResponse = await axios.get(
        `${process.env.REACT_APP_APIURL}list-folders`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      const listdata = listResponse.data;
      const list_folder = JSON.parse(listdata);
      setListFolders(list_folder);
    } catch (error) {
      navigate("/qrcode");
    }
  };

  const fetchLogs = async (user_phone_number, data) => {
    setMessageList([]);
    try {
      const listResponse = await axios.post(
        `${process.env.REACT_APP_APIURL}get-message-logs`,
        {
          user: user_phone_number,
          twilio: process.env.REACT_APP_REDIRECT_PHONE,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const list_message = listResponse.data;
      setMessageList(list_message);
      setSelectedContact(user_phone_number);
      setContactList(data);
      setShowContactList(false);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const getRandomColor = () => Math.floor(Math.random() * 256);

  const generateRandomColor = () => {
    const red = getRandomColor();
    const green = getRandomColor();
    const blue = getRandomColor();
    return [red, green, blue];
  };

  const calculateRandomGradientColors = () => {
    const startColor = generateRandomColor();
    const endColor = generateRandomColor();
    return {
      startColor: `rgb(${startColor.join(", ")})`,
      endColor: `rgb(${endColor.join(", ")})`,
    };
  };

  const { startColor, endColor } = calculateRandomGradientColors();

  const sendMessage = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_APIURL}send-message`,
        {
          to_number: selectedContact,
          from_number: process.env.REACT_APP_REDIRECT_PHONE,
          message_contents: messageText,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // Fetch the logs again to display the new message
      fetchLogs(selectedContact, contactList);
      // Clear the input field
      setMessageText("");
    } catch (error) {
      console.log("Error sending message:", error);
    }
  };

  const handleBackClick = () => {
    setShowContactList(true);
  };

  return (
    <main>
      <section className="tp-shop-area pt-65 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12 col-xl-12">
              <div className="tp-shop-main-wrapper bg-white mt-15">
                <h3 className="text-center mt-35 mb-45">Messenger</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-12 col-md-5 col-lg-5 pr-0"
              style={{
                display:
                  selectedContact && isMobile && !showContactList
                    ? "none"
                    : "block",
              }}
            >
              <div className="card contacts_card mb-3">
                <div className="card-body contacts_cardbody p-0 scrollbar_style">
                  <div>
                    {listFolders
                      ? Object.entries(listFolders).map(
                          ([key, row], rowIndex) => {
                            const { startColor, endColor } =
                              calculateRandomGradientColors();
                            return (
                              <a
                                href="#"
                                className={`link ${
                                  contactList && contactList.Nick === row.Nick
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => fetchLogs(key, row)}
                                key={key}
                              >
                                <div className="media align-items-center position-relative p-3">
                                  <div className="tp_profilephoto_wrapper">
                                    <div
                                      className="tp_profilephoto_imgwrapper"
                                      style={
                                        !row.ProfilePic ||
                                        row.ProfilePic === "None"
                                          ? {
                                              background: `linear-gradient(71deg, ${startColor} 12%, ${endColor} 61%)`,
                                              borderRadius: "50%",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              color: "#fff",
                                              fontSize: "24px",
                                              fontWeight: "bold",
                                              textShadow:
                                                "1px 1px 1px rgba(0, 0, 0, 0.5)",
                                            }
                                          : {}
                                      }
                                    >
                                      {row.ProfilePic &&
                                      row.ProfilePic !== "None" ? (
                                        <img
                                          src={row.ProfilePic}
                                          alt="Profile"
                                          border="0"
                                        />
                                      ) : (
                                        ""
                                        // row.Nick.charAt(0)
                                      )}
                                    </div>
                                  </div>
                                  <div className="media-body">
                                    <div className="f_18 f_bold">
                                      {row.Nick}
                                    </div>
                                    <div className="f_14 mt-1">{key}</div>
                                    <div className="media_txtrgt">
                                      <div className="timetxt pb-1">
                                        {formatDate(row.LastPosted, "post")}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            );
                          }
                        )
                      : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-7 col-lg-7 pl-0">
              <div className="card contacts_card">
                <div className="card-header bg_grey border_radius0">
                  {selectedContact && (
                    <div className="position-relative">
                      <div className="media align-items-center position-relative contact_prof_div">
                        <div className="tp_profilephoto_wrapper">
                          <div
                            className="tp_profilephoto_imgwrapper"
                            style={
                              !contactList.ProfilePic ||
                              contactList.ProfilePic === "None"
                                ? {
                                    background: `linear-gradient(71deg, ${startColor} 12%, ${endColor} 61%)`,
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "#fff",
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                    textShadow:
                                      "1px 1px 1px rgba(0, 0, 0, 0.5)",
                                  }
                                : {}
                            }
                          >
                            {contactList.ProfilePic &&
                            contactList.ProfilePic !== "None" ? (
                              <img
                                src={contactList.ProfilePic}
                                alt="Profile"
                                border="0"
                              />
                            ) : null}
                          </div>
                        </div>
                        <div className="media-body">
                          <div className="f_18 f_bold">{contactList.Nick}</div>
                          <div className="f_sz14 fwt_500 mt-1 f_black">
                            {selectedContact}
                          </div>
                          {isMobile && !showContactList ? (
                            <div className="messenger_bck">
                              <a
                                href="#"
                                className="link"
                                onClick={handleBackClick}
                              >
                                <span className="f_black">
                                  <i className="fa-solid fa-arrow-left-long me-1"></i>
                                  Back
                                </span>
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="card-body converse_cardbody scrollbar_style">
                  <div>
                    {messageList &&
                      messageList.map((msg, index) => (
                        <div className={`${
                          msg.ReceiverID.S ===
                            process.env.REACT_APP_REDIRECT_PHONE ||
                          msg.ReceiverID.S ===
                            "whatsapp:"+
                              process.env.REACT_APP_REDIRECT_PHONE
                            ? "conversebubble_users"
                            : "conversebubble_ours"
                        }`} key={index}>
                          {msg.TextData.S !== null &&
                          msg.TextData.S.length !== 0 ? (
                            <div
                              className={`${
                                msg.ReceiverID.S ===
                                  process.env.REACT_APP_REDIRECT_PHONE ||
                                msg.ReceiverID.S ===
                                  "whatsapp:"+
                                    process.env.REACT_APP_REDIRECT_PHONE
                                  ? "bubble_users"
                                  : "bubble_ours"
                              }`}
                            >
                              <MessengerMedias path={msg.TextData.S} />
                              {msg.MediaData.S && msg.MediaData.S !== "[]" ? (
                                 <MessengerMedias path={msg.MediaData.S.slice(2, -2)} />
                                // <div className="conversebubble_img">
                                //   <img
                                //     src={msg.MediaData.S.slice(2, -2)} // Remove the surrounding quotes and brackets
                                //     alt="Media"
                                //     border="0"
                                //   />
                                // </div>
                              ) : null}
                            </div>
                          ) : null}
                          <div className={`${
                                msg.ReceiverID.S ===
                                  process.env.REACT_APP_REDIRECT_PHONE ||
                                msg.ReceiverID.S ===
                                  "whatsapp:"+
                                    process.env.REACT_APP_REDIRECT_PHONE
                                  ? "conversetime conversetime_users"
                                  : "conversetime conversetime_ours"
                              }`}>
                            {formatDate(msg.Timestamp.S, "post")}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="card-footer px-0">
                  <div className="allcontent_wrapper">
                    <div className="input-group m-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type your message here"
                        value={messageText}
                        onChange={(e) => setMessageText(e.target.value)}
                      />
                      <span className="input-group-text">
                        <a href="#" onClick={sendMessage} className="f_black">
                          <i className="fa-solid fa-paper-plane f_22" />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Messenger;

import React, { Component, useContext, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import faq from "../img/faq.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Helmet } from "react-helmet";
function UpComingFeatures() {
  const navigate = useNavigate();
  const [openCollapse, setOpenCollapse] = useState(null);

  // useEffect(() => {
  //   // Initialize Bootstrap components after the component has mounted
  //   // You can also use other lifecycle methods like componentDidMount for class components
  //   // or the useEffect hook with appropriate dependencies.
  //   window.$(".collapse").collapse(); // Initialize all collapse elements
  // }, []);
  const toggleCollapse = (collapseId) => {
    setOpenCollapse((prevOpenCollapse) =>
      prevOpenCollapse === collapseId ? null : collapseId
    );
  };
  return (
    <>
    <Helmet>
      <title>
      Upcoming Features - Phonebook | Secure My Website, Make it Private
      </title>
      <meta
        name="description"
        content="Ability to provide a keyboard and identify other PhoneBook users. Give a pin and make the PhoneBook private Share a protected site only with certain people."
      />
      <meta
        name="keywords"
        content="safe website, website security, secure a website, how to secure a website, secure my website"
      />
    </Helmet>
  <main>
  {/* shop area start */}
  <section className="tp-shop-area pt-65 pb-120">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12">
          <div className="tp-shop-main-wrapper bg-white p-3">
            <h3 className="text-center mt-35 mb-45">Upcoming Features</h3>
            <div className="allcontent_wrapper">
              <div className="col-12 col-sm-12 col-md-12 col-lg-7 p-0">
                <div className="upcoming_features_list row m-0">
                  <div className="col-12 col-md-6 col-lg-6 upcoming_features_listbrdr">
                    <div className="upcoming_features_item mb-3">
                      <h5 className="w-100">Discover</h5>
                      <p className="mb-0">Ability to provide keyboard and identify other PhoneBooks</p>
                    </div>
                  </div> 
                  <div className="col-12 col-md-6 col-lg-6 upcoming_features_listbrdr">
                    <div className="upcoming_features_item mb-3">
                      <h5 className="w-100">Recommend</h5>
                      <p className="mb-0">Create shortcuts of preferred PhoneBooks and share</p>
                    </div>
                  </div> 
                  <div className="col-12 col-md-6 col-lg-6 upcoming_features_listbrdr">
                    <div className="upcoming_features_item mb-3">
                      <h5 className="w-100">Share</h5>
                      <p className="mb-0">Get copy and also share with Facebook, X etc</p>
                    </div>
                  </div> 
                  <div className="col-12 col-md-6 col-lg-6 upcoming_features_listbrdr">
                    <div className="upcoming_features_item mb-3">
                      <h5 className="w-100">Group</h5>
                      <p className="mb-0">Add other people so they can add content to your PhoneBook</p>
                    </div>
                  </div> 
                  <div className="col-12 col-md-6 col-lg-6 upcoming_features_listbrdr">
                    <div className="upcoming_features_item mb-3">
                      <h5 className="w-100">Multi-Sites</h5>
                      <p className="mb-0">Add other PhoneBooks under one phone number</p>
                    </div>
                  </div> 
                  <div className="col-12 col-md-6 col-lg-6 upcoming_features_listbrdr">
                    <div className="upcoming_features_item mb-3">
                      <h5 className="w-100">Help forum</h5>
                      <p className="mb-0"><span className="f_bold">/forum</span> and post questions which will be answered </p>
                    </div>
                  </div> 
                  <div className="col-12 col-md-6 col-lg-6 upcoming_features_listbrdr">
                    <div className="upcoming_features_item mb-3">
                      <h5 className="w-100">Pricing</h5>
                      <p className="mb-0">-</p>
                    </div>
                  </div> 
                  <div className="col-12 col-md-6 col-lg-6 upcoming_features_listbrdr">
                    <div className="upcoming_features_item mb-3">
                      <h5 className="w-100">Analytics</h5>
                      <p className="mb-0"><span className="f_bold">/analytics</span> and get the stats</p>
                    </div>
                  </div> 
                  <div className="col-12 col-md-6 col-lg-6 upcoming_features_listbrdr">
                    <div className="upcoming_features_item mb-3">
                      <h5 className="w-100">Reminders</h5>
                      <p className="mb-0">Part of settings. One can program how they want to be reminded</p>
                    </div>
                  </div> 
                  <div className="col-12 col-md-6 col-lg-6 upcoming_features_listbrdr">
                    <div className="upcoming_features_item mb-3">
                      <h5 className="w-100">Sponsorships</h5>
                      <p className="mb-0">-</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 upcoming_features_listbrdr">
                    <div className="upcoming_features_item mb-3">
                      <h5 className="w-100">Settings</h5>
                      <p className="mb-0"><span className="f_bold">/settings</span> for changing sitename, profile photo etc</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 upcoming_features_listbrdr">
                    <div className="upcoming_features_item mb-3">
                      <h5 className="w-100">Like</h5>
                      <p className="mb-0">-</p>
                    </div>
                  </div> 
                  <div className="col-12 col-md-6 col-lg-6 upcoming_features_listbrdr">
                    <div className="upcoming_features_item mb-3">
                      <h5 className="w-100">404 Content Day</h5>
                      <p className="mb-0">Get AI to come up with a relevant joke, fact etc</p>
                    </div>
                  </div> 
                  <div className="col-12 col-md-6 col-lg-6 upcoming_features_listbrdr">
                    <div className="upcoming_features_item mb-3">
                      <h5 className="w-100">Search</h5>
                      <p className="mb-0">Powerful search engine</p>
                    </div>
                  </div> 
                  <div className="col-12 col-md-6 col-lg-6 upcoming_features_listbrdr">
                    <div className="upcoming_features_item mb-3">
                      <h5 className="w-100">Pin Post</h5>
                      <p className="mb-0">Pin a given posting for the day for it to appear at the top</p>
                    </div>
                  </div> 
                  <div className="col-12 col-md-6 col-lg-6 upcoming_features_listbrdr">
                    <div className="upcoming_features_item mb-3">
                      <h5 className="w-100">Make it private</h5>
                      <p className="mb-0">Give a pin and make the PhoneBook private</p>
                    </div>
                  </div> 
                  <div className="col-12 col-md-6 col-lg-6 upcoming_features_listbrdr">
                    <div className="upcoming_features_item mb-3">
                      <h5 className="w-100">Selective Share</h5>
                      <p className="mb-0">Share a protected site only with certain people</p>
                    </div>
                  </div> 
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                <div className="content_helpimg"><img src="assets/img/features.png" /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* shop area end */}
</main>
</>
  );
}

export default UpComingFeatures;

import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
const QRCodeComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [qrCodeUrl, setQRCodeUrl] = useState("");
  const [smsqrCodeUrl, setSMSQRCodeUrl] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [userCountry, setUserCountry] = useState("");
  const [userIP, setUserIP] = useState("");
  const [subdomain, setSubdomain] = useState();
  const [listfolders, setListFolers] = useState([]);
  const [user, setUser] = useState();

  const temp_whatsapp = "+16282404194";
  useEffect(() => {
    const stripNonNumeric = (str) => {
      return str.replace(/\D/g, "");
    };

    // Check if the environment variable is available
    if (!process.env.REACT_APP_REDIRECT_PHONE) {
      console.error("REACT_APP_REDIRECT_PHONE environment variable not found.");
      return;
    }

    // Detect if the user is using a mobile device
    const userAgent = navigator.userAgent;
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      )
    );

    // Fetch user's IP address
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        setUserIP(data.ip);
        // Fetch geolocation information based on the user's IP address
        fetch(
          `https://ipinfo.io/${data.ip}/json?token=${process.env.REACT_APP_IP_TOKEN}`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("data", data);
            setUserCountry(data.country);
            if (data.country === "US") {
              // setQRCodeUrl(
              //   `sms:${process.env.REACT_APP_REDIRECT_PHONE}&body=Please set up a website for me`
              // );
              const phoneNumber = encodeURIComponent(
                process.env.REACT_APP_REDIRECT_PHONE
              );
              const messageBody = encodeURIComponent("");
              const qrCodeUrl = `sms:+${stripNonNumeric(
                process.env.REACT_APP_REDIRECT_PHONE
              )}?body=${messageBody}`;
              setQRCodeUrl(qrCodeUrl);

              const userAgent =
                navigator.userAgent || navigator.vendor || window.opera;
              if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
                const smsUri = `sms:+${encodeURIComponent(
                  process.env.REACT_APP_REDIRECT_PHONE
                )}&body=${messageBody}`;
                setSMSQRCodeUrl(smsUri);
              } else if (/android/i.test(userAgent)) {
                const smsUri = `sms:+${phoneNumber}?body=${messageBody}`;
                setSMSQRCodeUrl(smsUri);
              } else {
                const smsUri = `sms:+${phoneNumber}?body=${messageBody}`;
                setSMSQRCodeUrl(smsUri);
              }
            } else if (data.country === "IN") {
              setQRCodeUrl(
                `https://wa.me/${stripNonNumeric(
                  temp_whatsapp
                )}?text=${encodeURIComponent("")}`
              );
              setSMSQRCodeUrl(
                `https://wa.me/${stripNonNumeric(
                  temp_whatsapp
                )}?text=${encodeURIComponent("")}`
              );
            } else {
              setQRCodeUrl(
                `https://wa.me/${stripNonNumeric(
                  temp_whatsapp
                )}?text=${encodeURIComponent("")}`
              );
              setSMSQRCodeUrl(
                `https://wa.me/${stripNonNumeric(
                  temp_whatsapp
                )}?text=${encodeURIComponent("")}`
              );
            }
          })
          .catch((error) => {
            console.error("Error fetching user's country:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching user's IP Address1:", error);
      });
  }, [userCountry]);
  useEffect(() => {
    // Accessing window.location to get the URL
    const currentURL = window.location.hostname;
    const parts = currentURL.split(".");
    if (parts.length >= 1) {
      setSubdomain(parts[0]);
      console.log("parts[0]", parts[0]);
    }
  }, [subdomain]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchData();
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [subdomain]);
  const fetchData = async () => {
    try {
      if (subdomain !== "www" && subdomain !== "phonebook") {
        const response = await axios.post(
          process.env.REACT_APP_APIURL + "read-content-file",
          {
            "site-name": subdomain,
            "start-index": 0,
            "end-index": 1,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        // handleClose(); // This should now work fine since handleClose is defined
        const jsonDataRes = response.data;
        const jsonData = JSON.parse(jsonDataRes);
        console.log(jsonData.user_preferences);
        console.log(jsonData.user_preferences.user_pfp);
        window.localStorage.setItem("domain", subdomain);
        setUser(true);
      } else {
        setUser(false);
        navigate("/qrcode");
      }
    } catch (error) {
      setUser(false);
      navigate("/qrcode");
      console.log("Error fetching data:", error);
    }
  };
  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     if (user === false) {
  //       fetchFolders();
  //     }
  //   }, 1000);
  //   return () => clearTimeout(timeoutId);
  // }, [user]);
  // const fetchFolders = async () => {
  //   try {
  //     const listResponse = await axios.get(
  //       process.env.REACT_APP_APIURL + "list-folders",
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     // handleClose(); // This should now work fine since handleClose is defined
  //     const listdata = listResponse.data;
  //     const list_folder = JSON.parse(listdata);
  //     console.log({ list_folder });
  //     setListFolers(list_folder);
  //   } catch (error) {
  //     navigate("/qrcode");
  //     console.log("Error fetching data:", error);
  //   }
  // };
  // const getRandomColor = () => Math.floor(Math.random() * 256);

  // const generateRandomColor = () => {
  //   const red = getRandomColor();
  //   const green = getRandomColor();
  //   const blue = getRandomColor();
  //   return [red, green, blue];
  // };

  // const calculateRandomGradientColors = () => {
  //   const startColor = generateRandomColor();
  //   const endColor = generateRandomColor();
  //   return {
  //     startColor: `rgb(${startColor.join(", ")})`,
  //     endColor: `rgb(${endColor.join(", ")})`,
  //   };
  // };

  // const { startColor, endColor } = calculateRandomGradientColors();
  // const gradientStyle = {
  //   background: `linear-gradient(71deg, ${startColor} 12%, ${endColor} 61%)`,
  //   // width: "200px",
  //   // height: "200px",
  //   borderRadius: "50%",
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   color: "#fff",
  //   fontSize: "24px",
  //   fontWeight: "bold",
  //   textShadow: "1px 1px 1px rgba(0, 0, 0, 0.5)",
  // };
  return (
    <>
    <Helmet>
      <title>
        Phonebook - Build a Free Website Today Using Text Messaging or
        WhatsApp
      </title>
      <meta
        name="description"
        content="Create and manage websites using Text Messaging or WhatsApp.
Build a free website to express yourself, your thoughts, your mood, your activities, hobbies etc.Unlimited storage. set a custom domain for your site. securely delete your uploads. Share selective posts."
      />
      <meta
        name="keywords"
        content="build a free website, create and manage websites, website creation, easy website builder, free website, text messaging, whatsapp"
      />
    </Helmet>
    <main>
      <section className="tp-shop-area pt-65 pb-120">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-12 col-xl-12">
              <div className="tp-shop-main-wrapper bg-white p-3">
                <div className="qrcode_dsplydiv">
                  <h3 className="mt-25 mb-25 text-center">Create and Manage</h3>
                  <h2 className="mb-15 text-center">WEBSITES</h2>
                  <h1 className="mb-35 text-center">
                    Using Text Messaging or WhatsApp
                  </h1>
                  {isMobile ? (
                    <>
                      <h2 className="mb-25 text-center">
                        Touch this.{" "}
                        {userCountry === "US" ? "Text messaging" : "WhatsApp"}{" "}
                        will open.
                      </h2>
                      <div>
                        <h5 className="mb-35">
                          Send any message to get started
                        </h5>
                      </div>
                      <div className="qrcode_img text-center">
                        <a
                          href={smsqrCodeUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {userCountry === "US" ? (
                            <img
                              src="assets/img/touchthis_sms.png"
                              alt="Touch to Text messaging"
                            />
                          ) : (
                            <img
                              src="assets/img/touchthis_whatsapp.png"
                              alt="Touch to WhatsApp"
                            />
                          )}
                        </a>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* <h1 className="mb-25 text-center">
                        texpress your thoughts
                      </h1> */}
                      <div>
                        <h5 className="mb-35">Scan the QR code below</h5>
                      </div>

                      {qrCodeUrl && (
                        <>
                          <a
                            href={smsqrCodeUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <QRCode size={256} value={qrCodeUrl} />
                          </a>
                          <h3 className="mt-25 mb-25 text-center">
                            texpress your thoughts
                          </h3>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* {user === false ? (
              <div className="col-12 col-lg-5 col-xl-5">
                <div className="tp-shop-main-wrapper bg-white p-3">
                  <div className="phonebook_sitesdsply">
                    <div
                      id="carouselsitesIndicators"
                      className="carousel slide"
                      data-bs-ride="carousel"
                      data-bs-pause="false"
                    >
                      <div className="carousel-inner">
                        {listfolders
                          ? Object.entries(listfolders).map(
                              ([key, row], rowIndex) => (
                                <div
                                  className={`carousel-item ${
                                    rowIndex === 0 ? "active" : ""
                                  }`}
                                  key={key}
                                >
                                  <a class="link_white" href="#">
                                    <div className="phonebook_sitesdsply_item p-5">
                                      <div className="tp_profilephoto_wrapper">
                                        <div
                                          className="tp_profilephoto_imgwrapper"
                                          style={
                                            !row.user_pfp ||
                                            row.user_pfp === "None"
                                              ? gradientStyle
                                              : {}
                                          }
                                        >
                                          {row.user_pfp !== "None" &&
                                          row.user_pfp ? (
                                            <img
                                              src={row.user_pfp}
                                              alt="Profile"
                                              border="0"
                                            />
                                          ) : // <img
                                          //   src="assets/img/avatar1.png"
                                          //   border="0"
                                          // />
                                          null}
                                        </div>
                                      </div>
                                      <h4 className="mt-35 mb-35">
                                        {row.Nick}
                                      </h4>
                                      <p>
                                        {row.user_tagline
                                          ? row.user_tagline
                                          : ""}
                                      </p>
                                    </div>
                                  </a>
                                </div>
                              )
                            )
                          : null}
                      </div>
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselsitesIndicators"
                        data-bs-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        >
                          <i className="fa-solid fa-chevron-left" />
                        </span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselsitesIndicators"
                        data-bs-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        >
                          <i className="fa-solid fa-chevron-right" />
                        </span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )} */}
          </div>
        </div>
      </section>
    </main>
    </>
  );
};

export default QRCodeComponent;

// import React from "react";
// import { Helmet } from "react-helmet";

// function OwnPreview() {
//   const websiteUrl = "https://phonebook-live.phonebook.today/";
//   const image =
//     "https://a0.awsstatic.com/libra-css/images/logos/aws_logo_smile_179x109.png";
//   const title = "PhoneBook";
//   const description = "Hi I'm a nature lover. Enjoy my collections";

//   return (
//     <div>
//       {/* <Helmet> */}
//         <title>{title}</title>
//         <meta name="title" content={title} />
//         <meta name="description" content={description} />
//         <meta property="og:type" content="website" />
//         <meta property="og:url" content={websiteUrl} />
//         <meta property="og:title" content={title} />
//         <meta property="og:description" content={description} />
//         <meta property="og:image" content={image} />
//         <meta property="twitter:card" content="Gallery" />
//         <meta property="twitter:url" content={websiteUrl} />
//         <meta property="twitter:title" content={title} />
//         <meta property="twitter:description" content={description} />
//         <meta property="twitter:image" content={image} />
//         <meta property="twitter:site" content="phonebook"/>
//       {/* </Helmet> */}
//       {/* Your component JSX goes here */}
//     </div>
//   );
// }

// export default OwnPreview;
import React, { useEffect, useState } from "react";
import axios from "axios";

function OwnPreview() {
  const [subdomain, setSubdomain] = useState("");
  const [userPreferences, setUserPreferences] = useState(null);

  useEffect(() => {
    const currentURL = window.location.hostname;
    const parts = currentURL.split(".");
    if (parts.length >= 1) {
      setSubdomain(parts[0]);
    }
  }, []);

  useEffect(() => {
    if (subdomain) {
      fetchData();
    }
  }, [subdomain]);

  const fetchData = async () => {
    try {
      if (subdomain !== "www" && subdomain !== "phonebook") {
        const response = await axios.post(
          process.env.REACT_APP_APIURL + "read-content-file",
          {
            "site-name": subdomain,
            "start-index": 0,
            "end-index": 1,
          },
          {
            headers: {
              "Content-Type": "application/json",
            }
          }
        );
        const jsonData = JSON.parse(response.data);
        setUserPreferences(jsonData.user_preferences);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (userPreferences) {
      const websiteUrl = `${userPreferences.user_nick}.phonebook.today/`;
      const image = userPreferences.user_pfp || "";
      const title = userPreferences.user_nick || "Phonebook";
      const description = userPreferences.user_tagline || "texpress your thoughts";

      document.title = title;

      const metaTags = [
        { name: "title", content: title },
        { name: "description", content: description },
        { property: "og:type", content: "website" },
        { property: "og:url", content: websiteUrl },
        { property: "og:title", content: title },
        { property: "og:description", content: description },
        { property: "og:image", content: image },
        { property: "twitter:card", content: "summary" },
        { property: "twitter:url", content: websiteUrl },
        { property: "twitter:title", content: title },
        { property: "twitter:description", content: description },
        { property: "twitter:image", content: image },
        { property: "twitter:site", content: "phonebook" },
      ];

      metaTags.forEach(({ name, property, content }) => {
        const metaTag = document.createElement("meta");
        if (name) {
          metaTag.name = name;
        }
        if (property) {
          metaTag.setAttribute("property", property);
        }
        metaTag.content = content;
        document.head.appendChild(metaTag);
      });

      return () => {
        metaTags.forEach(({ name, property }) => {
          const metaTag = name
            ? document.querySelector(`meta[name='${name}']`)
            : document.querySelector(`meta[property='${property}']`);
          if (metaTag) {
            document.head.removeChild(metaTag);
          }
        });
      };
    }
  }, [userPreferences]);

  return null; // This component doesn't render anything visible
}

export default OwnPreview;


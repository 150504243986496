import React, { useState, useEffect } from "react";
import axios from "axios";
import $ from "jquery";
import Media from "./media";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import bootstrap from "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle";
import { Modal } from "bootstrap";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import avatar from "../img/avatar1.png";
import PDFViewerComponent from "./pdfviewer";
import Comments from "./comments";
// import {ShareSocial} from 'react-share-social';
import { Helmet } from "react-helmet";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  InstapaperShareButton,
} from "react-share";
import { ShareButton } from "react-share";

const formatDate = (date, cal) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (cal === "cal") {
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const currentDate = new Date();
    const dateToCheck = new Date(date);
    if (dateToCheck.toDateString() === currentDate.toDateString()) {
      return "Today";
    } else {
      const year = date.getFullYear().toString().slice(-2);
      const months = month.toString().slice(0, 3); // Get the month index

      return `${day} ${months} '${year}`;
    }
  } else if (cal === "uri") {
    return `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(
      6,
      8
    )}`;
  } else if (cal === "copy") {
    const dateObject = new Date(date);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
    const day = String(dateObject.getDate()).padStart(2, "0");
    return `${year}${month}${day}`;
  } else if (cal === "post") {
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var givenDate = new Date(date);
    var localTimezoneOffset = givenDate.getTimezoneOffset();

    var localTime = new Date(givenDate.getTime() - localTimezoneOffset * 60000);
    var day = localTime.getDate();
    var monthIndex = localTime.getMonth();
    var year = localTime.getFullYear();

    // Get hours and minutes
    var hours = localTime.getHours();
    var minutes = localTime.getMinutes();

    // Convert hours to 12-hour format and determine AM/PM
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    // Pad minutes with leading zero if needed
    minutes = minutes < 10 ? "0" + minutes : minutes;

    // Construct the formatted date string
    var formattedString =
      day +
      " " +
      months[monthIndex] +
      " " +
      year +
      ", " +
      hours +
      ":" +
      minutes +
      ampm;

    return formattedString;
  } else {
    return `${monthNames[date.getMonth()]} - ${date.getFullYear()}`;
  }
};

function MediaList() {
  const navigate = useNavigate();
  const location = useLocation();
  const [datalist, setData] = useState([]);
  const [subdomain, setSubdomain] = useState(null);
  const [modalImageUrl, setModalImageUrl] = useState("");
  const [modalAudioUrl, setModalAudioUrl] = useState("");
  const [modalVideoUrl, setModalVideoUrl] = useState("");
  const [modalPdfUrl, setModalPdfUrl] = useState("");
  const [modalFileUrl, setModalFileUrl] = useState("");
  const [modalMultiImageUrl, setModalMultiImageUrl] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [daydata, setDaydata] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [modalInstance, setModalInstance] = useState(null);
  const [modalmultiInstance, setModalMultiInstance] = useState(null);
  const [uriId, setUriId] = useState(() => {
    const searchParams = new URLSearchParams(location.search);
    const encodedId = searchParams.get("id");
    if (encodedId) {
      return JSON.parse(atob(encodedId));
    } else {
      return "";
    }
  });
  const [selectDate, setSelectDate] = useState("");
  const [value, onChange] = useState(() => {
    let uridate = location.pathname.split("/")[1];
    var selectDate1 = selectDate;
    console.log("ssssss", selectDate1);

    if (uridate) {
      let formattedDate = formatDate(uridate, "uri");
      return new Date(formattedDate);
    } else if (selectDate1) {
      return selectDate1;
    } else {
      return new Date();
    }
  });
  const [pageIndex, setPageIndex] = useState(0);
  const [viewoption, setViewoption] = useState();
  const [showToast, setShowToast] = useState(false);
  const [profile, setProfile] = useState("");
  const [user_preferences, setUserPreference] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchValueset, setSearchValueset] = useState("");
  const [filteredTopics, setFilteredTopics] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [about_me, setAboutMe] = useState("");

  const [smsqrCodeUrl, setSMSQRCodeUrl] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [userCountry, setUserCountry] = useState("");
  const [userIP, setUserIP] = useState("");
  const [comments, setComments] = useState([]);
  const [likesList, setLikes] = useState([]);
  const [commentSectionVisible, setCommentSectionVisible] = useState(false);
  const [likesSectionVisible, setLikesSectionVisible] = useState(false);
  const [isOpen, setIsOpen] = useState({});

  const style = {
    root: {
      background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
      borderRadius: 3,
      border: 0,
      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
      color: "white",
    },
    copyContainer: {
      border: "1px solid blue",
      background: "rgb(0,0,0,0.7)",
    },
    title: {
      color: "aquamarine",
      fontStyle: "italic",
    },
  };
  const openModal = (imageUrl) => {
    setModalImageUrl(imageUrl);
  };

  const openMultiModal = (imageUrl) => {
    setModalMultiImageUrl(imageUrl);
  };
  const openModalPdf = (PdfUrl) => {
    setModalPdfUrl(PdfUrl);
  };
  const openModalFile = (FileUrl) => {
    console.log({ FileUrl });
    setModalFileUrl(FileUrl);
  };
  // useEffect(() => {
  //   console.log("intialized with convoId from conversation", location);
  //   let uridate = location.pathname.split("/")[1];
  //   console.log({ uridate });
  //   if (uridate) {
  //     let formattedDate = formatDate(uridate, "uri");
  //     console.log({ formattedDate });
  //     onChange(new Date(formattedDate));
  //     // handleSubmit();
  //   }
  // }, [location]);

  useEffect(() => {
    // Accessing window.location to get the URL
    const currentURL = window.location.hostname;
    const parts = currentURL.split(".");
    if (parts.length >= 1) {
      setSubdomain(parts[0]);
      window.localStorage.setItem("subdomain", parts[0]);
      console.log("parts[0]", parts[0]);
    }
  }, [subdomain]);
  useEffect(() => {
    if (value instanceof Date && !isNaN(value)) {
      setPageIndex(0);
      const startDate = new Date(value);
      const endDate = new Date(value);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      const formattedStartDate =
        endDate.toISOString().slice(0, 10) + " 00:01:00.000000";
      const formattedEndDate =
        endDate.toISOString().slice(0, 10) + " 23:59:59.000000";
      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
    } else {
      console.log("Invalid date value:", value);
    }
  }, [value]);
  // Function to disable dates after the maximum date
  const tileDisabled = ({ date }) => {
    const maxDate = new Date();
    return date > maxDate;
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchData();
      console.log({ searchValueset });
      console.log({ pageIndex });
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [startDate, endDate, searchValueset]);

  const handleSubmit = () => {
    setData([]);

    // const timeoutId = setTimeout(() => {
    //   fetchData(pageIndex);
    // }, 1000);
    // return () => clearTimeout(timeoutId);
  };
  const ClearSubmit = () => {
    setData([]);
    setPageIndex(0);
    setSearchValueset("");
    setSearchValue("");
    // const timeoutId = setTimeout(() => {
    //   fetchData(pageIndex);
    // }, 1000);
    // return () => clearTimeout(timeoutId);
  };
  const fetchData = async () => {
    try {
      const currentURL = window.location.hostname;
      const parts = currentURL.split(".");
      if (parts.length >= 1 && parts[0] !== "www" && parts[0] !== "phonebook") {
        setSubdomain(parts[0]);
        let currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        let check_date = new Date(value);
        check_date.setHours(0, 0, 0, 0);
        // setSelectDate(value);
        console.log({ pageIndex });
        const response = await axios.post(
          process.env.REACT_APP_APIURL + "read-content-file",
          {
            ...(value &&
            check_date.getTime() === currentDate.getTime() &&
            !searchValueset
              ? {
                  "site-name":parts[0],
                  "start-index": !uriId ? pageIndex * 20 : undefined,
                  "end-index": !uriId ? pageIndex * 20 + 19 : undefined,
                }
              : searchValueset
              ? {
                  "site-name": parts[0],
                  "start-index": pageIndex * 20,
                  "end-index": pageIndex * 20 + 19,
                  topic: searchValueset ? searchValueset : "",
                }
              : {
                  "site-name": parts[0],
                  "start-date": startDate,
                  "end-date": endDate,
                  "start-index": !uriId ? pageIndex * 20 : undefined,
                  "end-index": !uriId ? pageIndex * 20 + 19 : undefined,
                }),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        // handleClose(); // This should now work fine since handleClose is defined
        const jsonDataRes = response.data;
        const jsonData = JSON.parse(jsonDataRes);
        window.localStorage.setItem("domain", parts[0]);
        setProfile(jsonData.user_preferences.user_pfp);
        setUserPreference(jsonData.user_preferences);
        setAboutMe(jsonData.user_preferences.user_tagline);
        console.log({ jsonData });
        // Check if post_content is empty or null
        const isPostContentNull = jsonData.post_content === null;
        const isPostContentEmpty =
          !isPostContentNull && Object.keys(jsonData.post_content).length === 0;
        if (isPostContentNull || isPostContentEmpty) {
          setDaydata(true);
          setViewoption(true);
          setData((prevDataList) => [...prevDataList, ...[]]);
        } else {
          if (Object.keys(jsonData.post_content).length < 20) {
            setViewoption(true);
          } else {
            setViewoption(false);
          }
          const chunks = Object.entries(jsonData.post_content)
            // .reverse()
            .reduce((acc, [key, value], index) => {
              if (
                (value.textual_content !== null ||
                  value.media_metadata.length !== 0) &&
                !(
                  value.textual_content === null &&
                  value.media_metadata.length === 0
                )
              ) {
                const chunkIndex = Math.floor(index / 2);
                if (!acc[chunkIndex]) {
                  acc[chunkIndex] = {};
                }
                acc[chunkIndex][key] = value;
              }
              return acc;
            }, []);
          console.log({ chunks });
          // setData(chunks);
          setData((prevDataList) => [...prevDataList, ...chunks]);
          // Increment page index

          setSelectDate(
            chunks[0][Object.keys(chunks[0])[0]].date
              ? chunks[0][Object.keys(chunks[0])[0]].date
              : value
          );
          const timeoutId = setTimeout(() => {
            // Your code to execute after one second
            scrollToActiveDiv();
          }, 2000);
          chunks.forEach((messages) => {
            if (uriId && messages[uriId]) {
              const message = messages[uriId];
              const media_metadata = message.media_metadata;
              if (media_metadata && media_metadata.length === 1) {
                var extension = media_metadata[0]
                  .split(".")
                  .pop()
                  .toLowerCase();
                if (
                  ["jpeg", "jpg", "png", "webp"].includes(extension) === true
                ) {
                  console.log({ media_metadata });
                  setModalImageUrl(media_metadata[0]);
                  openModalload();
                } else if (
                  ["ogg", "amr", "3gp", "aac", "mpeg", "mp3"].includes(
                    extension
                  ) === true
                ) {
                  setModalAudioUrl(media_metadata[0]);
                  openModalload();
                } else if (
                  ["mp4", "webm", "3gpp"].includes(extension) === true
                ) {
                  setModalVideoUrl(media_metadata[0]);
                  openModalload();
                }
              } else if (media_metadata && media_metadata.length >= 2) {
                setModalMultiImageUrl(media_metadata);
                // console.log({ media_metadata });
                openMultiModalload();
              }
            }
          });
          setPageIndex((prevIndex) => prevIndex + 1);
        }
      } else {
        navigate("/qrcode");
      }
    } catch (error) {
      navigate("/qrcode");
      console.log("Error fetching data:", error);
    }
  };
  // Function to handle copying the link
  const handleCopyLink = async (data) => {
    const linkToCopy = data; // Get the current page URL
    const textField = document.createElement("textarea");
    textField.value = linkToCopy;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    // Optionally, you can display a message to indicate that the link has been copied
    toast.success("Copied Successfully", {
      // position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000, // Adjust the duration as needed
    });
  };
  const scrollToActiveDiv = () => {
    const activeDiv = document.querySelector(".news-content.active");
    if (activeDiv) {
      activeDiv.scrollIntoView();
    }
  };
  const handlePrevious = () => {
    console.log("handlePrevious", selectDate);
    const currentDate = new Date(selectDate); // Parse the date string into a Date object
    currentDate.setDate(currentDate.getDate() - 1); // Subtract one day
    onChange(currentDate);
    setSelectDate(currentDate);
    console.log("handlePrevious1", currentDate);
  };
  const handleNext = () => {
    console.log("handlePrevious", selectDate);
    const currentDate = new Date(selectDate); // Parse the date string into a Date object
    currentDate.setDate(currentDate.getDate() + 1); // Subtract one day
    onChange(currentDate);
    setSelectDate(currentDate);
    console.log("handlePrevious1", currentDate);
  };

  // Function to open the modal
  const openModalload = () => {
    if (modalInstance) {
      modalInstance.show();
    }
  };
  const openMultiModalload = () => {
    if (modalmultiInstance) {
      modalmultiInstance.show();
    }
  };
  // Function to initialize the modal instance
  const initializeModalOnload = () => {
    const modalElement = document.getElementById("modalviewimageonload");
    if (modalElement) {
      const modal = new Modal(modalElement);
      setModalInstance(modal);
    }
  };
  useEffect(() => {
    initializeModalOnload();
  }, []);
  const initializemultiModalOnload = () => {
    const modalElement = document.getElementById("modalmultipleimagesonload");
    if (modalElement) {
      const modal = new Modal(modalElement);
      setModalMultiInstance(modal);
    }
  };
  useEffect(() => {
    initializemultiModalOnload();
  }, []);

  const calculateGradientColors = (length) => {
    const threshold = 1;
    const ratio = Math.min(length / threshold, 1);
    const startColor = [175, 232, 236]; // Lighter color
    const endColor = [32, 189, 204]; // Darker color
    const interpolatedColors = startColor.map((channel, index) => {
      return Math.round(channel + ratio * (endColor[index] - channel));
    });
    const interpolatedColorString = `rgba(${interpolatedColors.join(", ")}, 1)`;

    return interpolatedColorString;
  };

  const getRandomColor = () => Math.floor(Math.random() * 256);

  const generateRandomColor = () => {
    const red = getRandomColor();
    const green = getRandomColor();
    const blue = getRandomColor();
    return [red, green, blue];
  };

  const calculateRandomGradientColors = () => {
    const startColor = generateRandomColor();
    const endColor = generateRandomColor();
    return {
      startColor: `rgb(${startColor.join(", ")})`,
      endColor: `rgb(${endColor.join(", ")})`,
    };
  };

  const length = datalist ? datalist * 2 : 0; // Example length
  const { startColor, endColor } = calculateRandomGradientColors();

  const gradientStyle = {
    background: `linear-gradient(71deg, ${startColor} 12%, ${endColor} 61%)`,
    // width: "200px",
    // height: "200px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    fontSize: "24px",
    fontWeight: "bold",
    textShadow: "1px 1px 1px rgba(0, 0, 0, 0.5)",
  };

  const closeToast = () => {
    setShowToast(false);
  };

  // Effect to show the toast after 10 seconds
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowToast(true);
    }, 10000);

    // Clear timeout on unmount
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    // Filter user topics based on input value
    const filtered = user_preferences.user_topics
      ? user_preferences.user_topics
          .filter((topic) =>
            topic.toLowerCase().includes(searchValue.toLowerCase())
          )
          .sort((a, b) => a.localeCompare(b))
      : [];

    setFilteredTopics(filtered);
  }, [searchValue, user_preferences.user_topics]);

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
    // if (!e.target.value) {
    //   setPageIndex(0);
    //   setData([]);
    //   setSearchValueset("");
    // }
  };

  const handleSuggestionClick = (data) => {
    console.log("data", data);
    console.log("searchValue", searchValueset);
    setSearchValue(data);
    if (data !== searchValueset) {
      setPageIndex(0);
      setData([]);
      setSearchValue(data);
      const timeoutId = setTimeout(() => {
        setSearchValueset(data);
      }, 1000);
    }

    setShowSuggestions(false); // Hide suggestion div after a suggestion is clicked
  };

  const handleInputFocus = () => {
    setShowSuggestions(true); // Show suggestion div when input field is focused
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the input field and the suggestion div
      if (
        !event.target.classList.contains("search_frmcntrl") &&
        !event.target.closest(".search_suggestion_div")
      ) {
        setShowSuggestions(false);
      }
    };

    // Add event listener for clicks outside the input field and the suggestion div
    document.addEventListener("click", handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const stripNonNumeric = (str) => {
      return str.replace(/\D/g, "");
    };

    // Check if the environment variable is available
    if (!process.env.REACT_APP_REDIRECT_PHONE) {
      console.error("REACT_APP_REDIRECT_PHONE environment variable not found.");
      return;
    }

    // Detect if the user is using a mobile device
    const userAgent = navigator.userAgent;
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      )
    );

    // Fetch user's IP address
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        setUserIP(data.ip);
        // Fetch geolocation information based on the user's IP address
        fetch(
          `https://ipinfo.io/${data.ip}/json?token=${process.env.REACT_APP_IP_TOKEN}`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("data", data);
            setUserCountry(data.country);
          });
      });
  }, [userCountry]);
  const RedirectApp = async (com) => {
    console.log({ com });
    console.log({ userCountry });
    const stripNonNumeric = (str) => {
      return str.replace(/\D/g, "");
    };

    let redirectUrl = "";
    if (userCountry === "US") {
      // setQRCodeUrl(
      //   `sms:${process.env.REACT_APP_REDIRECT_PHONE}&body=Please set up a website for me`
      // );
      const phoneNumber = encodeURIComponent(
        process.env.REACT_APP_REDIRECT_PHONE
      );
      const messageBody = encodeURIComponent(com);
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
        const smsUri = `sms:+${encodeURIComponent(
          process.env.REACT_APP_REDIRECT_PHONE
        )}&body=${messageBody}`;
        redirectUrl = smsUri;
      } else if (/android/i.test(userAgent)) {
        const smsUri = `sms:+${phoneNumber}?body=${messageBody}`;
        redirectUrl = smsUri;
      } else {
        const smsUri = `sms:+${phoneNumber}?body=${messageBody}`;
        redirectUrl = smsUri;
      }
    } else {
      redirectUrl = `https://wa.me/${stripNonNumeric(
        process.env.REACT_APP_REDIRECT_PHONE
      )}?text=${encodeURIComponent(com)}`;
    }
    console.log({ isMobile });
    window.open(redirectUrl, "_blank");
  };
  const fetchComments = async (post_id) => {
    try {
      console.log({ post_id });
      const listResponse = await axios.post(
        process.env.REACT_APP_APIURL + "read-post-comments",
        {
          post_id: post_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // handleClose(); // This should now work fine since handleClose is defined
      const listdata = listResponse.data;
      // const list_comment = JSON.parse(listdata);
      console.log({ listdata });
      // console.log({ list_comment });
      setComments(listdata);
    } catch (error) {
      // navigate("/qrcode");
      // console.log("Error fetching data:", error);
    }
  };
  const toggleCommentSection = (postId) => {
    setCommentSectionVisible(postId);
  };

  const fetchAndToggleComments = async (postId) => {
    await fetchComments(postId); // Assuming fetchComments is defined elsewhere
    toggleCommentSection(postId);
  };

  const toggleDropdown = (postId) => {
    // Close all dropdowns
    const newIsOpen = {};
    Object.keys(isOpen).forEach((key) => {
      newIsOpen[key] = false;
    });

    // Toggle the clicked dropdown
    newIsOpen[postId] = !isOpen[postId];
    setIsOpen(newIsOpen);
  };
  //likes
  const fetchLikes = async (post_id) => {
    try {
      console.log({ post_id });
      const listResponse = await axios.post(
        process.env.REACT_APP_APIURL + "get-likes",
        {
          post_id: post_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // handleClose(); // This should now work fine since handleClose is defined
      const listdata = listResponse.data;
      // const list_comment = JSON.parse(listdata);
      console.log({ listdata });
      // console.log({ list_comment });
      setLikes(listdata);
    } catch (error) {
      // navigate("/qrcode");
      // console.log("Error fetching data:", error);
    }
  };
  const toggleLikeSection = (postId) => {
    setLikesSectionVisible(postId);
  };

  const fetchAndToggleLikes = async (postId) => {
    await fetchLikes(postId); // Assuming fetchLikes is defined elsewhere
    toggleLikeSection(postId);
  };
  return (
    <>
      <Helmet>
        <title>
          Phonebook - Build a Free Website Today Using Text Messaging or
          WhatsApp
        </title>
        <meta
          name="description"
          content="Create and manage websites using Text Messaging or WhatsApp.
Build a free website to express yourself, your thoughts, your mood, your activities, hobbies etc.Unlimited storage. set a custom domain for your site. securely delete your uploads. Share selective posts."
        />
        <meta
          name="keywords"
          content="build a free website, create and manage websites, website creation, easy website builder, free website, text messaging, whatsapp"
        />
      </Helmet>
      <main>
        {/* shop area start */}
        <section className="tp-shop-area pt-130 pb-120">
          <div className="container-fluid">
            <div className="row justify-content-center searchwrapper">
              <div className="col-12 col-lg-4 col-xl-4">
                <div className="position-relative searchmrgntop">
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <i className="fa-solid fa-magnifying-glass crsr_pntr" />
                    </span>
                    <input
                      type="text"
                      className="form-control search_frmcntrl"
                      placeholder="Search"
                      value={searchValue}
                      onChange={handleInputChange}
                      onFocus={handleInputFocus}
                    />
                    {searchValueset && ( // Render close icon if searchValue is not empty
                      <span
                        class="input-group-text bg-white"
                        onClick={() => ClearSubmit()}
                      >
                        <i class="fa-solid fa-xmark crsr_pntr"></i>
                      </span>
                    )}
                  </div>
                  <div
                    className="search_suggestion_div"
                    style={{ display: showSuggestions ? "block" : "none" }}
                  >
                    <div className="search_suggest_list scrollbar_style">
                      {filteredTopics
                        ? filteredTopics.map((data) => (
                            <div
                              className="search_suggest_item"
                              key={data}
                              value={data}
                              onClick={() => handleSuggestionClick(data)}
                            >
                              {data}
                            </div>
                          ))
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-lg-12 col-xl-12">
                <div className="tp-shop-main-wrapper bg-white">
                  <div className="tp_profilephoto_wrapper">
                    <div
                      className="tp_profilephoto_imgwrapper"
                      style={
                        !profile || profile === "None" ? gradientStyle : {}
                      }
                    >
                      {profile !== "None" && profile ? (
                        <img
                          src={profile + "?timestamp=" + new Date().getTime()}
                          alt="Profile"
                          border="0"
                        />
                      ) : (
                        ""
                      )}
                    </div>

                    {/* <div className="w-100 text-center mt-4 mb-25">
                    <h2>
                      Today{" "}
                      <span
                        className="crsr_pntr f_lightblue"
                        data-bs-toggle="modal"
                        data-bs-target="#modalcalendar"
                      >
                        <i className="fa-regular fa-calendar-days ms-3 f_30" />
                      </span>
                    </h2>
                  </div> */}
                  </div>
                  <div className="aboutme_wrapper mt-15">
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6">
                          <p>{about_me}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tp_image_gallery">
                    {/* <div className="container"> */}
                    <section className="white-bg padding-top-bottom">
                      <div className="container">
                        <div className="timeline">
                          {/* <div className="date-title">
                            <span>
                              <a href="#">
                                <span
                                  className="p-0 me-2 datearrow"
                                  data-bs-toggle="modal"
                                  data-bs-target="#modalcalendar"
                                >
                                  <i className="fa-regular fa-arrow-left-long" />
                                </span>
                              </a>
                              {datalist && datalist.length > 0
                                ? formatDate(
                                    new Date(
                                      datalist[0][
                                        Object.keys(datalist[0])[0]
                                      ].date
                                    ),
                                    "cal"
                                  )
                                : formatDate(value, "cal")}

                              <a href="#">
                                <span
                                  className="p-0 ms-2 datearrow"
                                  data-bs-toggle="modal"
                                  data-bs-target="#modalcalendar"
                                >
                                  <i className="fa-regular fa-arrow-right-long" />
                                </span>
                              </a>
                            </span>
                          </div> */}

                          {datalist && datalist.length > 0 ? (
                            datalist.map((row, rowIndex) => (
                              <div className="row" key={rowIndex}>
                                {Object.entries(row).map(
                                  ([postId, postData], index) => {
                                    let extension, extension1;
                                    if (index === 0) {
                                      extension = postData.media_metadata[0]
                                        ? postData.media_metadata[0]
                                            .split(".")
                                            .pop()
                                            .toLowerCase()
                                        : postData.textual_content.length !== 0
                                        ? postData.textual_content
                                            .split(".")
                                            .pop()
                                            .toLowerCase()
                                        : "";
                                    }
                                    if (index === 1) {
                                      extension1 = postData.media_metadata[0]
                                        ? postData.media_metadata[0]
                                            .split(".")
                                            .pop()
                                            .toLowerCase()
                                        : postData.textual_content.length !== 0
                                        ? postData.textual_content
                                            .split(".")
                                            .pop()
                                            .toLowerCase()
                                        : "";
                                    }
                                    return (
                                      <React.Fragment key={postId}>
                                        {index === 0 && (
                                          <div className="col-12 col-sm-12 col-md-6 news-item">
                                            <div
                                              className={`news-content ${
                                                uriId && uriId === postId
                                                  ? "active"
                                                  : ""
                                              }`}
                                            >
                                              {/* Check if the extension is an image */}
                                              {[
                                                "jpeg",
                                                "jpg",
                                                "png",
                                                "webp",
                                              ].includes(extension) === true &&
                                                index === 0 && (
                                                  <>
                                                    {/* Check if there are multiple images */}
                                                    {postData.media_metadata
                                                      .length >= 2 ? (
                                                      <div>
                                                        <div
                                                          id={`carouselphotoIndicators${rowIndex}${index}`}
                                                          className="carousel slide"
                                                          data-bs-ride="carousel"
                                                          data-bs-pause="false"
                                                        >
                                                          <div
                                                            className="carousel-inner"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#modalmultipleimages"
                                                            onClick={() =>
                                                              openMultiModal(
                                                                postData.media_metadata
                                                              )
                                                            }
                                                          >
                                                            {postData.media_metadata.map(
                                                              (
                                                                rows,
                                                                metaindex
                                                              ) => (
                                                                <div
                                                                  key={
                                                                    metaindex
                                                                  }
                                                                  className={`carousel-item ${
                                                                    metaindex ===
                                                                    0
                                                                      ? "active"
                                                                      : ""
                                                                  }`}
                                                                >
                                                                  <img
                                                                    className=""
                                                                    src={rows}
                                                                    alt="Media"
                                                                  />
                                                                </div>
                                                              )
                                                            )}
                                                          </div>
                                                          <button
                                                            className="carousel-control-prev"
                                                            type="button"
                                                            data-bs-target={`#carouselphotoIndicators${rowIndex}${index}`}
                                                            data-bs-slide="prev"
                                                          >
                                                            <span
                                                              className="carousel-control-prev-icon"
                                                              aria-hidden="true"
                                                            >
                                                              <i className="fa-solid fa-chevron-left" />
                                                            </span>
                                                            <span className="visually-hidden">
                                                              Previous
                                                            </span>
                                                          </button>
                                                          <button
                                                            className="carousel-control-next"
                                                            type="button"
                                                            data-bs-target={`#carouselphotoIndicators${rowIndex}${index}`}
                                                            data-bs-slide="next"
                                                          >
                                                            <span
                                                              className="carousel-control-next-icon"
                                                              aria-hidden="true"
                                                            >
                                                              <i className="fa-solid fa-chevron-right" />
                                                            </span>
                                                            <span className="visually-hidden">
                                                              Next
                                                            </span>
                                                          </button>
                                                          <div className="dsply_type">
                                                            <i className="fa-regular fa-images me-2" />
                                                            {
                                                              postData
                                                                .media_metadata
                                                                .length
                                                            }
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <div
                                                        className="news-media"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#modalviewimage"
                                                        onClick={() =>
                                                          openModal(
                                                            postData
                                                              .media_metadata[0]
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          className="img-responsive"
                                                          src={
                                                            postData
                                                              .media_metadata[0]
                                                          }
                                                          alt="Media"
                                                        />
                                                      </div>
                                                    )}

                                                    {/* Render textual content if available */}
                                                    {postData.textual_content !==
                                                      null &&
                                                      postData.textual_content
                                                        .length !== 0 && (
                                                        <Media
                                                          path={`${postData.textual_content}`}
                                                        />
                                                      )}
                                                  </>
                                                )}

                                              {/* Check if the extension is txt */}
                                              {index === 0 &&
                                                extension === "txt" && (
                                                  <Media
                                                    path={`${postData.textual_content}`}
                                                  />
                                                )}

                                              {/* Check if the extension is audio */}
                                              {[
                                                "ogg",
                                                "amr",
                                                "3gp",
                                                "aac",
                                                "mpeg",
                                                "mp3",
                                              ].includes(extension) === true &&
                                                index === 0 && (
                                                  <div>
                                                    <div className="videoWrapper audio">
                                                      <audio controls>
                                                        <source
                                                          src={`${postData.media_metadata[0]}`}
                                                          type="audio/mp3"
                                                        />
                                                        Your browser does not
                                                        support the audio
                                                        element.
                                                      </audio>
                                                    </div>
                                                    {/* Render textual content if available */}
                                                    {postData.textual_content !==
                                                      null &&
                                                      postData.textual_content
                                                        .length !== 0 && (
                                                        <Media
                                                          path={`${postData.textual_content}`}
                                                        />
                                                      )}
                                                  </div>
                                                )}

                                              {/* Check if the extension is video */}
                                              {["mp4", "webm", "3gpp"].includes(
                                                extension
                                              ) === true &&
                                                index === 0 && (
                                                  <div>
                                                    <div className="videoWrapper">
                                                      <video controls>
                                                        <source
                                                          src={`${postData.media_metadata[0]}`}
                                                          type="video/mp4"
                                                        />
                                                        Your browser does not
                                                        support the video
                                                        element.
                                                      </video>
                                                    </div>
                                                    {/* Render textual content if available */}
                                                    {postData.textual_content !==
                                                      null &&
                                                      postData.textual_content
                                                        .length !== 0 && (
                                                        <Media
                                                          path={`${postData.textual_content}`}
                                                        />
                                                      )}
                                                  </div>
                                                )}

                                              {/* Check if the extension is pdf */}
                                              {extension === "pdf" &&
                                                index === 0 && (
                                                  <div
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#modalviewpdf"
                                                    onClick={() =>
                                                      openModalPdf(
                                                        postData
                                                          .media_metadata[0]
                                                      )
                                                    }
                                                  >
                                                    <h2 className="news-title">
                                                      <span className="pdf">
                                                        <i className="fa-regular fa-file-pdf" />
                                                      </span>
                                                    </h2>

                                                    {/* Render textual content if available */}
                                                    {postData.textual_content !==
                                                      null &&
                                                      postData.textual_content
                                                        .length !== 0 && (
                                                        <Media
                                                          path={`${postData.textual_content}`}
                                                        />
                                                      )}
                                                  </div>
                                                )}

                                              {/* Render link for other file types */}
                                              {extension !== "pdf" &&
                                                [
                                                  "jpeg",
                                                  "jpg",
                                                  "png",
                                                  "webp",
                                                  "txt",
                                                  "ogg",
                                                  "amr",
                                                  "3gp",
                                                  "aac",
                                                  "mpeg",
                                                  "mp3",
                                                  "mp4",
                                                  "webm",
                                                  "3gpp",
                                                ].includes(extension) ===
                                                  false &&
                                                index === 0 && (
                                                  <div className="alldocs">
                                                    <a
                                                      href={
                                                        postData
                                                          .media_metadata[0]
                                                      }
                                                    >
                                                      <h2 className="news-title">
                                                        <span className="pdf">
                                                          {extension ===
                                                            "doc" ||
                                                          extension ===
                                                            "docx" ||
                                                          extension ===
                                                            "msword" ? (
                                                            <i className="fa-regular fa-file-word attachtype_word" />
                                                          ) : extension ===
                                                              "csv" ||
                                                            extension ===
                                                              "sheet" ||
                                                            extension ===
                                                              "xls" ||
                                                            extension ===
                                                              "ms-excel" ||
                                                            extension ===
                                                              "xlsx" ? (
                                                            <i className="fa-regular fa-file-excel" />
                                                          ) : extension ===
                                                              "pptx" ||
                                                            extension ===
                                                              "ppt" ? (
                                                            <i className="fa-regular fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                          ) : (
                                                            <i className="fa-regular fa-file" />
                                                          )}
                                                        </span>
                                                      </h2>

                                                      {/* Render textual content if available */}
                                                      {postData.textual_content !==
                                                        null &&
                                                        postData.textual_content
                                                          .length !== 0 && (
                                                          <Media
                                                            path={`${postData.textual_content}`}
                                                          />
                                                        )}
                                                    </a>
                                                  </div>
                                                )}

                                              {/* Copy link option */}
                                              <div className="allcontent_wrapper mt-3">
                                                {/* <div className="doc_type">
                                                  <span
                                                    className="crsr_pntr"
                                                    onClick={() =>
                                                      handleCopyLink(
                                                        `https://${
                                                          window.location
                                                            .hostname
                                                        }/${formatDate(
                                                          postData.date,
                                                          "copy"
                                                        )}?id=` +
                                                          btoa(
                                                            JSON.stringify(
                                                              postId
                                                            )
                                                          )
                                                      )
                                                    }
                                                  >
                                                    <i className="fa-regular fa-clipboard f_19" />
                                                  </span>
                                                </div> */}
                                                <div
                                                  className={`doc_type ${
                                                    postData.likes !== "0"
                                                      ? "doc_bg"
                                                      : ""
                                                  }`}
                                                >
                                                  <span className="crsr_pntr">
                                                    <a
                                                      className="link crsr_pntr"
                                                      {...(isMobile && {
                                                        target: "_blank",
                                                      })}
                                                      onClick={
                                                        isMobile
                                                          ? () =>
                                                              RedirectApp(
                                                                `/like ${postId}`
                                                              )
                                                          : undefined
                                                      }
                                                    >
                                                      <i
                                                        className={`${
                                                          postData.likes !== "0"
                                                            ? "fa-solid fa-heart f_red me-1 posrel_top3"
                                                            : "fa-regular fa-heart"
                                                        }`}
                                                        {...(!isMobile && {
                                                          "data-bs-toggle":
                                                            "modal",
                                                          "data-bs-target":
                                                            "#modallikecomment",
                                                        })}
                                                      />
                                                    </a>
                                                    {postData.likes !== "0"
                                                      ? postData.likes
                                                      : ""}
                                                    {postData.likes !== "0" ? (
                                                      <i
                                                        class="fa-solid fa-caret-down f_18 ms-2"
                                                        onClick={() =>
                                                          fetchAndToggleLikes(
                                                            postId
                                                          )
                                                        }
                                                      ></i>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>
                                                </div>
                                                <div className="doc_type doc_bg">
                                                  <span className="crsr_pntr">
                                                    <a
                                                      className="link crsr_pntr"
                                                      {...(isMobile && {
                                                        target: "_blank",
                                                      })}
                                                      onClick={
                                                        isMobile
                                                          ? () =>
                                                              RedirectApp(
                                                                `/comment ${postId} `
                                                              )
                                                          : undefined
                                                      }
                                                    >
                                                      <i
                                                        className="fa-regular fa-note-sticky f_purple me-2 posrel_top3"
                                                        {...(!isMobile && {
                                                          "data-bs-toggle":
                                                            "modal",
                                                          "data-bs-target":
                                                            "#modallikecomment",
                                                        })}
                                                      />
                                                    </a>
                                                    {/* 142 */}
                                                    <i
                                                      class="fa-solid fa-caret-down f_18 ms-2"
                                                      onClick={() =>
                                                        fetchAndToggleComments(
                                                          postId
                                                        )
                                                      }
                                                    ></i>
                                                    {/* <i
                                                    className="fa-solid fa-eye f_14 ms-1 comment_view1"
                                                    onClick={() =>
                                                      fetchAndToggleComments(
                                                        postId
                                                      )
                                                    }
                                                  ></i> */}
                                                  </span>
                                                </div>
                                                <div
                                                  className="doc_type"
                                                  key={`${rowIndex}${index}`}
                                                >
                                                  <div
                                                    className="btn-group share_btngrp"
                                                    id={`share${rowIndex}${index}`}
                                                  >
                                                    <button
                                                      type="button"
                                                      aria-expanded={
                                                        isOpen[postId]
                                                      }
                                                      onClick={() =>
                                                        toggleDropdown(postId)
                                                      }
                                                    >
                                                      <span className="crsr_pntr">
                                                        <i className="fa-solid fa-share-nodes" />
                                                      </span>
                                                    </button>
                                                    <ul
                                                      className={`dropdown-menu mt-4 ${
                                                        isOpen[postId]
                                                          ? "show"
                                                          : ""
                                                      }`}
                                                    >
                                                      <a>
                                                        <span
                                                          className="crsr_pntr"
                                                          onClick={() =>
                                                            handleCopyLink(
                                                              `https://${
                                                                window.location
                                                                  .hostname
                                                              }/${formatDate(
                                                                postData.date,
                                                                "copy"
                                                              )}?id=` +
                                                                btoa(
                                                                  JSON.stringify(
                                                                    postId
                                                                  )
                                                                )
                                                            )
                                                          }
                                                        >
                                                          <i className="fa-regular fa-clipboard f_19" />
                                                        </span>
                                                      </a>
                                                      &nbsp;
                                                      <a href="#">
                                                        <WhatsappShareButton
                                                          url={
                                                            `https://${
                                                              window.location
                                                                .hostname
                                                            }/${formatDate(
                                                              postData.date,
                                                              "copy"
                                                            )}?id=` +
                                                            btoa(
                                                              JSON.stringify(
                                                                postId
                                                              )
                                                            )
                                                          }
                                                        >
                                                          <i className="fa-brands fa-whatsapp fc_whatsapp" />
                                                        </WhatsappShareButton>
                                                      </a>
                                                      &nbsp;
                                                      <a href="#">
                                                        <FacebookShareButton
                                                          url={
                                                            `https://${
                                                              window.location
                                                                .hostname
                                                            }/${formatDate(
                                                              postData.date,
                                                              "copy"
                                                            )}?id=` +
                                                            btoa(
                                                              JSON.stringify(
                                                                postId
                                                              )
                                                            )
                                                          }
                                                        >
                                                          <i className="fa-brands fa-facebook-f fc_facebook" />
                                                        </FacebookShareButton>
                                                      </a>
                                                      &nbsp;
                                                      <a href="#">
                                                        <TwitterShareButton
                                                          url={
                                                            `https://${
                                                              window.location
                                                                .hostname
                                                            }/${formatDate(
                                                              postData.date,
                                                              "copy"
                                                            )}?id=` +
                                                            btoa(
                                                              JSON.stringify(
                                                                postId
                                                              )
                                                            )
                                                          }
                                                        >
                                                          <i className="fa-brands fa-x-twitter fc_twitter" />
                                                        </TwitterShareButton>
                                                      </a>
                                                      &nbsp;
                                                      <a href="#">
                                                        <InstapaperShareButton
                                                          url={
                                                            `https://${
                                                              window.location
                                                                .hostname
                                                            }/${formatDate(
                                                              postData.date,
                                                              "copy"
                                                            )}?id=` +
                                                            btoa(
                                                              JSON.stringify(
                                                                postId
                                                              )
                                                            )
                                                          }
                                                        >
                                                          <i className="fa-brands fa-instagram fc_instagram f_22" />
                                                        </InstapaperShareButton>
                                                      </a>
                                                      &nbsp;
                                                      <a href="#">
                                                        <LinkedinShareButton
                                                          url={
                                                            `https://${
                                                              window.location
                                                                .hostname
                                                            }/${formatDate(
                                                              postData.date,
                                                              "copy"
                                                            )}?id=` +
                                                            btoa(
                                                              JSON.stringify(
                                                                postId
                                                              )
                                                            )
                                                          }
                                                        >
                                                          <i className="fa-brands fa-linkedin-in fc_linkedin f_22" />
                                                        </LinkedinShareButton>
                                                      </a>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                              {/*likes list*/}
                                              {likesSectionVisible ===
                                                postId && (
                                                <div
                                                  className="comment_overlay scrollbar_style like_section1"
                                                  style={{
                                                    display: likesSectionVisible
                                                      ? "block"
                                                      : "none",
                                                  }}
                                                >
                                                  <div className="card bg_transparent">
                                                    <div className="card-header nobrdr_rds brdr_btm_line post_comments_header">
                                                      <div className="d-flex flex-row flex-wrap w-100">
                                                        <div className="col-7 f_white f_20">
                                                          Likes
                                                        </div>
                                                        <div className="col-5 justify-content-end txtalgn_rgt">
                                                          <a
                                                            className="like_close1 crsr_pntr"
                                                            onClick={
                                                              toggleLikeSection
                                                            }
                                                          >
                                                            <i className="fa-solid fa-xmark f_white f_20 pt-1" />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="card-body">
                                                      <div className="post_comments_list">
                                                        {likesList &&
                                                        Object.keys(likesList)
                                                          .length !== 0 ? (
                                                          Object.entries(
                                                            likesList
                                                          ).map(
                                                            (
                                                              [key, row],
                                                              rowIndex
                                                            ) => {
                                                              const {
                                                                startColor,
                                                                endColor,
                                                              } =
                                                                calculateRandomGradientColors(); // Calculate colors dynamically
                                                              return (
                                                                <div
                                                                  className="post_comments_item mb-2"
                                                                  key={`${key}`}
                                                                >
                                                                  <div className="post_comments_thumb">
                                                                    <div className="tp_profilephoto_wrapper">
                                                                      <div
                                                                        className="tp_profilephoto_imgwrapper"
                                                                        style={
                                                                          !row.ProfilePic ||
                                                                          row.ProfilePic ===
                                                                            "None"
                                                                            ? {
                                                                                background: `linear-gradient(71deg, ${startColor} 12%, ${endColor} 61%)`,
                                                                                borderRadius:
                                                                                  "50%",
                                                                                display:
                                                                                  "flex",
                                                                                justifyContent:
                                                                                  "center",
                                                                                alignItems:
                                                                                  "center",
                                                                                color:
                                                                                  "#fff",
                                                                                fontSize:
                                                                                  "24px",
                                                                                fontWeight:
                                                                                  "bold",
                                                                                textShadow:
                                                                                  "1px 1px 1px rgba(0, 0, 0, 0.5)",
                                                                              }
                                                                            : {}
                                                                        }
                                                                      >
                                                                        {row.ProfilePic !==
                                                                          "None" &&
                                                                        row.ProfilePic ? (
                                                                          <img
                                                                            src={
                                                                              row.ProfilePic
                                                                            }
                                                                            alt="Profile"
                                                                            border="0"
                                                                          />
                                                                        ) : null}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="post_comments_content">
                                                                    <div className="f_grey">
                                                                      {formatDate(
                                                                        row.LikeDate,
                                                                        "post"
                                                                      )}
                                                                    </div>
                                                                    <p className="w-100 mt-0">
                                                                      {
                                                                        row.LikerNick
                                                                      }
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              );
                                                            }
                                                          )
                                                        ) : (
                                                          <div className="w-100 text-center py-3 f_17 f_white">
                                                            No likes yet
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                              {/*comment list*/}
                                              {commentSectionVisible ===
                                                postId && (
                                                <div
                                                  className="comment_overlay scrollbar_style comment_section1"
                                                  style={{
                                                    display:
                                                      commentSectionVisible
                                                        ? "block"
                                                        : "none",
                                                  }}
                                                >
                                                  <div className="card bg_transparent">
                                                    <div className="card-header nobrdr_rds brdr_btm_line post_comments_header">
                                                      <div className="d-flex flex-row flex-wrap w-100">
                                                        <div className="col-7 f_white f_20">
                                                          Comments
                                                        </div>
                                                        <div className="col-5 justify-content-end txtalgn_rgt">
                                                          <a
                                                            className="comment_close1 crsr_pntr"
                                                            onClick={
                                                              toggleCommentSection
                                                            }
                                                          >
                                                            <i className="fa-solid fa-xmark f_white f_20 pt-1" />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="card-body">
                                                      <div className="post_comments_list">
                                                        {comments &&
                                                        Object.keys(comments)
                                                          .length !== 0 ? (
                                                          Object.entries(
                                                            comments
                                                          ).map(
                                                            (
                                                              [key, row],
                                                              rowIndex
                                                            ) => {
                                                              const {
                                                                startColor,
                                                                endColor,
                                                              } =
                                                                calculateRandomGradientColors(); // Calculate colors dynamically
                                                              return (
                                                                <div
                                                                  className="post_comments_item mb-2"
                                                                  key={`${key}`}
                                                                >
                                                                  <div className="post_comments_thumb">
                                                                    <div className="tp_profilephoto_wrapper">
                                                                      <div
                                                                        className="tp_profilephoto_imgwrapper"
                                                                        style={
                                                                          !row.ProfilePic ||
                                                                          row.ProfilePic ===
                                                                            "None"
                                                                            ? {
                                                                                background: `linear-gradient(71deg, ${startColor} 12%, ${endColor} 61%)`,
                                                                                borderRadius:
                                                                                  "50%",
                                                                                display:
                                                                                  "flex",
                                                                                justifyContent:
                                                                                  "center",
                                                                                alignItems:
                                                                                  "center",
                                                                                color:
                                                                                  "#fff",
                                                                                fontSize:
                                                                                  "24px",
                                                                                fontWeight:
                                                                                  "bold",
                                                                                textShadow:
                                                                                  "1px 1px 1px rgba(0, 0, 0, 0.5)",
                                                                              }
                                                                            : {}
                                                                        }
                                                                      >
                                                                        {row.ProfilePic !==
                                                                          "None" &&
                                                                        row.ProfilePic ? (
                                                                          <img
                                                                            src={
                                                                              row.ProfilePic
                                                                            }
                                                                            alt="Profile"
                                                                            border="0"
                                                                          />
                                                                        ) : null}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="post_comments_content">
                                                                    <div className="f_grey">
                                                                      {
                                                                        row.commenter
                                                                      }{" "}
                                                                      <i className="fa-solid fa-circle f_6" />{" "}
                                                                      {formatDate(
                                                                        row.comment_date,
                                                                        "post"
                                                                      )}
                                                                    </div>

                                                                    {row.comment_content !==
                                                                      null &&
                                                                      row
                                                                        .comment_content
                                                                        .length !==
                                                                        0 && (
                                                                        <Comments
                                                                          comurl={`${row.comment_content}`}
                                                                        />
                                                                      )}
                                                                  </div>
                                                                </div>
                                                              );
                                                            }
                                                          )
                                                        ) : (
                                                          <div className="w-100 text-center py-3 f_17 f_white">
                                                            No comments yet
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                            {/* Timestamp */}
                                            <div class="timestamp pt-2">
                                              {formatDate(
                                                postData.date,
                                                "post"
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        {/* Rightside */}
                                        {index === 1 && (
                                          <div className="col-12 col-sm-12 col-md-6 news-item right">
                                            <div
                                              className={`news-content ${
                                                uriId && uriId === postId
                                                  ? "active"
                                                  : ""
                                              }`}
                                            >
                                              {/* Check if the extension is an image */}
                                              {[
                                                "jpeg",
                                                "jpg",
                                                "png",
                                                "webp",
                                              ].includes(extension1) === true &&
                                              index === 1 ? (
                                                postData.media_metadata
                                                  .length >= 2 ? (
                                                  <div>
                                                    <div
                                                      id={`carouselphotoIndicators${rowIndex}${index}`}
                                                      className="carousel slide"
                                                      data-bs-ride="carousel"
                                                      data-bs-pause="false"
                                                    >
                                                      <div
                                                        className="carousel-inner"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#modalmultipleimages"
                                                        onClick={() =>
                                                          openMultiModal(
                                                            postData.media_metadata
                                                          )
                                                        }
                                                      >
                                                        {postData.media_metadata.map(
                                                          (rows, metaindex) => (
                                                            <div
                                                              key={metaindex}
                                                              className={`carousel-item ${
                                                                metaindex === 0
                                                                  ? "active"
                                                                  : ""
                                                              }`}
                                                            >
                                                              <img
                                                                className=""
                                                                src={rows}
                                                                alt="Media"
                                                              />
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                      <button
                                                        className="carousel-control-prev"
                                                        type="button"
                                                        data-bs-target={`#carouselphotoIndicators${rowIndex}${index}`}
                                                        data-bs-slide="prev"
                                                      >
                                                        <span
                                                          className="carousel-control-prev-icon"
                                                          aria-hidden="true"
                                                        >
                                                          <i className="fa-solid fa-chevron-left" />
                                                        </span>
                                                        <span className="visually-hidden">
                                                          Previous
                                                        </span>
                                                      </button>
                                                      <button
                                                        className="carousel-control-next"
                                                        type="button"
                                                        data-bs-target={`#carouselphotoIndicators${rowIndex}${index}`}
                                                        data-bs-slide="next"
                                                      >
                                                        <span
                                                          className="carousel-control-next-icon"
                                                          aria-hidden="true"
                                                        >
                                                          <i className="fa-solid fa-chevron-right" />
                                                        </span>
                                                        <span className="visually-hidden">
                                                          Next
                                                        </span>
                                                      </button>
                                                      <div className="dsply_type">
                                                        <i className="fa-regular fa-images me-2" />
                                                        {
                                                          postData
                                                            .media_metadata
                                                            .length
                                                        }
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="news-media"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#modalviewimage"
                                                    onClick={() =>
                                                      openModal(
                                                        postData
                                                          .media_metadata[0]
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      className="img-responsive"
                                                      src={
                                                        postData
                                                          .media_metadata[0]
                                                      }
                                                      alt="Media"
                                                    />
                                                  </div>
                                                )
                                              ) : null}
                                              {/* Render textual content if available */}
                                              {index === 1 &&
                                                postData.textual_content !==
                                                  null &&
                                                postData.textual_content
                                                  .length !== 0 && (
                                                  <Media
                                                    path={`${postData.textual_content}`}
                                                  />
                                                )}
                                              {/* Check if the extension is audio */}
                                              {[
                                                "ogg",
                                                "amr",
                                                "3gp",
                                                "aac",
                                                "mpeg",
                                                "mp3",
                                              ].includes(extension1) === true &&
                                              index === 1 ? (
                                                <div>
                                                  <div className="videoWrapper audio">
                                                    <audio controls>
                                                      <source
                                                        src={`${postData.media_metadata[0]}`}
                                                        type="audio/mp3"
                                                      />
                                                      Your browser does not
                                                      support the audio element.
                                                    </audio>
                                                  </div>
                                                  {/* Render textual content if available */}
                                                  {postData.textual_content !==
                                                    null &&
                                                  postData.textual_content
                                                    .length !== 0 ? (
                                                    <Media
                                                      path={`${postData.textual_content}`}
                                                    />
                                                  ) : null}
                                                </div>
                                              ) : null}
                                              {/* Check if the extension is video */}
                                              {["mp4", "webm", "3gpp"].includes(
                                                extension1
                                              ) === true && index === 1 ? (
                                                <div>
                                                  <div className="videoWrapper">
                                                    <video controls>
                                                      <source
                                                        src={`${postData.media_metadata[0]}`}
                                                        type="video/mp4"
                                                      />
                                                      Your browser does not
                                                      support the video element.
                                                    </video>
                                                  </div>
                                                  {/* Render textual content if available */}
                                                  {postData.textual_content !==
                                                    null &&
                                                  postData.textual_content
                                                    .length !== 0 ? (
                                                    <Media
                                                      path={`${postData.textual_content}`}
                                                    />
                                                  ) : null}
                                                </div>
                                              ) : null}
                                              {/* Check if the extension is pdf */}
                                              {extension1 === "pdf" &&
                                              index === 1 ? (
                                                <div
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#modalviewpdf"
                                                  onClick={() =>
                                                    openModalPdf(
                                                      postData.media_metadata[0]
                                                    )
                                                  }
                                                >
                                                  <h2 className="news-title">
                                                    <span className="pdf">
                                                      <i className="fa-regular fa-file-pdf" />
                                                    </span>
                                                  </h2>
                                                  {/* Render textual content if available */}
                                                  {postData.textual_content !==
                                                    null &&
                                                  postData.textual_content
                                                    .length !== 0 ? (
                                                    <Media
                                                      path={`${postData.textual_content}`}
                                                    />
                                                  ) : null}
                                                </div>
                                              ) : null}
                                              {/* Render link for other file types */}
                                              {extension1 !== "pdf" &&
                                              [
                                                "jpeg",
                                                "jpg",
                                                "png",
                                                "webp",
                                                "txt",
                                                "ogg",
                                                "amr",
                                                "3gp",
                                                "aac",
                                                "mpeg",
                                                "mp3",
                                                "mp4",
                                                "webm",
                                                "3gpp",
                                              ].includes(extension1) ===
                                                false &&
                                              index === 1 ? (
                                                <div className="alldocs">
                                                  <a
                                                    href={
                                                      postData.media_metadata[0]
                                                    }
                                                  >
                                                    <h2 className="news-title">
                                                      <span className="pdf">
                                                        {extension1 ===
                                                          "msword" ||
                                                        extension1 === "docx" ||
                                                        extension1 === "doc" ? (
                                                          <i className="fa-regular fa-file-word attachtype_word" />
                                                        ) : extension1 ===
                                                            "csv" ||
                                                          extension1 ===
                                                            "sheet" ||
                                                          extension1 ===
                                                            "xls" ||
                                                          extension1 ===
                                                            "ms-excel" ||
                                                          extension1 ===
                                                            "xlsx" ? (
                                                          <i className="fa-regular fa-file-excel" />
                                                        ) : extension1 ===
                                                            "pptx" ||
                                                          extension1 ===
                                                            "ppt" ? (
                                                          <i className="fa-regular fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                        ) : (
                                                          <i className="fa-regular fa-file" />
                                                        )}
                                                      </span>
                                                    </h2>
                                                    {/* Render textual content if available */}
                                                    {postData.textual_content !==
                                                      null &&
                                                    postData.textual_content
                                                      .length !== 0 ? (
                                                      <Media
                                                        path={`${postData.textual_content}`}
                                                      />
                                                    ) : null}
                                                  </a>
                                                </div>
                                              ) : null}
                                              {/* Copy link option */}
                                              <div className="allcontent_wrapper mt-3">
                                                {/* <div className="doc_type">
                                                  <span
                                                    className="crsr_pntr"
                                                    onClick={() =>
                                                      handleCopyLink(
                                                        `https://${
                                                          window.location
                                                            .hostname
                                                        }/${formatDate(
                                                          postData.date,
                                                          "copy"
                                                        )}?id=` +
                                                          btoa(
                                                            JSON.stringify(
                                                              postId
                                                            )
                                                          )
                                                      )
                                                    }
                                                  >
                                                    <i className="fa-regular fa-clipboard" />
                                                  </span>
                                                </div> */}
                                                <div
                                                  className={`doc_type ${
                                                    postData.likes !== "0"
                                                      ? "doc_bg"
                                                      : ""
                                                  }`}
                                                >
                                                  <span className="crsr_pntr">
                                                    <a
                                                      className="link crsr_pntr"
                                                      {...(isMobile && {
                                                        target: "_blank",
                                                      })}
                                                      onClick={
                                                        isMobile
                                                          ? () =>
                                                              RedirectApp(
                                                                `/like ${postId}`
                                                              )
                                                          : undefined
                                                      }
                                                    >
                                                      <i
                                                        className={`${
                                                          postData.likes !== "0"
                                                            ? "fa-solid fa-heart f_red me-1 posrel_top3"
                                                            : "fa-regular fa-heart"
                                                        }`}
                                                        {...(!isMobile && {
                                                          "data-bs-toggle":
                                                            "modal",
                                                          "data-bs-target":
                                                            "#modallikecomment",
                                                        })}
                                                      />
                                                    </a>
                                                    {postData.likes !== "0"
                                                      ? postData.likes
                                                      : ""}
                                                    {postData.likes !== "0" ? (
                                                      <i
                                                        class="fa-solid fa-caret-down f_18 ms-2"
                                                        onClick={() =>
                                                          fetchAndToggleLikes(
                                                            postId
                                                          )
                                                        }
                                                      ></i>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>
                                                </div>
                                                <div className="doc_type doc_bg">
                                                  <span className="crsr_pntr">
                                                    <a
                                                      className="link crsr_pntr"
                                                      {...(isMobile && {
                                                        target: "_blank",
                                                      })}
                                                      onClick={
                                                        isMobile
                                                          ? () =>
                                                              RedirectApp(
                                                                `/comment ${postId} `
                                                              )
                                                          : undefined
                                                      }
                                                    >
                                                      <i
                                                        className="fa-regular fa-note-sticky f_purple me-1"
                                                        {...(!isMobile && {
                                                          "data-bs-toggle":
                                                            "modal",
                                                          "data-bs-target":
                                                            "#modallikecomment",
                                                        })}
                                                      />
                                                    </a>
                                                    {/* 142 */}
                                                    <i
                                                      class="fa-solid fa-caret-down f_18 ms-2"
                                                      onClick={() =>
                                                        fetchAndToggleComments(
                                                          postId
                                                        )
                                                      }
                                                    ></i>
                                                  </span>
                                                </div>
                                                <div
                                                  className="doc_type"
                                                  key={`${rowIndex}${index}`}
                                                >
                                                  <div
                                                    className="btn-group share_btngrp"
                                                    id={`share${rowIndex}${index}`}
                                                  >
                                                    <button
                                                      type="button"
                                                      aria-expanded={
                                                        isOpen[postId]
                                                      }
                                                      onClick={() =>
                                                        toggleDropdown(postId)
                                                      }
                                                    >
                                                      <span className="crsr_pntr">
                                                        <i className="fa-solid fa-share-nodes" />
                                                      </span>
                                                    </button>
                                                    <ul
                                                      className={`dropdown-menu mt-4 ${
                                                        isOpen[postId]
                                                          ? "show"
                                                          : ""
                                                      }`}
                                                    >
                                                      <a>
                                                        <span
                                                          className="crsr_pntr"
                                                          onClick={() =>
                                                            handleCopyLink(
                                                              `https://${
                                                                window.location
                                                                  .hostname
                                                              }/${formatDate(
                                                                postData.date,
                                                                "copy"
                                                              )}?id=` +
                                                                btoa(
                                                                  JSON.stringify(
                                                                    postId
                                                                  )
                                                                )
                                                            )
                                                          }
                                                        >
                                                          <i className="fa-regular fa-clipboard f_19" />
                                                        </span>
                                                      </a>
                                                      &nbsp;
                                                      <a href="#">
                                                        <WhatsappShareButton
                                                          url={
                                                            `https://${
                                                              window.location
                                                                .hostname
                                                            }/${formatDate(
                                                              postData.date,
                                                              "copy"
                                                            )}?id=` +
                                                            btoa(
                                                              JSON.stringify(
                                                                postId
                                                              )
                                                            )
                                                          }
                                                        >
                                                          <i className="fa-brands fa-whatsapp fc_whatsapp" />
                                                        </WhatsappShareButton>
                                                      </a>
                                                      &nbsp;
                                                      <a href="#">
                                                        <FacebookShareButton
                                                          url={
                                                            `https://${
                                                              window.location
                                                                .hostname
                                                            }/${formatDate(
                                                              postData.date,
                                                              "copy"
                                                            )}?id=` +
                                                            btoa(
                                                              JSON.stringify(
                                                                postId
                                                              )
                                                            )
                                                          }
                                                        >
                                                          <i className="fa-brands fa-facebook-f fc_facebook" />
                                                        </FacebookShareButton>
                                                      </a>
                                                      &nbsp;
                                                      <a href="#">
                                                        <TwitterShareButton
                                                          url={
                                                            `https://${
                                                              window.location
                                                                .hostname
                                                            }/${formatDate(
                                                              postData.date,
                                                              "copy"
                                                            )}?id=` +
                                                            btoa(
                                                              JSON.stringify(
                                                                postId
                                                              )
                                                            )
                                                          }
                                                        >
                                                          <i className="fa-brands fa-x-twitter fc_twitter" />
                                                        </TwitterShareButton>
                                                      </a>
                                                      &nbsp;
                                                      <a href="#">
                                                        <InstapaperShareButton
                                                          url={
                                                            `https://${
                                                              window.location
                                                                .hostname
                                                            }/${formatDate(
                                                              postData.date,
                                                              "copy"
                                                            )}?id=` +
                                                            btoa(
                                                              JSON.stringify(
                                                                postId
                                                              )
                                                            )
                                                          }
                                                        >
                                                          <i className="fa-brands fa-instagram fc_instagram f_22" />
                                                        </InstapaperShareButton>
                                                      </a>
                                                      &nbsp;
                                                      <a href="#">
                                                        <LinkedinShareButton
                                                          url={
                                                            `https://${
                                                              window.location
                                                                .hostname
                                                            }/${formatDate(
                                                              postData.date,
                                                              "copy"
                                                            )}?id=` +
                                                            btoa(
                                                              JSON.stringify(
                                                                postId
                                                              )
                                                            )
                                                          }
                                                        >
                                                          <i className="fa-brands fa-linkedin-in fc_linkedin f_22" />
                                                        </LinkedinShareButton>
                                                      </a>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                              {/*likes list*/}
                                              {likesSectionVisible ===
                                                postId && (
                                                <div
                                                  className="comment_overlay scrollbar_style like_section1"
                                                  style={{
                                                    display: likesSectionVisible
                                                      ? "block"
                                                      : "none",
                                                  }}
                                                >
                                                  <div className="card bg_transparent">
                                                    <div className="card-header nobrdr_rds brdr_btm_line post_comments_header">
                                                      <div className="d-flex flex-row flex-wrap w-100">
                                                        <div className="col-7 f_white f_20">
                                                          Likes
                                                        </div>
                                                        <div className="col-5 justify-content-end txtalgn_rgt">
                                                          <a
                                                            className="like_close1 crsr_pntr"
                                                            onClick={
                                                              toggleLikeSection
                                                            }
                                                          >
                                                            <i className="fa-solid fa-xmark f_white f_20 pt-1" />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="card-body">
                                                      <div className="post_comments_list">
                                                        {likesList &&
                                                        Object.keys(likesList)
                                                          .length !== 0 ? (
                                                          Object.entries(
                                                            likesList
                                                          ).map(
                                                            (
                                                              [key, row],
                                                              rowIndex
                                                            ) => {
                                                              const {
                                                                startColor,
                                                                endColor,
                                                              } =
                                                                calculateRandomGradientColors(); // Calculate colors dynamically
                                                              return (
                                                                <div
                                                                  className="post_comments_item mb-2"
                                                                  key={`${key}`}
                                                                >
                                                                  <div className="post_comments_thumb">
                                                                    <div className="tp_profilephoto_wrapper">
                                                                      <div
                                                                        className="tp_profilephoto_imgwrapper"
                                                                        style={
                                                                          !row.ProfilePic ||
                                                                          row.ProfilePic ===
                                                                            "None"
                                                                            ? {
                                                                                background: `linear-gradient(71deg, ${startColor} 12%, ${endColor} 61%)`,
                                                                                borderRadius:
                                                                                  "50%",
                                                                                display:
                                                                                  "flex",
                                                                                justifyContent:
                                                                                  "center",
                                                                                alignItems:
                                                                                  "center",
                                                                                color:
                                                                                  "#fff",
                                                                                fontSize:
                                                                                  "24px",
                                                                                fontWeight:
                                                                                  "bold",
                                                                                textShadow:
                                                                                  "1px 1px 1px rgba(0, 0, 0, 0.5)",
                                                                              }
                                                                            : {}
                                                                        }
                                                                      >
                                                                        {row.ProfilePic !==
                                                                          "None" &&
                                                                        row.ProfilePic ? (
                                                                          <img
                                                                            src={
                                                                              row.ProfilePic
                                                                            }
                                                                            alt="Profile"
                                                                            border="0"
                                                                          />
                                                                        ) : null}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="post_comments_content">
                                                                    <div className="f_grey">
                                                                      {formatDate(
                                                                        row.LikeDate,
                                                                        "post"
                                                                      )}
                                                                    </div>
                                                                    <p className="w-100 mt-0">
                                                                      {
                                                                        row.LikerNick
                                                                      }
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              );
                                                            }
                                                          )
                                                        ) : (
                                                          <div className="w-100 text-center py-3 f_17 f_white">
                                                            No likes yet
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                              {/*comment list*/}
                                              {commentSectionVisible ===
                                                postId && (
                                                <div
                                                  className="comment_overlay scrollbar_style comment_section1"
                                                  style={{
                                                    display:
                                                      commentSectionVisible
                                                        ? "block"
                                                        : "none",
                                                  }}
                                                >
                                                  <div className="card bg_transparent">
                                                    <div className="card-header nobrdr_rds brdr_btm_line post_comments_header">
                                                      <div className="d-flex flex-row flex-wrap w-100">
                                                        <div className="col-7 f_white f_20">
                                                          Comments
                                                        </div>
                                                        <div className="col-5 justify-content-end txtalgn_rgt">
                                                          <a
                                                            className="comment_close1 crsr_pntr"
                                                            onClick={
                                                              toggleCommentSection
                                                            }
                                                          >
                                                            <i className="fa-solid fa-xmark f_white f_20 pt-1" />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="card-body">
                                                      <div className="post_comments_list">
                                                        {comments &&
                                                        Object.keys(comments)
                                                          .length !== 0 ? (
                                                          Object.entries(
                                                            comments
                                                          ).map(
                                                            (
                                                              [key, row],
                                                              rowIndex
                                                            ) => {
                                                              const {
                                                                startColor,
                                                                endColor,
                                                              } =
                                                                calculateRandomGradientColors(); // Calculate colors dynamically
                                                              return (
                                                                <div
                                                                  className="post_comments_item mb-2"
                                                                  key={`${key}`}
                                                                >
                                                                  <div className="post_comments_thumb">
                                                                    <div className="tp_profilephoto_wrapper">
                                                                      <div
                                                                        className="tp_profilephoto_imgwrapper"
                                                                        style={
                                                                          !row.ProfilePic ||
                                                                          row.ProfilePic ===
                                                                            "None"
                                                                            ? {
                                                                                background: `linear-gradient(71deg, ${startColor} 12%, ${endColor} 61%)`,
                                                                                borderRadius:
                                                                                  "50%",
                                                                                display:
                                                                                  "flex",
                                                                                justifyContent:
                                                                                  "center",
                                                                                alignItems:
                                                                                  "center",
                                                                                color:
                                                                                  "#fff",
                                                                                fontSize:
                                                                                  "24px",
                                                                                fontWeight:
                                                                                  "bold",
                                                                                textShadow:
                                                                                  "1px 1px 1px rgba(0, 0, 0, 0.5)",
                                                                              }
                                                                            : {}
                                                                        }
                                                                      >
                                                                        {row.ProfilePic !==
                                                                          "None" &&
                                                                        row.ProfilePic ? (
                                                                          <img
                                                                            src={
                                                                              row.ProfilePic
                                                                            }
                                                                            alt="Profile"
                                                                            border="0"
                                                                          />
                                                                        ) : null}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="post_comments_content">
                                                                    <div className="f_grey">
                                                                      {
                                                                        row.commenter
                                                                      }{" "}
                                                                      <i className="fa-solid fa-circle f_6" />{" "}
                                                                      {formatDate(
                                                                        row.comment_date,
                                                                        "post"
                                                                      )}
                                                                    </div>
                                                                    {row.comment_content !==
                                                                      null &&
                                                                      row
                                                                        .comment_content
                                                                        .length !==
                                                                        0 && (
                                                                        <Comments
                                                                          comurl={`${row.comment_content}`}
                                                                        />
                                                                      )}
                                                                  </div>
                                                                </div>
                                                              );
                                                            }
                                                          )
                                                        ) : (
                                                          <div className="w-100 text-center py-3 f_17 f_white">
                                                            No comments yet
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                            {/* Timestamp */}
                                            <div className="timestamp txtalgn_rgt pt-2">
                                              {formatDate(
                                                postData.date,
                                                "post"
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </React.Fragment>
                                    );
                                  }
                                )}
                              </div>
                            ))
                          ) : (
                            <>
                              {daydata ? (
                                <div style={{ textAlign: "center" }}>
                                  <p>
                                    No data is available for the selected day.
                                  </p>
                                </div>
                              ) : (
                                <div style={{ textAlign: "center" }}>
                                  <i className="fa-solid fa-spinner fa-spin-pulse fa-3x" />
                                  <p>Loading...</p>
                                </div>
                              )}
                            </>
                          )}

                          {/* <div className="date-title">
                            <span>
                              <a href="#" onClick={() => handlePrevious()}>
                                <span
                                  className="datearrow"
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#modalcalendar"
                                >
                                  <i className="fa-regular fa-arrow-left-long" />
                                </span>
                              </a>
                              <a href="#" onClick={() => handleNext()}>
                                <span
                                  className="datearrow"
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#modalcalendar"
                                >
                                  <i className="fa-regular fa-arrow-right-long" />
                                </span>
                              </a>
                            </span>
                          </div> */}
                          {viewoption === false ? (
                            <div className="date-title">
                              <span
                                className="crsr_pntr"
                                onClick={() => fetchData(pageIndex + 1)}
                              >
                                View More
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </section>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </section>
        {/* model calendar */}
        <div
          className="modal fade"
          id="modalcalendar"
          // data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="modalcalendarLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                // onClick={() => handleClose()}
              >
                <i className="fa-solid fa-xmark" />
              </button>
              <div className="modal-header">
                <h5 className="modal-title" id="modalcalendarLabel">
                  Calendar
                </h5>
              </div>
              <div className="modal-body">
                <div className="elegant-calendar justify-content-center d-md-flex pt-25 pb-25">
                  <div
                    className="wrap-header d-flex align-items-center img"
                    style={{ backgroundImage: "url(plugins/img/bg.jpg)" }}
                  >
                    <p id="reset">Today</p>
                    <div id="header" className="p-0">
                      <div className="head-info text-center">
                        <div className="head-month">{formatDate(value)}</div>
                        <div className="head-day">{value.getDate()}</div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Calendar
                      onChange={onChange}
                      value={value}
                      tileDisabled={tileDisabled}
                    />{" "}
                  </div>
                </div>
                <div className="w-100 d-flex justify-content-center mt-25">
                  <button
                    type="button"
                    className="btn btn-lg btn-success"
                    data-bs-dismiss="modal"
                    onClick={() => handleSubmit()}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* image model */}
        <div
          className="modal fade"
          id="modalviewimage"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="modalviewimageLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className=" fa-solid fa-xmark" />
              </button>

              <div className="modal-body">
                {modalImageUrl ? (
                  <div className="modalviewfullimage">
                    <img src={modalImageUrl} alt />
                  </div>
                ) : modalVideoUrl ? (
                  <div className="videoWrapper">
                    <video controls>
                      <source src={modalVideoUrl} type="video/mp4" />
                      Your browser does not support the video element.
                    </video>
                  </div>
                ) : modalAudioUrl ? (
                  <div className="news-media">
                    <div className="videoWrapper audio">
                      <audio controls>
                        <source src={modalAudioUrl} type="audio/mp3" />
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        {/*multiple image model */}
        <div
          className="modal fade"
          id="modalmultipleimages"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="modalmultipleimagesLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className=" fa-solid fa-xmark" />
              </button>
              {/* <div className="modal-header">
              <h5 className="modal-title" id="modalmultipleimagesLabel">
                Office Interior
              </h5>
            </div> */}
              <div className="modal-body">
                <div
                  id="carouselphotoIndicators1"
                  className="carousel slide"
                  data-bs-ride="carousel"
                  data-bs-pause="false"
                >
                  <div className="carousel-inner">
                    {modalMultiImageUrl.map((rows, metaindex) => (
                      <div
                        key={metaindex}
                        className={`carousel-item ${
                          metaindex === 0 ? "active" : ""
                        }`}
                      >
                        {/* <Media
                        path={`${rows}`}
                      /> */}
                        <img className="" src={rows} alt="Media" />
                      </div>
                    ))}
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselphotoIndicators1"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      >
                        <i className="fa-solid fa-chevron-left" />
                      </span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselphotoIndicators1"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      >
                        <i className="fa-solid fa-chevron-right" />
                      </span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Onload image model */}
        <div
          className="modal fade"
          id="modalviewimageonload"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="modalviewimageLabelonload"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className=" fa-solid fa-xmark" />
              </button>

              <div className="modal-body">
                {modalImageUrl ? (
                  <div className="modalviewfullimage">
                    <img src={modalImageUrl} alt />
                  </div>
                ) : modalVideoUrl ? (
                  <div className="videoWrapper">
                    <video controls>
                      <source src={modalVideoUrl} type="video/mp4" />
                      Your browser does not support the video element.
                    </video>
                  </div>
                ) : modalAudioUrl ? (
                  <div className="news-media">
                    <div className="videoWrapper audio">
                      <audio controls>
                        <source src={modalAudioUrl} type="audio/mp3" />
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        {/* onload multiple image model */}
        <div
          className="modal fade"
          id="modalmultipleimagesonload"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="modalmultipleimagesLabelonload"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className=" fa-solid fa-xmark" />
              </button>
              {/* <div className="modal-header">
              <h5 className="modal-title" id="modalmultipleimagesLabel">
                Office Interior
              </h5>
            </div> */}
              <div className="modal-body">
                <div
                  id="carouselphotoIndicatorsonload"
                  className="carousel slide"
                  data-bs-ride="carousel"
                  data-bs-pause="false"
                >
                  <div className="carousel-inner">
                    {modalMultiImageUrl.map((rows, metaindex) => (
                      <div
                        key={metaindex}
                        className={`carousel-item ${
                          metaindex === 0 ? "active" : ""
                        }`}
                      >
                        {/* <Media
                        path={`${rows}`}
                      /> */}
                        <img className="" src={rows} alt="Media" />
                      </div>
                    ))}
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselphotoIndicatorsonload"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      >
                        <i className="fa-solid fa-chevron-left" />
                      </span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselphotoIndicatorsonload"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      >
                        <i className="fa-solid fa-chevron-right" />
                      </span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* PDF model*/}
        <div
          className="modal fade"
          id="modalviewpdf"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="modalviewpdfLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setModalPdfUrl("")}
              >
                <i className=" fa-solid fa-xmark" />
              </button>

              <div className="modal-body modal_content_hgt">
                {modalPdfUrl ? (
                  <div className="">
                    <PDFViewerComponent pdfUrl={modalPdfUrl} />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        {/* File model*/}
        <div
          className="modal fade"
          id="modalviewfile"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="modalviewfileLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setModalFileUrl("")}
              >
                <i className=" fa-solid fa-xmark" />
              </button>

              <div className="modal-body modal_content_hgt">
                {modalFileUrl ? (
                  <div className="">
                    {/* <FileViewer docxUrl={modalFileUrl} /> */}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        {/*toaster popup */}
        {showToast === true ? (
          <div className="getphonebook_toastcontainer fadeInBottom">
            <div className="getphonebook_toastdiv position-relative">
              <a href="https://www.phonebook.today/" className="f_white">
                Get your own Phonebook today
              </a>
              <div className="getphonebookclose_div">
                <a onClick={closeToast}>
                  <i className=" fa-solid fa-xmark" />
                </a>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* Like/Comment in desktop */}
        <div
          className="modal fade"
          id="modallikecomment"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="modallikecommentLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa-solid fa-xmark" />
              </button>
              <div className="modal-header">
                <h5 className="modal-title" id="modallikecommentLabel">
                  Like/Comment
                </h5>
              </div>
              <div className="modal-body">
                <p className="text-center f_17">
                  This needs text messaging. Please open the site in the phone
                  and try this.
                </p>
              </div>
              <div className="modal-footer">
                <a
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="btn btn-success"
                >
                  Ok
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default MediaList;

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.bundle";
import { Modal } from "bootstrap";
import ReactModal from "react-modal";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import setCanvasPreview from "./setcanvaspreviews";
const ASPECT_RATIO = 1;
const MIN_DIMENSION = 160;

function Settings() {
  const navigate = useNavigate();
  const location = useLocation();
  const [subdomain, setSubdomain] = useState();
  const [profileinfo, setProfileinfo] = useState();
  const [newSubdomain, setNewSubdomain] = useState();
  const [profileLogo, setProfileLogo] = useState(null);
  const [base64_image, setBase64Image] = useState(null);
  const [canvas, setCanvas] = useState(null);

  const [error, setError] = useState(null);
  const [modelsettingsInstance, setModalSettingsInstance] = useState(null);
  const [uriKey, setUriKey] = useState(() => {
    const searchParams = new URLSearchParams(location.search);
    const encodedId = searchParams.get("key");
    if (encodedId) {
      return encodedId;
    } else {
      return "";
    }
  });
  const [isDelete, setIsDelete] = useState();
  const [whatsapp, setWhatsapp] = useState();
  const [smsqrCodeUrl, setSMSQRCodeUrl] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [userCountry, setUserCountry] = useState("");
  const [userIP, setUserIP] = useState("");
  const imgRef = useRef(null);
  const PreviewcanvasRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [crop, setCrop] = useState({
    unit: "%", // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });
  const [aspect, setAspect] = useState(16 / 9);
  const [croppedImage, setCroppedImage] = useState("");
  const [modelprofileInstance, setModalProfileInstance] = useState(null);
  const [profile, setProfile] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [about_me, setAboutMe] = useState("");
  const [isMaxCharsReached, setIsMaxCharsReached] = useState(false);

  useEffect(() => {
    console.log("intialized with convoId from conversation", location);
    // let urixor = location.pathname.split("/")[2];
    // console.log({ urixor });
    let urixor = uriKey;
    console.log({ urixor });
    if (urixor) {
      const encryptedString = urixor;
      const key = process.env.REACT_APP_XOR_KEY;
      const decryptedString = xorDecrypt(encryptedString, key);
      console.log({ decryptedString });
      const parts = decryptedString.split("-!-");
      const dateTime = parts[0];
      const whatsappNumber = parts[1];
      console.log("Date and Time:", dateTime);
      console.log("WhatsApp Number:", whatsappNumber);
      setWhatsapp(whatsappNumber);
      var givenDate = new Date(dateTime);
      if (isNaN(givenDate.getTime())) {
        navigate("/");
      } else {
        var localTimezoneOffset = givenDate.getTimezoneOffset();

        var localTime = new Date(
          givenDate.getTime() - localTimezoneOffset * 60000
        );
        var currentDate = new Date();
        if (decryptedString && currentDate > localTime) {
          setIsDelete(false);
        } else if (currentDate < givenDate) {
          setIsDelete(true);
        } else {
          setIsDelete(true);
        }
      }
    } else {
      navigate("/");
    }
    // }
  }, [location, whatsapp]);
  useEffect(() => {
    // Accessing window.location to get the URL
    const currentURL = window.location.hostname;
    const parts = currentURL.split(".");
    if (parts.length >= 1) {
      setSubdomain(parts[0]);
      setNewSubdomain(parts[0]);
      console.log("parts[0]", parts[0]);
    }
  }, [subdomain]);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchData();
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [subdomain, profile]);
  const fetchData = async () => {
    try {
      if (subdomain !== "www" && subdomain !== "phonebook") {
        const response = await axios.post(
          process.env.REACT_APP_APIURL + "read-content-file",
          {
            "site-name": subdomain,
            "start-index": 0,
            "end-index": 1,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        // handleClose(); // This should now work fine since handleClose is defined
        const jsonDataRes = response.data;
        const jsonData = JSON.parse(jsonDataRes);
        console.log(jsonData.user_preferences);
        console.log(jsonData.user_preferences.user_pfp);
        setProfile(jsonData.user_preferences.user_pfp);
        setProfileinfo(jsonData.user_preferences);
        setAboutMe(jsonData.user_preferences.user_tagline);
        window.localStorage.setItem("domain", subdomain);
      } else {
        navigate("/qrcode");
      }
    } catch (error) {
      navigate("/qrcode");
      console.log("Error fetching data:", error);
    }
  };

  const xorDecrypt = (encryptedData, key) => {
    // Convert hexadecimal string to array of bytes
    const hexToBytes = (hex) => {
      const bytes = [];
      for (let i = 0; i < hex.length; i += 2) {
        bytes.push(parseInt(hex.substr(i, 2), 16));
      }
      return bytes;
    };

    // Perform XOR operation on two arrays of bytes
    const xorBytes = (arr1, arr2) => {
      const result = [];
      for (let i = 0; i < arr1.length; i++) {
        result.push(arr1[i] ^ arr2[i % arr2.length]);
      }
      return result;
    };

    const encryptedBytes = hexToBytes(encryptedData);
    const keyBytes = new TextEncoder().encode(key);

    // XOR operation
    const decryptedBytes = xorBytes(encryptedBytes, keyBytes);

    // Convert bytes to string
    const decryptedText = String.fromCharCode(...decryptedBytes);

    return decryptedText;
  };
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const handleDomainChange = (e) => {
    let inputValue = e.target.value.toLowerCase();
    const regex = /^[a-z0-9]+$/;
    if (regex.test(inputValue)) {
      setNewSubdomain(inputValue);
    } else {
      inputValue = inputValue.replace(/[^a-z0-9]/g, "");
      setNewSubdomain(inputValue);
    }
  };
  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("nick", subdomain);
      formData.append("domain", newSubdomain);
      if (subdomain !== newSubdomain) {
        console.log(formData);
        const response = await axios.post(
          process.env.REACT_APP_APIURL + "update-settings",
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          window.location.href = `https://${newSubdomain}.phonebook.today`;

          console.log("Update successful.");
        } else {
          toast.error("The new domain name has not been changed.", {
            // position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000, // Adjust the duration as needed
          });
        }
      } else {
        toast.error("The new domain name and the nick name are the same", {
          // position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000, // Adjust the duration as needed
        });
      }
    } catch (error) {
      console.error("Error updating settings:", error);
      setError("Error updating settings. Please try again later.");
    }
  };

  const openSettingsModalload = () => {
    if (modelsettingsInstance) {
      modelsettingsInstance.show();
    }
  };
  // Function to initialize the modal instance
  const initializeModalSettings = () => {
    const modalElement = document.getElementById("modelsettings");
    if (modalElement) {
      const modal = new Modal(modalElement);
      setModalSettingsInstance(modal);
    }
  };
  useEffect(() => {
    initializeModalSettings();
  }, []);
  useEffect(() => {
    const stripNonNumeric = (str) => {
      return str.replace(/\D/g, "");
    };

    // Check if the environment variable is available
    if (!process.env.REACT_APP_REDIRECT_PHONE) {
      console.error("REACT_APP_REDIRECT_PHONE environment variable not found.");
      return;
    }

    // Detect if the user is using a mobile device
    const userAgent = navigator.userAgent;
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      )
    );

    // Fetch user's IP address
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        setUserIP(data.ip);
        // Fetch geolocation information based on the user's IP address
        fetch(
          `https://ipinfo.io/${data.ip}/json?token=${process.env.REACT_APP_IP_TOKEN}`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("data", data);
            setUserCountry(data.country);
            if (data.country === "US") {
              // setQRCodeUrl(
              //   `sms:${process.env.REACT_APP_REDIRECT_PHONE}&body=Please set up a website for me`
              // );
              const phoneNumber = encodeURIComponent(
                process.env.REACT_APP_REDIRECT_PHONE
              );
              const messageBody = encodeURIComponent("");
              const userAgent =
                navigator.userAgent || navigator.vendor || window.opera;
              if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
                const smsUri = `sms:+${encodeURIComponent(
                  process.env.REACT_APP_REDIRECT_PHONE
                )}&body=${messageBody}`;
                setSMSQRCodeUrl(smsUri);
              } else if (/android/i.test(userAgent)) {
                const smsUri = `sms:+${phoneNumber}?body=${messageBody}`;
                setSMSQRCodeUrl(smsUri);
              } else {
                const smsUri = `sms:+${phoneNumber}?body=${messageBody}`;
                setSMSQRCodeUrl(smsUri);
              }
            } else if (data.country === "IN") {
              setSMSQRCodeUrl(
                `https://wa.me/${stripNonNumeric(
                  process.env.REACT_APP_REDIRECT_PHONE
                )}?text=${encodeURIComponent("")}`
              );
            } else {
              setSMSQRCodeUrl(
                `https://wa.me/${stripNonNumeric(
                  process.env.REACT_APP_REDIRECT_PHONE
                )}?text=${encodeURIComponent("")}`
              );
            }
          })
          .catch((error) => {
            console.error("Error fetching user's country:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching user's IP Address1:", error);
      });
  }, []);

  const openprofileModalload = () => {
    if (modelprofileInstance) {
      modelprofileInstance.show();
    }
  };
  // Function to initialize the modal instance
  const initializeModalProfile = () => {
    const modalElement = document.getElementById("modelprofile");
    if (modalElement) {
      const modal = new Modal(modalElement);
      setModalProfileInstance(modal);
    }
  };
  useEffect(() => {
    initializeModalProfile();
  }, []);

  const MAX_QUALITY = 0.1; // Maximum quality for the compressed image

  const compressImage = (image, callback) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const maxSizeInMB = 7;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    const { naturalWidth, naturalHeight } = image;

    const width = image.width;
    const height = image.height;
    const aspectRatio = width / height;
    const newWidth = Math.sqrt(maxSizeInBytes * aspectRatio);
    const newHeight = Math.sqrt(maxSizeInBytes / aspectRatio);
    canvas.width = newWidth;
    canvas.height = newHeight;
    ctx.drawImage(image, 0, 0, newWidth, newHeight);
    let quality = 0.8;
    let dataURL = canvas.toDataURL("image/jpeg", quality);
    callback(dataURL);
  };

  const onSelectFile = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      console.log({ imageUrl });
      imageElement.src = imageUrl;

      imageElement.addEventListener("load", () => {
        const { naturalWidth, naturalHeight } = imageElement;
        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          toast.error("Image must be at least 160 x 160 pixels.", {
            autoClose: 2000,
          });
          return setImgSrc("");
        } else {
          // Compress the image before setting it as the source
          compressImage(imageElement, (compressedImageUrl) => {
            setImgSrc(compressedImageUrl);
            openprofileModalload();
          });
        }
      });
    });

    reader.readAsDataURL(file);
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;
    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };
  const handleSaveFileChange = async (event) => {
    console.log("File selected", event);
    const MAX_QUALITY = 0.8;
    const file = event;
    if (file) {
      // Create a canvas element to manipulate the image quality
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Load the image data
      const image = new Image();
      image.src = file;

      // Wrap the code inside an async function
      image.onload = async () => {
        const canvasWidth = 250;
        const canvasHeight = 250;
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        // Draw the image onto the canvas with maximum quality
        ctx.drawImage(image, 0, 0, canvasWidth, canvasHeight);

        // Convert the canvas content to a base64 encoded JPEG image with maximum quality
        const base64 = canvas.toDataURL("image/jpeg", MAX_QUALITY);
        console.log({ base64 });
        const base64Data = base64.split(",")[1];
        // Create a FormData object and append the Blob
        const formData = new FormData();
        formData.append("nick", subdomain);
        formData.append("profile_picture", base64Data);

        if (subdomain) {
          console.log(formData);
          try {
            const response = await axios.post(
              process.env.REACT_APP_APIURL + "update-settings",
              formData,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            if (response.status === 200) {
              fetchData();
              console.log("Update successful.");
            } else {
              toast.error("The new domain name has not been changed.", {
                autoClose: 2000,
              });
            }

            setBase64Image(base64);
            setProfileLogo(file);
          } catch (error) {
            console.error("Error updating settings:", error);
            // Handle error if the Axios request fails
          }
        }
      };
    }
  };
  const handleaboutChange = (e) => {
    let inputValue = e.target.value;
    // Limit input to 100 characters
    if (inputValue.length <= 100) {
      setAboutMe(inputValue);
      if (inputValue.length === 100) {
        setIsMaxCharsReached(true); // Enable the red text
      } else {
        setIsMaxCharsReached(false); // Disable the red text
      }
    } else {
      // If about_me exceeds 100 characters, show an error toast
      // toast.error("About Me must be under 100 characters.", {
      //   // position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 2000, // Adjust the duration as needed
      // });
    }
  };
  const handleaboutUpdate = async () => {
    try {
      if (about_me.length <= 100) {
        const formData = new FormData();
        formData.append("nick", subdomain);
        formData.append("tagline", about_me);

        const response = await axios.post(
          process.env.REACT_APP_APIURL + "update-settings",
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          toast.success("The about me has been updated.", {
            // position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000, // Adjust the duration as needed
          });
        }
      } else {
        // If about_me exceeds 100 characters, show an error toast
        toast.error("About Me must be under 100 characters.", {
          // position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000, // Adjust the duration as needed
        });
      }
    } catch (error) {
      console.error("Error updating settings:", error);
      setError("Error updating settings. Please try again later.");
    }
  };

  return (
    <main>
      {/* shop area start */}
      <section className="tp-shop-area pt-65 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12 col-xl-12">
              {isDelete == true ? (
                <div className="tp-shop-main-wrapper bg-white">
                  <h3 className="text-center mt-35 mb-45">Settings</h3>
                  <div className="allcontent_wrapper">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="mb-5 d-inline-block w-100">
                        {/* Profile Picture Upload */}
                        <div className="ctsprofpiccntr position-relative">
                          <div
                            className="tp_profilephoto_imgwrapper"
                            style={
                              !profile || profile === "None"
                                ? {
                                    background: "rgb(175, 232, 236)",
                                    background:
                                      "-moz-linear-gradient(71deg, rgba(175, 232, 236, 1) 12%, rgba(32, 189, 204, 1) 61%)",
                                    background:
                                      "-webkit-linear-gradient(71deg, rgba(175, 232, 236, 1) 12%, rgba(32, 189, 204, 1) 61%)",
                                    background:
                                      "linear-gradient(71deg, rgba(175, 232, 236, 1) 12%, rgba(32, 189, 204, 1) 61%)",
                                    filter:
                                      'progid:DXImageTransform.Microsoft.gradient(startColorstr="#afe8ec",endColorstr="#20bdcc",GradientType=1)',
                                  }
                                : {}
                            }
                          >
                            {base64_image ? (
                              <img src={base64_image} alt="" border="0" />
                            ) : (
                              <img
                                src={
                                  profile + "?timestamp=" + new Date().getTime()
                                }
                                alt=""
                                border="0"
                              />
                            )}
                          </div>
                          <div className="s_profileupld s_profileupld_top clearfix">
                            <label className="btn-bs-file btn btn-dark">
                              {/*Uploads*/}
                              <i className="fa-solid fa-camera posrel_camera" />
                              <input
                                type="file"
                                accept="image/*"
                                onChange={onSelectFile}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="w-100 d-flex justify-content-center mt-35">
                        <div className="col-12 col-sm-12 col-md-10 col-lg-6">
                          <div className="row">
                            <label className="col-7 col-sm-5 col-form-label f_bold">
                              Manage My Store
                            </label>
                            <div className="col-5 col-sm-7">
                              <a href={'https://' + window.location.hostname + '/manage-store?key=' + uriKey} target="_blank" className="btn btn-dark" >
                                Manage
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-100 d-flex justify-content-center mt-35">
                      <div className="brdr_btm col-12 col-sm-12 col-md-12 col-lg-12" />
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                      <div className="w-100 d-flex justify-content-center mt-35">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <div className="col-auto mb-4 text-center">
                            <label className="col-form-label">
                              Your Domain
                            </label>
                          </div>
                          <div className="row g-3 align-items-center mt-1 mrgnbtm_desktop">
                            <div className="col-auto d-none d-sm-block">
                              <span className="form-text">https://</span>
                            </div>
                            <div className="col-auto col-6">
                              <input
                                type="text"
                                className="form-control"
                                value={newSubdomain}
                                onChange={handleDomainChange}
                              />
                            </div>
                            <div className="col-auto">
                              <span className="form-text">phonebook.today</span>
                            </div>
                          </div>
                          <div className="w-100 d-flex justify-content-center mt-35">
                            <button
                              className="btn btn-dark"
                              onClick={handleUpdate}
                              disabled={newSubdomain === subdomain}
                            >
                              Update My Domain
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                      <div className="w-100 d-flex justify-content-center mt-35">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <div className="col-auto mb-2 text-center">
                            <label className="col-form-label">About Me</label>
                          </div>
                          <div className="row g-3">
                            <div className="col-12 col-lg-12">
                              <textarea
                                className="form-control txtarea_hgt"
                                value={about_me}
                                onChange={handleaboutChange}
                              />
                              {isMaxCharsReached ? (
                                <div className="txtalgn_rgt f_red f_14">
                                  This must contain under 100 characters
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="w-100 d-flex justify-content-center mt-35">
                            <button
                              className="btn btn-dark"
                              onClick={handleaboutUpdate}
                              disabled={
                                about_me.length === 0 || about_me.length > 100
                              }
                            >
                              Update My Bio
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-100 d-flex justify-content-center mt-35">
                      <div className="brdr_btm col-12 col-sm-12 col-md-12 col-lg-12" />
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="w-100 d-flex justify-content-center mt-35">
                        <div className="col-12 col-sm-12 col-md-10 col-lg-6">
                          <div className="row">
                            <label className="col-7 col-sm-5 col-form-label f_bold">
                              Delete My Posts
                            </label>
                            <div className="col-5 col-sm-7">
                              <a href={'https://' + window.location.hostname + '/delete?key=' + uriKey} target="_blank" className="btn btn-dark">
                                Delete
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : isDelete === false ? (
                openSettingsModalload()
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
      {/*setting model */}
      <div
        className="modal fade"
        id="modelsettings"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="modelsettingsLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => navigate("/")}
            >
              <i className="fa-solid fa-xmark" />
            </button>

            <div className="modal-body">
              Your /settings request is timed out. Please request again from
              your messaging app (
              <a
                className="f_black txt_underline"
                href={smsqrCodeUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                SMS or WhatsApp
              </a>
              )
            </div>
          </div>
        </div>
      </div>
      {/* profile Popup */}
      <div
        className="modal fade"
        id="modelprofile"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="modelprofileLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              // onClick={() => navigate("/")}
            >
              <i className="fa-solid fa-xmark" />
            </button>
            <div className="modal-header">
              <h5 className="modal-title" id="modalprofileLabel">
                Your profile photo
              </h5>
            </div>
            <div className="modal-body">
              <div class="profile_img">
                {imgSrc && (
                  <div className="flex flex-col items-center">
                    <ReactCrop
                      // src={imageSrc}
                      crop={crop}
                      onChange={(pixelCrop, percentCrop) =>
                        setCrop(percentCrop)
                      }
                      circularCrop
                      keepSelection
                      aspect={ASPECT_RATIO}
                      minWidth={MIN_DIMENSION}

                      // onChange={handleCropChange}
                      // onComplete={handleCropComplete}
                      // onImageLoaded={handleImageLoaded}
                      // aspect={aspect}
                    >
                      <img
                        ref={imgRef}
                        src={imgSrc}
                        alt="Upload"
                        style={{ maxHeight: "70vh" }}
                        onLoad={onImageLoad}
                      />
                    </ReactCrop>
                  </div>
                )}
                {crop && (
                  <canvas
                    ref={PreviewcanvasRef}
                    className="mt-4"
                    style={{
                      display: "none",
                      border: "1px solid black",
                      objectFit: "contain",
                      width: 160,
                      height: 160,
                    }}
                  />
                )}
              </div>
              <div class="modal-footer">
                <a
                  href="#"
                  className="btn btn-success"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setCanvasPreview(
                      imgRef.current,
                      PreviewcanvasRef.current,
                      convertToPixelCrop(
                        crop,
                        imgRef.current.width,
                        imgRef.current.height
                      )
                    );

                    const dataUrl = PreviewcanvasRef.current.toDataURL();
                    console.log({ dataUrl });
                    setCanvas(dataUrl);
                    handleSaveFileChange(dataUrl);
                  }}
                >
                  Save
                </a>
                <a
                  href="#"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="btn btn-secondary"
                >
                  Cancel
                </a>
              </div>
              {/* <div align="center" className="mt-3">
                <button
                  className="btn btn-black btnpdng_sm mr-2"
                  onClick={saveImageCrop}
                >
                  Save
                </button>

                <button
                  className="btn btn-secondary crsr_pntr btnpdng_sm"
                  onClick={closeImageCrop}
                >
                  Cancel
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* shop area end */}
    </main>
  );
}

export default Settings;

import React, { Component, useContext, useState } from 'react'

import { useNavigate } from 'react-router-dom';
import NavigationBar from "./NavigationBar";
function Header({ pathname }) {
    const navigate = useNavigate();
    // const [userLoc, setUserLoc] = useState(ReactSession.get("userlocation"));
    return (
        
        <header>
            <NavigationBar></NavigationBar>
        </header>
    );
}

export default Header;
import React, { useState, useEffect } from "react";
import { PDFReader, pdfjs } from "reactjs-pdf-reader";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDF_Viewer = ({ pdfUrl }) => {
  // Use pdfUrl directly, no need for additional state
  const [shouldRenderPdf, setShouldRenderPdf] = useState(!!pdfUrl);

  useEffect(() => {
    // Update shouldRenderPdf when pdfUrl changes
    setShouldRenderPdf(!!pdfUrl);
  }, [pdfUrl]);

  const renderToolbar = (toolbarSlot) => {
    return (
      <div
        class="pdfwdth"
        style={{ alignItems: "center", display: "flex", width: "100%" }}
      >
        {/* Your toolbar rendering logic */}
      </div>
    );
  };

  const onPageChange = (pageNum) => {
    console.log("Current page1:", pageNum);
    // You can add your logic here to handle page changes
  };

  return (
    <div>
      {shouldRenderPdf ? (
        <div style={{ border: "1px solid rgba(0, 0, 0, 0.3)" }}>
          <PDFReader
            url={pdfUrl} // Use pdfUrl directly here
            showAllPage={true} // Note: 'true' should not be a string here
            width={800} // Note: '800' should not be a string here
            renderToolbar={renderToolbar} // Optional: Custom toolbar rendering
            onPageChange={onPageChange} // Optional: Callback for page change
          />
        </div>
      ) : (
        <div>
          <p style={{ fontSize: "50px", color: "red" }}>
            This Page is Restricted
          </p>
        </div>
      )}
    </div>
  );
};

export default PDF_Viewer;

// import React, { useState, useEffect } from "react";
// import { Document, Page } from "react-pdf";
// import WordViewer from "react-docx";
// import ExcelRenderer from "react-excel-renderer";
// import axios from "axios";

// const PDF_Viewer = ({ pdfUrl }) => {
//   const [pdfFile, setPdfFile] = useState(null);
//   const [docxFile, setDocxFile] = useState(null);
//   const [excelFile, setExcelFile] = useState(null);

//   useEffect(() => {
//     // Load PDF file
//     axios
//       .get(
//         "https://whatsapp-website-storage.s3.amazonaws.com/whatsapp%3A%2B918825478557/whatsapp%3A%2B15103301270/media/MEd2929a396d2f723b69f4b349c7093f44.pdf",
//         { responseType: "blob" }
//       )
//       .then((response) => {
//         setPdfFile(response.data);
//       })
//       .catch((error) => {
//         console.error("Error fetching PDF file:", error);
//       });

//     // Load DOCX file
//     axios
//       .get(
//         "https://whatsapp-website-storage.s3.amazonaws.com/whatsapp%3A%2B918825478557/whatsapp%3A%2B15103301270/media/ME21efcf231a90c01b31d932e49f5e58d0.msword",
//         { responseType: "blob" }
//       )
//       .then((response) => {
//         setDocxFile(response.data);
//       })
//       .catch((error) => {
//         console.error("Error fetching Word file:", error);
//       });

//     // Fetch Excel file
//     axios
//       .get(
//         "https://whatsapp-website-storage.s3.amazonaws.com/whatsapp%3A%2B918825478557/whatsapp%3A%2B15103301270/media/MEd9e5f3b49bcd4c8057b0892339391258.vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//         { responseType: "blob" }
//       )
//       .then((response) => {
//         setExcelFile(response.data);
//       })
//       .catch((error) => {
//         console.error("Error fetching Excel file:", error);
//       });
//   }, []);

//   return (
//     <div>
//       {pdfFile && (
//         <div>
//           <h2>PDF Preview</h2>
//           <Document file={pdfFile}>
//             <Page pageNumber={1} />
//           </Document>
//         </div>
//       )}
//       {docxFile && (
//         <div>
//           <h2>Word (DOCX) Preview</h2>
//           <WordViewer document={docxFile} />
//         </div>
//       )}
//       {excelFile && (
//         <div>
//           <h2>Excel (XLSX/XLS) Preview</h2>
//           <ExcelRenderer excelFile={excelFile} renderMethod={"renderTable"} />
//         </div>
//       )}
//     </div>
//   );
// };

// export default PDF_Viewer;

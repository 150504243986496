import React, { Component, useContext, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import help from "../img/help.png";
import { Helmet } from "react-helmet";
function Help() {
  const navigate = useNavigate();
  const [smsqrCodeUrl, setSMSQRCodeUrl] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [userCountry, setUserCountry] = useState("");
  const [userIP, setUserIP] = useState("");
  // const [userLoc, setUserLoc] = useState(ReactSession.get("userlocation"));

  const RedirectApp = async (com) => {
    const stripNonNumeric = (str) => {
      return str.replace(/\D/g, "");
    };

    // Check if the environment variable is available
    if (!process.env.REACT_APP_REDIRECT_PHONE) {
      console.error("REACT_APP_REDIRECT_PHONE environment variable not found.");
      return;
    }

    // Detect if the user is using a mobile device
    const userAgent = navigator.userAgent;
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      )
    );

    // Fetch user's IP address
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        setUserIP(data.ip);
        // Fetch geolocation information based on the user's IP address
        fetch(
          `https://ipinfo.io/${data.ip}/json?token=${process.env.REACT_APP_IP_TOKEN}`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("data", data);
            setUserCountry(data.country);
            let redirectUrl = "";
            if (data.country === "US") {
              // setQRCodeUrl(
              //   `sms:${process.env.REACT_APP_REDIRECT_PHONE}&body=Please set up a website for me`
              // );
              const phoneNumber = encodeURIComponent(
                process.env.REACT_APP_REDIRECT_PHONE
              );
              const messageBody = encodeURIComponent(com);
              const userAgent =
                navigator.userAgent || navigator.vendor || window.opera;
              if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
                const smsUri = `sms:+${encodeURIComponent(
                  process.env.REACT_APP_REDIRECT_PHONE
                )}&body=${messageBody}`;
                redirectUrl = smsUri;
              } else if (/android/i.test(userAgent)) {
                const smsUri = `sms:+${phoneNumber}?body=${messageBody}`;
                redirectUrl = smsUri;
              } else {
                const smsUri = `sms:+${phoneNumber}?body=${messageBody}`;
                redirectUrl = smsUri;
              }
            }
            // else if (data.country === "IN") {
            //   redirectUrl = `https://wa.me/${stripNonNumeric(
            //     process.env.REACT_APP_REDIRECT_PHONE
            //   )}?text=${encodeURIComponent(com)}`;
            // }
            else {
              redirectUrl = `https://wa.me/${stripNonNumeric(
                process.env.REACT_APP_REDIRECT_PHONE
              )}?text=${encodeURIComponent(com)}`;
            }

            window.open(redirectUrl, "_blank");
          })
          .catch((error) => {
            console.error("Error fetching user's country:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching user's IP Address1:", error);
      });
  };
  return (
    <>
    <Helmet>
      <title>
      Help - Phonebook | Create a Website on Your Own, Custom Domain, Analytics
      </title>
      <meta
        name="description"
        content="Phonebook.today is entirely managed from your messaging applications installed on your phone, such as SMS or WhatsApp. More apps, including but not limited to Telegram, are coming soon."
      />
      <meta
        name="keywords"
        content="create a website on your own, custom domain, sms, analytics, messaging applications"
      />
    </Helmet>
    <main>
      {/* shop area start */}
      <section className="tp-shop-area pt-65 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12 col-xl-12">
              <div className="tp-shop-main-wrapper bg-white p-3">
                <h3 className="text-center mt-35 mb-45">/help</h3>
                <div className="allcontent_wrapper">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                    <p>
                      Phonebook is entirely managed from your messaging
                      applications installed on your phone, such as SMS or
                      WhatsApp. More apps, including but not limited to
                      Telegram, are coming soon.
                    </p>
                    <p>
                      To get help from Phonebook, simply send the command{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => RedirectApp("/help")}
                        className="link crsr_pntr"
                      >
                        {" "}
                        <span className="f_bold">/help</span>
                      </a>
                      , and you will receive a list of available commands for
                      various actions and activities.
                    </p>
                    <p className="f_bold mt-25">
                      Currently, the following commands are available:
                    </p>

                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => RedirectApp("/info")}
                        className="link crsr_pntr"
                      >
                        <span className="f_bold">/info</span>: Get information
                        about your Phonebook website, such as your unique URL
                        and current settings.
                      </a>
                    </p>
                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => RedirectApp("/settings")}
                        className="link crsr_pntr"
                      >
                        <span className="f_bold">/settings</span>: Set up a
                        custom domain, change your profile photo, and adjust
                        other preferences for your Phonebook site.{" "}
                      </a>
                    </p>
                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => RedirectApp("/analytics")}
                        className="link crsr_pntr"
                      >
                        <span className="f_bold">/analytics</span>
                        :Retrieve statistics for your Phonebook site, including
                        visitor count, page views, and engagement metrics.
                      </a>
                    </p>
                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => RedirectApp("/delete")}
                        className="link crsr_pntr"
                      >
                        <span className="f_bold">/delete</span>: Remove selected
                        items from your Phonebook site to keep your content
                        up-to-date.
                      </a>
                    </p>
                    <p>
                      More commands and features will be added in the future to
                      enhance your Phonebook experience.
                    </p>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                    <div className="content_helpimg">
                      <img src={help} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* shop area end */}
    </main>
    </>
  );
}

export default Help;

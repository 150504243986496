import React, { useState, useEffect } from "react";
function Footer() {
  const path = window.location.pathname;
  useEffect(() => {
    if (path === "/help" || path === "/faq" || path === "/qrcode") {
      const script = document.createElement("script");

      script.src = "https://cdn.tinytalk.ai/latest/tiny-talk-sdk.min.umd.js";
      script.defer = true;
      script.dataset.tinyBotId = "bdc82804-2bc9-4652-af02-45ebe4b060b8";

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);
  return (
    <>
    {path !== "/qrcode"?
    <footer className="footer">
      <div className="tp-footer-area tp-footer-style-2 tp-footer-style-primary tp-footer-style-6" data-bg-color="#ffffff">
        <div className="tp-footer-bottom">
          <div className="container-fluid">
            <div className="tp-footer-bottom-wrapper">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <div className="tp-footer-copyright">
                    <div className="d-flex flex-wrap flex-row justify-content-center">
                      <div className="footer_img w-100 d-flex justify-content-center"><img src="assets/img/logo.png" border={0} /></div>
                      <div><p>Copyright © 2024 PhoneBook</p></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
:
<footer className="footer">
  <div className="tp-footer-area tp-footer-style-2 tp-footer-style-primary tp-footer-style-6" data-bg-color="#ffffff">
    <div className="tp-footer-bottom">
      <div className="container-fluid">
        <div className="tp-footer-bottom-wrapper">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="tp-footer-copyright">
                <div className="d-flex flex-wrap flex-row justify-content-center">
                  <div className="myprofiles_div">
                    <a href="https://www.instagram.com/phonebook.today/" target="_blank" className="m-2 link">
                      <div className="myprofiles_list bg_singleclr">
                        <i className="fa-brands fa-instagram f_22" />
                      </div>
                    </a>
                    <a href="https://www.facebook.com/phonebook.today/" target="_blank" className="m-2 link">
                      <div className="myprofiles_list bg_singleclr">
                        <i className="fa-brands fa-facebook-f f_20" />
                      </div>
                    </a>
                    <a href="https://x.com/phonebook_today" target="_blank" className="m-2 link">
                      <div className="myprofiles_list bg_singleclr">
                        <i className="fa-brands fa-x-twitter f_20" />
                      </div>
                    </a>
                    <a href="https://www.threads.net/@phonebook.today?xmt=AQGzjBPythByjmO_X9RQuufOXh6E6L6JJZbOE7LZHa_CKn0" target="_blank" className="m-2 link">
                      <div className="myprofiles_list bg_singleclr">
                        <i className="fa-brands fa-threads f_22" />
                      </div>
                    </a>
                    <a href="https://www.youtube.com/channel/UCRYLEb1jB5wBv5iKtBX5qSg" target="_blank" className="m-2 link">
                      <div className="myprofiles_list bg_singleclr">
                        <i className="fa-brands fa-youtube f_20" />
                      </div>
                    </a>
                  </div>
                  {/* <div class="footer_img w-100 d-flex justify-content-center"><img src="assets/img/logo.png" border="0"/></div> */}
                  <div><p>Copyright © 2024 PhoneBook</p></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
  }
</>
  );
}

export default Footer;
import React, { useState, useEffect } from "react";
import logo from '../img/logo.png';

const NavigationBar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState("home");
  const [calendarMenu, setCalendarMenu] = useState(false);
  const [sitename, setSitename] = useState();

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const path = window.location.pathname;

  useEffect(() => {
    const determineActiveMenu = () => {
      switch (path) {
        case "/":
          setActiveMenu("home");
          setCalendarMenu(true);
          break;
        case "/gallery":
          setActiveMenu("gallery");
          setCalendarMenu(false);
          break;
        case "/help":
          setActiveMenu("help");
          setCalendarMenu(false);
          break;
        case "/faq":
          setActiveMenu("faq");
          setCalendarMenu(false);
          break;
        case "/settings":
          setActiveMenu("settings");
          setCalendarMenu(false);
          break;
        case "/qrcode":
          setActiveMenu("qrcode");
          setCalendarMenu(false);
          break;
        case "/upcoming_features":
          setActiveMenu("upcoming_features");
          setCalendarMenu(false);
          break;
          case "/addStore":
          setActiveMenu("manage-store");
          setCalendarMenu(false);
          break;
        default:
          setActiveMenu("home");
          setCalendarMenu(true);
          break;
      }
    };

    determineActiveMenu();
    window.addEventListener("popstate", determineActiveMenu);

    return () => {
      window.removeEventListener("popstate", determineActiveMenu);
    };
  }, [path]);

  const handleCalendarClick = () => {
    setActiveMenu("calendar");
    setIsNavOpen(false);
  };

  useEffect(() => {
    const domain = window.localStorage.getItem("domain");
    if (domain) {
      setSitename(domain);
    } else {
      let attempt = 0;
      const maxAttempts = 3;
      const retryInterval = 2000;

      const retrySetSitename = () => {
        const newDomain = window.localStorage.getItem("domain");
        if (newDomain) {
          setSitename(newDomain);
        } else if (attempt < maxAttempts) {
          attempt++;
          setTimeout(retrySetSitename, retryInterval);
        }
      };

      retrySetSitename();
    }
  }, []);

  return (
    <>
      {path === "/messenger" ? (
        <nav className="navbar navbar-expand-xl fixed-top">
          <div className="container-fluid">
            <div className="justify-content-center d-inline-flex w-100">
              <a className="navbar-brand" href="index.html">
                <img src="assets/img/logo.png" border={0} className="desktop_logo" />
              </a>
            </div>
          </div>
        </nav>
      ) : (
        <nav className="navbar navbar-expand-xl fixed-top">
          <div className="container-fluid">
            <a className="navbar-brand" href="https://www.phonebook.today">
              <img src={logo} alt="Logo" className="desktop_logo" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleNav}
              aria-expanded={isNavOpen}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"><i className="fa-solid fa-bars f_30"/></span>
            </button>
            <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`} id="navbarToggler1">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a href="/" className={`nav-link ${activeMenu === "home" ? 'active' : ''}`}>Home</a>
                </li>
                {calendarMenu && (
                  <li className="nav-item">
                    <a
                      className={`nav-link crsr_pntr ${activeMenu === "calendar" ? 'active' : ''}`}
                      data-bs-toggle="modal"
                      data-bs-target="#modalcalendar"
                      onClick={handleCalendarClick}
                    >
                      Calendar
                    </a>
                  </li>
                )}
                {!sitename && (
                  <>
                    <li className="nav-item">
                      <a className={`nav-link ${activeMenu === "gallery" ? 'active' : ''}`} href="/gallery">Gallery</a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${activeMenu === "faq" ? 'active' : ''}`} href="/faq">FAQ</a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${activeMenu === "upcoming_features" ? 'active' : ''}`} href="/upcoming_features">Upcoming Features</a>
                    </li>
                  </>
                )}
                {sitename && (
                  <li className="nav-item">
                    <a className={`nav-link ${activeMenu === "manage-store" ? 'active' : ''}`} href="/manage-store">/store</a>
                  </li>
                )}
                <li className="nav-item">
                  <a className={`nav-link ${activeMenu === "help" ? 'active' : ''}`} href="/help">/help</a>
                </li>
                {path === "/settings" && (
                  <li className="nav-item">
                    <a className={`nav-link ${activeMenu === "settings" ? 'active' : ''}`} href="/settings">Settings</a>
                  </li>
                )}
                <li className="nav-item">
                  <a className={`nav-link ${activeMenu === "qrcode" ? 'active' : ''}`} href="/qrcode">Get a Phonebook</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}
    </>
  );
};

export default NavigationBar;

import React, { useState, useEffect } from "react";
import YouTubeVideo from "./youtube";
import { ReactTinyLink } from "react-tiny-link";
import LinkPreview from "./linkpreview";
// import WebPagePreview from "./metapreview";
const MessengerMedias = ({ path }) => {
  const [mediaUrl, setMediaUrl] = useState(path);
  const [fileContent, setFileContent] = useState("");
  const [extension, setExtension] = useState("");

  const linkRegex = /(https?:\/\/[^\s]+)/g;
  const asteriskRegex = /\*(.*?)\*/g;

  const highlightText = (text) => {
    // console.log("text", text);
    const linkRegex = /(?:https?:\/\/|www\.)\S+\.\S+/gi;
    const asteriskRegex = /\*(.*?)\*/gi;
    const underscoreRegex = /_(.*?)_/gi;
    const tildeRegex = /~(.*?)~/gi;
    const backtickRegex = /`(.*?)`/gi;
    const angleBracketRegex = /> (.*?)/gi;
    const numberedListRegex = /1\. (.*?)/gi;
    const codeBlockRegex = /```(.*?)```/gi;
    const blockQuoteRegex = /> (.*?)/gi;
    const strikeThroughRegex = /~(.*?)~/gi;
    const asteriskSpaceRegex = /\* (.*?)\b/gi;

    return (
      text
        .replace(linkRegex, (match) => {
          if (match.includes("youtu")) {
            const matchShortenedLink = match.match(
              /(?:\?v=|\/embed\/|\.be\/)([\w\d_-]+)/
            );
            const matchStandardLink = match.match(
              /(?:https?:\/\/)?(?:www\.)?youtu(?:\.be|be\.com)\/(?:\S+)/
            );
            const matchClipLink = match.match(
              /(?:https?:\/\/)?(?:www\.)?youtube\.com\/clip\/([\w\d_-]+)/i // Update the regex to capture clip links
            );

            let videoId = null;

            if (matchShortenedLink) {
              videoId = matchShortenedLink[1];
            } else if (matchClipLink) {

              return `<u><a href="${match}" target="_blank" rel="noopener noreferrer" class="linkwhite wrdbrkall">${match}</a></u>`;

            } else if (matchStandardLink) {
              videoId = matchStandardLink[0].split("/").pop();
            }

            if (videoId) {
              return `<div class="videoWrapper"><iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe></div>`;
            }
          } else {
            if (/^https?:\/\//i.test(match) || /^www\./i.test(match)) {
              if (/^www\./i.test(match)) {
                  return `<u><a href="https://${match}" target="_blank" rel="noopener noreferrer" class="linkwhite wrdbrkall">${match}</a></u>`;
              } else {
                  return `<u><a href="${match}" target="_blank" rel="noopener noreferrer" class="linkwhite wrdbrkall">${match}</a></u>`;
              }
            } else {
                return match;
            }
          
          }
        })
        .replace(/\*(.*?)\*/gi, "<strong>$1</strong>")
        .replace(
          /(?<!https?:\/\/(?:www\.)?youtu(?:\.be|be\.com)\/\S*)_(.*?)_(?!\S*youtu(?:\.be|be\.com))/gi,
          "<em>$1</em>"
        )

        // Strikethrough: Surround text with tildes (~text~) to strike it through.
        .replace(/~(.*?)~/gi, "<del>$1</del>")

        // Code: Surround text with backticks (`text`) to format it as code.
        .replace(/`(.*?)`/gi, `<code class="codebrdr">$1</code>`)

        // Bullet Points: Start a line with an asterisk (*) or a hyphen (-) to create a bullet point list.
        .replace(/^[\*\-] (.*)/gim, "<li>$1</li>")

        // Numbered Lists: Start a line with a number followed by a period to create a numbered list.
        .replace(/^\d+\. (.*)/gim, "<li>$1</li>")

        // Quotes: Start a line with a greater than symbol (>) to create a quote.
        .replace(
          /^> (.*)/gim,
          `<blockquote class="blockqte_bg">$1</blockquote>`
        )

        // Headers:
        // H1: Start a line with a hashtag followed by a space to create an H1 header.
        .replace(/^# (.*)/gim, "<h1>$1</h1>")
        // H2: Start a line with two hashtags followed by a space to create an H2 header.
        .replace(/^## (.*)/gim, "<h2>$1</h2>")
        // H3: Start a line with three hashtags followed by a space to create an H3 header.
        .replace(/^### (.*)/gim, "<h3>$1</h3>")
        // Mentions:
        .replace(/@(\w+)/gi, '<span class="mention">@$1</span>')

        // Code Snippets:
        .replace(
          /```(\w*)\n([\s\S]*?)\n```/gim,
          '<pre><code class="language-$1">$2</code></pre>'
        )

        // Emojis:
        // .replace(/:(.*?):/gi, '<span class="emoji">:$1:</span>')

        // Horizontal Lines:
        .replace(/^\s*[\*\-_]{3,}\s*$/gim, "<hr />")
    );
  };

  useEffect(() => {
    const fetchTxtFile = async (url) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Failed to fetch file");
        }
        const text = await response.text();

        setFileContent(text);
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    };

    if (mediaUrl) {
      const extension = path.split(".").pop().toLowerCase();
      setExtension(extension);
      if (extension === "txt") {
        fetchTxtFile(mediaUrl);
      }
    }
  }, [mediaUrl, path]);

  const renderMediaContent = () => {
    const extension = path.split(".").pop().toLowerCase();

    switch (true) {
      case ["jpeg", "jpg", "png", "webp"].includes(extension):
        return <img className="" src={mediaUrl} alt="Media" />;
      case ["ogg", "amr", "3gp", "aac", "mpeg", "mp3"].includes(extension):
        return (
          <audio controls>
            <source src={mediaUrl} type={`audio/${extension}`} />
            Your browser does not support the audio element.
          </audio>
        );
      // case ["pdf", "doc", "docx", "pptx", "xlsx"].includes(extension):
      //   return (
      //     <a href={mediaUrl} target="_blank" rel="noopener noreferrer">
      //       View Document
      //     </a>
      //   );
      case ["mp4", "webm", "3gpp"].includes(extension):
        return (
          <video controls>
            <source src={mediaUrl} type={`video/${extension}`} />
            Your browser does not support the video element.
          </video>
        );
      case ["vcf"].includes(extension):
        return (
          <a href={mediaUrl} download>
            Download Contact
          </a>
        );
      case extension === "txt":
        // return fileContent
        //   .split("\n")
        //   .map((paragraph, index) => (
        //     <p
        //       key={index}
        //       dangerouslySetInnerHTML={{ __html: highlightText(paragraph) }}
        //     />
        //   ));
        // const urlRegex = /(?:https?:\/\/|www\.)\S+\.\S+/gi;
        var urlRegex =
          /(https?:\/\/(?!(?:www\.)?(?:youtube\.com|youtu\.be))[^\s]+)/g;

        // Find all matches in the text
        var matches = fileContent.match(urlRegex);

        // Get the first match (if any)
        var firstLink = matches ? matches[0] : null;

        return (
          <>
            {/* {firstLink && (
              <LinkPreview url={firstLink} />
             
            )} */}
            
            {fileContent.split("\n").map((paragraph, index) => (
              <div
                key={index}
                dangerouslySetInnerHTML={{ __html: highlightText(paragraph) }}
              />
            ))}
            </>
        );
      default:
        // return <p>Unsupported file format</p>;
        return <p></p>;
    }
  };

  if (fileContent) {
    return <div className="">{mediaUrl && renderMediaContent()}</div>;
  } else {
    if (["ogg", "amr", "3gp", "aac", "mpeg", "mp3"].includes(extension)) {
      return (
        <div className="videoWrapper audio">
          {mediaUrl && renderMediaContent()}
        </div>
      );
    } else if (["mp4", "webm", "3gpp"].includes(extension)) {
      return (
        <div className="conversebubble_video">{mediaUrl && renderMediaContent()}</div>
      );
    } else {
      return <div className="conversebubble_img">{mediaUrl && renderMediaContent()}</div>;
    }
  }
};

export default MessengerMedias;

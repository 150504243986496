import React from 'react';

function SitemapLoader() {
  const xmlContent = `<?xml version="1.0" encoding="UTF-8"?>
<urlset
  xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"
  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
  xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9
  http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
  <!-- created with Free Online Sitemap Generator www.xml-sitemaps.com -->

  <url>
    <loc>https://phonebook.today/</loc>
    <lastmod>2024-06-26T06:47:17+00:00</lastmod>
  </url>
</urlset>`;

  return (
    <div>
      <h2>Sitemap Content:</h2>
      <pre>
        <code>{xmlContent}</code>
      </pre>
    </div>
  );
}

export default SitemapLoader;

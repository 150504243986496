import React, { useEffect, useState } from "react";
import axios from "axios";

const AddStore = () => {
  const [itemName, setItemName] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [itemCategory, setItemCategory] = useState("");
  const [itemPrice, setItemPrice] = useState("");
  const [itemCurrency, setItemCurrency] = useState("INR"); // Default currency
  const [itemImage, setItemImage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [subdomain, setSubdomain] = useState("");
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      resizeImage(file, 500, 500)
        .then((resizedImage) => {
          setItemImage(resizedImage);
        })
        .catch((error) => {
          console.error("Error resizing image:", error);
        });
    }
  };

  const handleCurrencyChange = (currency) => {
    setItemCurrency(currency);
    setDropdownOpen(false);
  };
  useEffect(() => {
    const currentURL = window.location.hostname;
    const parts = currentURL.split(".");
    if (parts.length >= 1) {
      setSubdomain(parts[0]);
    }
  }, [subdomain]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  console.log("test",itemCategory);
    // Convert item image to base64
    const base64Image = await convertToBase64(itemImage);
  
    // Send data to server
    try {
      const response = await axios.post(
        process.env.REACT_APP_APIURL + "store/add-item",
        {
          user_nick: subdomain,
          name: itemName,
          description: itemDescription,
          category: "#"+itemCategory,
          price: itemPrice,
          currency: itemCurrency,
          image: base64Image,
          payment: "gpay",
        }
      );
      console.log("Item added successfully:", response.data);
      // Redirect or show success message
  
      // Reset all fields to their initial states
      setItemName("");
      setItemDescription("");
      setItemCategory("");
      setItemPrice("");
      setItemCurrency("INR");
      setItemImage(null);
    } catch (error) {
      console.error("Error adding item:", error);
      // Show error message
    } finally {
      setIsSubmitting(false);
    }
  };
  

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      if (!file) return reject("No file provided");

      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const resizeImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const img = document.createElement("img");
      const canvas = document.createElement("canvas");
      const reader = new FileReader();

      reader.onload = (e) => {
        img.src = e.target.result;
        img.onload = () => {
          const ctx = canvas.getContext("2d");

          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height * maxWidth) / width);
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width * maxHeight) / height);
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              resolve(new File([blob], file.name, { type: file.type }));
            },
            file.type,
            0.7 // Quality parameter between 0 and 1
          );
        };
        img.onerror = (error) => reject(error);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const isFormValid = () => {
    return itemName && itemDescription && itemCategory && itemPrice && itemCurrency && itemImage;
  };

  return (
    <main>
      <section className="tp-shop-area pt-65 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12 col-xl-12">
              <div className="tp-shop-main-wrapper bg-white p-3">
                <h3 className="text-center mt-35 mb-45">/store</h3>
                <div className="allcontent_wrapper">
                  <div className="w-100 d-flex justify-content-center">
                    <div className="col-12 col-sm-10 col-md-8 col-lg-6">
                      <form onSubmit={handleSubmit}>
                        <div className="col-auto mb-2 text-center">
                          <label className="col-form-label f_22">Add Item</label>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-12 col-sm-12 col-md-4 col-form-label">Name</label>
                          <div className="col-12 col-sm-12 col-md-8">
                            <input
                              type="text"
                              className="form-control"
                              value={itemName}
                              onChange={(e) => setItemName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-12 col-sm-12 col-md-4 col-form-label">Description</label>
                          <div className="col-12 col-sm-12 col-md-8">
                            <textarea
                              className="form-control txtarea_hgt"
                              value={itemDescription}
                              onChange={(e) => setItemDescription(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-12 col-sm-12 col-md-4 col-form-label">Category</label>
                          <div className="col-12 col-sm-12 col-md-8">
                            <input 
                            type="text" 
                            className="form-control"
                            value={itemCategory} 
                            onChange={(e) => setItemCategory(e.target.value)}
                             />
                            <div className="f_grey">Hint: artwork</div>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-12 col-sm-12 col-md-4 col-form-label">Price</label>
                          <div className="col-12 col-sm-12 col-md-8">
                            <div className="input-group mb-3">
                              <button
                                className="btn btn-outline-secondary dropdown-toggle"
                                type="button"
                                aria-expanded={dropdownOpen}
                                onClick={() => setDropdownOpen(!dropdownOpen)}
                              >
                                {itemCurrency}
                              </button>
                              <ul className={`dropdown-menu dropdown_price ${dropdownOpen ? "show" : ""}`}>
                                <li>
                                  <a className="dropdown-item" onClick={() => handleCurrencyChange("INR")}>
                                    <i className="fa-solid fa-indian-rupee-sign me-2"></i>
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" onClick={() => handleCurrencyChange("USD")}>
                                    <i className="fa-solid fa-dollar-sign me-2"></i>
                                  </a>
                                </li>
                              </ul>
                              <input
                                type="text"
                                className="form-control"
                                aria-label="Text input with dropdown button"
                                value={itemPrice}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  // Regular expression to match only numeric values
                                  const regex = /^[0-9]*$/;
                                  // Check if the entered value matches the pattern
                                  if (regex.test(value) || value === '') {
                                    // Update the state only if the entered value is numeric or empty
                                    setItemPrice(value);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-12 col-sm-12 col-md-4 col-form-label">Item Photo</label>
                          <div className="col-12 col-sm-12 col-md-8">
                            <div className="position-relative">
                              <label className="btn-bs-file btn btn-dark">
                                Browse
                                <input type="file" onChange={handleImageChange} />
                              </label>
                            </div>
                            {itemImage && (
                              <div className="store_imgdiv mt-3">
                                <img src={URL.createObjectURL(itemImage)} alt="Preview" border={0} />
                                <div className="store_imgdel" onClick={() => setItemImage(null)}>
                                  <i className="fa-solid fa-xmark" />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="w-100 d-flex justify-content-center mt-55">
                          <button
                            type="submit"
                            className="btn btn-dark"
                            disabled={!isFormValid() || isSubmitting}
                          >
                            {isSubmitting ? "Submitting..." : "Save & Add Another Item"}
                          </button>
                        </div>
                      </form>
                      <div className="w-100 d-flex justify-content-center mt-25">
                        <a href="/manage-store" className="f_18 link1">
                          View Store
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AddStore;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StoreList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [listStore, setListStore] = useState(null);
  const [subdomain, setSubdomain] = useState("");
  const [profileinfo, setProfileinfo] = useState(null);
  const [profile, setProfile] = useState("");
  const [uriKey, setUriKey] = useState(() => {
    const searchParams = new URLSearchParams(location.search);
    const encodedId = searchParams.get("key");
    return encodedId || "";
  });
  const [isDelete, setIsDelete] = useState(false);
  const [whatsapp, setWhatsapp] = useState("");
  const [user, setUser] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [userCountry, setUserCountry] = useState("");
  const [userIP, setUserIP] = useState("");
  const [itemId, setItemId] = useState("");
  const [itemName, setItemName] = useState("");
  const [ItemOffers, setItemOffers] = useState("");
  const [ProfilePic, setProfilePic] = useState("");
  const [Item, setItem] = useState("");
  useEffect(() => {
    console.log("intialized with convoId from conversation", location);
    // let urixor = location.pathname.split("/")[2];
    // console.log({ urixor });
    let urixor = uriKey;
    console.log({ urixor });
    if (urixor) {
      const encryptedString = urixor;
      const key = process.env.REACT_APP_XOR_KEY;
      const decryptedString = xorDecrypt(encryptedString, key);
      console.log({ decryptedString });
      const parts = decryptedString.split("-!-");
      const dateTime = parts[0];
      const whatsappNumber = parts[1];
      console.log("Date and Time:", dateTime);
      console.log("WhatsApp Number:", whatsappNumber);
      setWhatsapp(whatsappNumber);
      var givenDate = new Date(dateTime);
      if (isNaN(givenDate.getTime())) {
        navigate("/");
      } else {
        var localTimezoneOffset = givenDate.getTimezoneOffset();

        var localTime = new Date(
          givenDate.getTime() - localTimezoneOffset * 60000
        );
        var currentDate = new Date();
        if (decryptedString && currentDate > localTime) {
          setIsDelete(false);
        } else if (currentDate < givenDate) {
          setIsDelete(true);
        } else {
          setIsDelete(true);
        }
      }
    }
    // }
  }, [location, whatsapp]);

  useEffect(() => {
    const currentURL = window.location.hostname;
    const parts = currentURL.split(".");
    if (parts.length >= 1) {
      setSubdomain(parts[0]);
    }
  }, []);

  const xorDecrypt = (encryptedData, key) => {
    const hexToBytes = (hex) => {
      const bytes = [];
      for (let i = 0; i < hex.length; i += 2) {
        bytes.push(parseInt(hex.substr(i, 2), 16));
      }
      return bytes;
    };

    const xorBytes = (arr1, arr2) => {
      const result = [];
      for (let i = 0; i < arr1.length; i++) {
        result.push(arr1[i] ^ arr2[i % arr2.length]);
      }
      return result;
    };

    const encryptedBytes = hexToBytes(encryptedData);
    const keyBytes = new TextEncoder().encode(key);
    const decryptedBytes = xorBytes(encryptedBytes, keyBytes);
    const decryptedText = String.fromCharCode(...decryptedBytes);
    return decryptedText;
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchData();
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [subdomain]);

  const fetchData = async () => {
    try {
      if (subdomain && subdomain !== "www" && subdomain !== "phonebook") {
        const response = await axios.post(
          `${process.env.REACT_APP_APIURL}read-content-file`,
          {
            "site-name": subdomain,
            "start-index": 0,
            "end-index": 1,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const jsonDataRes = response.data;
        const jsonData = JSON.parse(jsonDataRes);
        setProfile(jsonData.user_preferences.user_pfp);
        setProfileinfo(jsonData.user_preferences);
        setUser(true);
        window.localStorage.setItem("domain", subdomain);
        fetchStore();
      } else {
        console.log("fffff");
        navigate("/qrcode");
      }
    } catch (error) {
      navigate("/qrcode");
      console.log("Error fetching data:", error);
    }
  };

  // useEffect(() => {
  //   // const timeoutId = setTimeout(() => {
  //   fetchStore();
  // // }, 500);
  // // return () => clearTimeout(timeoutId);
  // }, [subdomain]);

  const fetchStore = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APIURL}store/get-listings`,
        {
          site: subdomain,
          start: 0,
          end: 19,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const jsonData = response.data;
      console.log(jsonData);
      setListStore(jsonData);
    } catch (error) {
      console.log("Error fetching data:", error);
      setListStore([]);
    }
  };
  const handleDelete = async (itemId) => {
    // Display a confirmation dialog before proceeding with deletion
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this item?"
    );

    // Check if the user confirmed the deletion
    if (confirmDelete) {
      try {
        // Make a POST request to the server to delete the item
        const response = await axios.post(
          process.env.REACT_APP_APIURL + "store/update-item",
          {
            item_id: itemId, // Provide the ID of the listing to delete
            type: "DEL", // Specify the type as 'DEL' for deletion
            attribute: "", // No need to specify an attribute for deletion
            new_value: "", // No need to specify a new value for deletion
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        // Log success message if deletion is successful
        console.log("Item deleted successfully:", response.data);
        // Remove the deleted item from the listStore object
        setListStore((prevListStore) => {
          const updatedListStore = { ...prevListStore };
          delete updatedListStore[itemId];
          return updatedListStore;
        });
        // Implement any further logic after successful deletion
      } catch (error) {
        // Log error message if deletion fails
        console.error("Error deleting item:", error);
        // Handle error scenario
      }
    }
  };
  useEffect(() => {
    const stripNonNumeric = (str) => {
      return str.replace(/\D/g, "");
    };

    // Check if the environment variable is available
    if (!process.env.REACT_APP_REDIRECT_PHONE) {
      console.error("REACT_APP_REDIRECT_PHONE environment variable not found.");
      return;
    }

    // Detect if the user is using a mobile device
    const userAgent = navigator.userAgent;
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      )
    );

    // Fetch user's IP address
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        setUserIP(data.ip);
        // Fetch geolocation information based on the user's IP address
        fetch(
          `https://ipinfo.io/${data.ip}/json?token=${process.env.REACT_APP_IP_TOKEN}`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("data", data);
            setUserCountry(data.country);
          });
      });
  }, [userCountry]);
  const RedirectApp = async (com, phone) => {
    console.log({ com });
    console.log({ userCountry });
    const stripNonNumeric = (str) => {
      return str.replace(/\D/g, "");
    };

    let redirectUrl = "";
    if (userCountry === "US") {
      // setQRCodeUrl(
      //   `sms:${process.env.REACT_APP_REDIRECT_PHONE}&body=Please set up a website for me`
      // );
      const phoneNumber = encodeURIComponent(
        phone ? phone : process.env.REACT_APP_REDIRECT_PHONE
      );
      const messageBody = encodeURIComponent(com);
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
        const smsUri = `sms:+${encodeURIComponent(
          phone ? phone : process.env.REACT_APP_REDIRECT_PHONE
        )}&body=${messageBody}`;
        redirectUrl = smsUri;
      } else if (/android/i.test(userAgent)) {
        const smsUri = `sms:+${phoneNumber}?body=${messageBody}`;
        redirectUrl = smsUri;
      } else {
        const smsUri = `sms:+${phoneNumber}?body=${messageBody}`;
        redirectUrl = smsUri;
      }
    } else {
      redirectUrl = `https://wa.me/${stripNonNumeric(
        phone ? phone : process.env.REACT_APP_REDIRECT_PHONE
      )}?text=${encodeURIComponent(com)}`;
    }
    console.log({ isMobile });
    window.open(redirectUrl, "_blank");
  };
  const openModal = (Id, Name) => {
    setItemId(Id);
    setItemName(Name);
  };

  const openOffer = (Id, ItemOffers,itemDetails) => {
    console.log("ItemOffers", ItemOffers);
    console.log("Id", Id);
    setItemId(Id);
    setItemOffers(ItemOffers);
    console.log(ItemOffers);
    setItem(itemDetails);
  };
  const handleUpdate = async () => {
    try {
      const status = document.getElementById("statusSelect").value;

      // Make a POST request to the server to update the status
      const response = await axios.post(
        process.env.REACT_APP_APIURL + "store/update-item",
        {
          item_id: itemId,
          type: "UPDATE",
          attribute: "status",
          new_value: status,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Status updated successfully:", response.data);
      // Update the ItemStatus value in the listStore object
      setListStore((prevListStore) => {
        const updatedListStore = { ...prevListStore };
        updatedListStore[itemId].ItemStatus = status; // Update the ItemStatus value
        return updatedListStore;
      });
      // Show success toaster
      toast.success("Status updated successfully");

      // Implement any further logic after successful update
    } catch (error) {
      console.error("Error updating status:", error);
      // Handle error scenario
    }
  };
  const getRandomColor = () => Math.floor(Math.random() * 256);

  const generateRandomColor = () => {
    const red = getRandomColor();
    const green = getRandomColor();
    const blue = getRandomColor();
    return [red, green, blue];
  };

  const calculateRandomGradientColors = () => {
    const startColor = generateRandomColor();
    const endColor = generateRandomColor();
    return {
      startColor: `rgb(${startColor.join(", ")})`,
      endColor: `rgb(${endColor.join(", ")})`,
    };
  };
  return (
    <main>
      <section className="tp-shop-area pt-65 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12 col-xl-12">
              <div className="tp-shop-main-wrapper bg-white p-3">
                <h3 className="text-center mt-35 mb-45">/store</h3>
                {listStore !== null &&
                Object.keys(listStore).length === 0 &&
                isDelete ? (
                  <div>
                    <div className="w-100 d-flex flex-row flex-wrap justify-content-center mt-55">
                      <div className="w-100 text-center f_20 mb-25">
                        You can add items in your store by clicking on the
                        button below
                      </div>
                      <a href="/addStore" className="btn btn-dark btn-lg">
                        <i className="fa-solid fa-circle-plus me-2"></i>Add Item
                      </a>
                    </div>
                  </div>
                ) : listStore !== null &&
                  Object.keys(listStore).length === 0 &&
                  isDelete === false ? (
                  <div>
                    <div className="w-100 d-flex flex-row flex-wrap justify-content-center mt-55">
                      <div className="w-100 text-center f_20 mb-25">
                        No items are added yet
                      </div>
                    </div>
                  </div>
                ) : listStore !== null &&
                  Object.keys(listStore).length !== 0 ? (
                  <div className="allcontent_wrapper">
                    {isDelete && (
                      <div className="w-100 d-flex flex-row flex-wrap justify-content-end mb-15">
                        <a href="/addStore" className="btn btn-dark">
                          <i className="fa-solid fa-circle-plus me-2" /> Add
                          Item
                        </a>
                      </div>
                    )}
                    {Object.keys(listStore).map((itemId) => {
                      const itemDetails = listStore[itemId];
                      console.log("ttT", itemDetails);
                      return (
                        <div
                          className="store_productitem d-md-flex mb-20"
                          key={itemId}
                        >
                          <div className="store_product_thumb p-relative fix px-3">
                            <div
                              className={`store_itemstat ${
                                itemDetails.ItemStatus === "Available"
                                  ? "bg_green"
                                  : "bg_orange"
                              }`}
                            >
                              {itemDetails.ItemStatus === "Available"
                                ? "Active"
                                : "Sold Out"}
                            </div>
                            <img
                              src={itemDetails.ItemImage}
                              alt={itemDetails.ItemName}
                            />
                          </div>
                          <div className="store_product_content p-relative">
                            <div className="tp-product-content-2 pt-15">
                              <h3 className="tp-product-title-2">
                                {itemDetails.ItemName}
                              </h3>
                              <p>{itemDetails.ItemDescription}</p>
                              <div className="d-flex flex-row flex-wrap align-items-center mb-3">
                                <div className="store_product_price">
                                  <i
                                    className={`fa-solid ${
                                      itemDetails.ItemCurrency === "USD"
                                        ? "fa-dollar-sign"
                                        : "fa-indian-rupee-sign"
                                    } me-2`}
                                  />
                                  {itemDetails.ItemCurrency === "USD"
                                    ? `${itemDetails.ItemPrice}.00`
                                    : `${itemDetails.ItemPrice}.00`}
                                </div>
                              </div>
                              <div className="d-flex flex-row flex-wrap align-items-center">
                                {/* <div className="me-3 mb-2">
                                  <a href="#" className="btn btn-dark">
                                    Buy
                                  </a>
                                </div> */}
                                <div className="me-3 mb-2">
                                  <a
                                    {...(isMobile && {
                                      target: "_blank",
                                    })}
                                    onClick={
                                      isMobile
                                        ? () =>
                                            RedirectApp(
                                              `/make-offer ${itemId} `
                                            )
                                        : undefined
                                    }
                                    className="btn btn-dark"
                                  >
                                    Make An Offer
                                  </a>
                                </div>
                                <div className="me-3 mb-2">
                                  {Object.keys(
                                    JSON.parse(itemDetails.ItemOffers)
                                  ).length !== 0 ? (
                                    <a
                                      data-bs-toggle="modal"
                                      data-bs-target="#modalviewoffer"
                                      className="btn btn-dark"
                                      onClick={() =>
                                        openOffer(
                                          itemId,
                                          JSON.parse(itemDetails.ItemOffers),itemDetails
                                        )
                                      }
                                    >
                                      View Offer
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                {isDelete && (
                                  <div className="me-3 mb-2">
                                    <a
                                      data-bs-toggle="modal"
                                      data-bs-target="#modalupdatestatus"
                                      className="btn btn-dark"
                                      onClick={() =>
                                        openModal(itemId, itemDetails.ItemName)
                                      }
                                    >
                                      Update Status
                                    </a>
                                  </div>
                                )}

                                {isDelete && (
                                  <div>
                                    <a onClick={() => handleDelete(itemId)}>
                                      <i className="fa-solid fa-trash-can f_22" />
                                    </a>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
      {/* Modal */}
      <div
        className="modal fade"
        id="modalupdatestatus"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="modalupdatestatusLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fa-solid fa-xmark" />
            </button>
            <div className="modal-header">
              <h5 className="modal-title" id="modalupdatestatusLabel">
                {itemName}
              </h5>
            </div>
            <div className="modal-body">
              <div className="flexdiv">
                <div className="mb-3 row">
                  <label className="col-12 col-sm-12 col-md-4 col-form-label">
                    Status
                  </label>
                  <div className="col-12 col-sm-12 col-md-8">
                    <select id="statusSelect" className="form-select">
                      <option value="Available">Active</option>
                      <option value="Sold Out">Sold Out</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <a
                href="#"
                className="btn btn-success"
                onClick={handleUpdate}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Update
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* View Review Modal */}
      <div
        className="modal fade"
        id="modalviewoffer"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="modalviewofferLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fa-solid fa-xmark" />
            </button>
            <div className="modal-header">
              <h5 className="modal-title" id="modalviewofferLabel">
                <span className="storemodalimg">
                  <img src={Item.ItemImage} border={0} />
                </span>{" "}
                {Item.ItemName}
              </h5>
            </div>
            <div className="modal-body">
              <div className="store_viewoffer_list scrollbar_style">
                {Object.keys(ItemOffers).length !== 0 &&
                  Object.entries(ItemOffers).map(([phoneNumber, offerText]) => {
                    const { startColor, endColor } =
                      calculateRandomGradientColors();
                    return (
                      <div
                        key={phoneNumber}
                        className="store_viewoffer_item mb-2"
                      >
                        <div className="store_viewoffer_thumb">
                          <div className="tp_profilephoto_wrapper">
                            <div
                              className="tp_profilephoto_imgwrapper"
                              style={
                                !ProfilePic || ProfilePic === "None"
                                  ? {
                                      background: `linear-gradient(71deg, ${startColor} 12%, ${endColor} 61%)`,
                                      borderRadius: "50%",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      color: "#fff",
                                      fontSize: "24px",
                                      fontWeight: "bold",
                                      textShadow:
                                        "1px 1px 1px rgba(0, 0, 0, 0.5)",
                                    }
                                  : {}
                              }
                            >
                              {/* <img src="assets/img/avatar1.png" border="0"/> */}
                            </div>
                            {/* <div className="tp_profilephoto_imgwrapper">
                            <img src="assets/img/avatar1.png" border={0} />
                          </div> */}
                          </div>
                        </div>
                        <div className="store_viewoffer_content">
                          <div className="me-auto">
                            <div className="f_grey">
                              {phoneNumber}{" "}
                              {/* <i className="fa-solid fa-circle f_6" /> today
                              11:50am */}
                            </div>
                            <p className="w-100 mt-0">
                            <i className={`fa-solid ${Item.ItemCurrency === "INR" ? "fa-indian-rupee-sign" : "fa-dollar-sign"} me-2`} />
                              <span className="f_17">{offerText}</span>
                            </p>
                          </div>
                          <div className="ms-auto">
                            <a
                              href="#"
                              // target="_blank"
                              className="m-2 link"
                              onClick={() => RedirectApp("Hi", phoneNumber)}
                              // onClick={
                              //   isMobile
                              //     ? 
                              //     () => RedirectApp("Hi", phoneNumber)
                              //     : undefined
                              // }
                            >
                              <i className={userCountry === "US" ? "fa-regular fa-message f_24" : "fa-brands fa-whatsapp f_26"} />
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default StoreList;

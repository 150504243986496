// import SideNav from "./components/SideNav";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./layout/header";
import Footer from "./layout/footer";
import Media from "./components/media";
import MediaList from "./components/medialist";
import QRCodePage from "./components/qrcode";
import QRCodePageTest from "./components/qrcodetest";
import ThumbnailPreview from "./components/linkpreview";
import PDF_Viewer from "./components/pdfviewer";
import DeleteMediaList from "./components/deletemedialist";
import Help from "./components/help";
import FAQ from "./components/faq";
import Settings from "./components/settings";
import UpComingFeatures from "./components/upcoming_features";
import Gallery from "./components/gallery";

import PostHogPageviewTracker from "./components/PostHogPageviewTracker";
import OwnPreview from "./components/ownpreview";
import StoreList from "./components/store";
import AddStore from "./components/store_add";
import Messenger from "./components/messenger";
import SitemapLoader from "./components/sitemap";
const App = () => {
  return (
    // <div style={styles.container}>
    
      <BrowserRouter>
      <div class="content">
        {/* <OwnPreview /> */}
        <Header />
        {/* <SideNav /> */}
        <Routes>
          <Route path="/" element={<MediaList />} />
          <Route path="/list" element={<MediaList />} />
          <Route path="/:date" element={<MediaList />} />
          <Route path="/:date/:id" element={<MediaList />} />
          <Route path="/qrcode" element={<QRCodePage />} />
          <Route path="/info" element={<QRCodePageTest />} />
          <Route path="/pdf" element={<PDF_Viewer />} />
          <Route path="/delete" element={<DeleteMediaList />} />
          <Route path="/delete/:key" element={<DeleteMediaList />} />
          <Route path="/help" element={<Help />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/upcoming_features" element={<UpComingFeatures />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/manage-store" element={<StoreList />} />
          <Route path="/addStore" element={<AddStore />} />
          <Route path="/messenger" element={<Messenger />} />
        </Routes>
        <PostHogPageviewTracker />
        </div>
        <Footer />
      </BrowserRouter>
    
  );
};

const styles = {
  container: {
    display: "flex",
    height: "100%",
    width: "100%",
    backgroundColor: "#343541",
  },
};

export default App;

import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
const QRCodeComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [qrCodeUrl, setQRCodeUrl] = useState("");
  const [smsqrCodeUrl, setSMSQRCodeUrl] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [userCountry, setUserCountry] = useState("");
  const [userIP, setUserIP] = useState("");
  const [subdomain, setSubdomain] = useState();
  const [listfolders, setListFolers] = useState([]);
  const [user, setUser] = useState();

  useEffect(() => {
    // Accessing window.location to get the URL
    const currentURL = window.location.hostname;
    const parts = currentURL.split(".");
    if (parts.length >= 1) {
      setSubdomain(parts[0]);
      console.log("parts[0]", parts[0]);
    }
  }, [subdomain]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchData();
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [subdomain]);
  const fetchData = async () => {
    try {
      if (subdomain !== "www" && subdomain !== "phonebook") {
        const response = await axios.post(
          process.env.REACT_APP_APIURL + "read-content-file",
          {
            "site-name": subdomain,
            "start-index": 0,
            "end-index": 1,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        // handleClose(); // This should now work fine since handleClose is defined
        const jsonDataRes = response.data;
        const jsonData = JSON.parse(jsonDataRes);
        console.log(jsonData.user_preferences);
        console.log(jsonData.user_preferences.ProfilePic);
        window.localStorage.setItem("domain", subdomain);
        setUser(true);
      } else {
        setUser(false);
        // navigate("/qrcode");
      }
    } catch (error) {
      setUser(false);
      //   navigate("/qrcode");
      console.log("Error fetching data:", error);
    }
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (user === false) {
        fetchFolders();
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [user]);
  const fetchFolders = async () => {
    try {
      const listResponse = await axios.get(
        process.env.REACT_APP_APIURL + "list-folders",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // handleClose(); // This should now work fine since handleClose is defined
      const listdata = listResponse.data;
      const list_folder = JSON.parse(listdata);
      console.log({ list_folder });
      setListFolers(list_folder);
    } catch (error) {
      navigate("/qrcode");
      console.log("Error fetching data:", error);
    }
  };
  const getRandomColor = () => Math.floor(Math.random() * 256);

  const generateRandomColor = () => {
    const red = getRandomColor();
    const green = getRandomColor();
    const blue = getRandomColor();
    return [red, green, blue];
  };

  const calculateRandomGradientColors = () => {
    const startColor = generateRandomColor();
    const endColor = generateRandomColor();
    return {
      startColor: `rgb(${startColor.join(", ")})`,
      endColor: `rgb(${endColor.join(", ")})`,
    };
  };

  const { startColor, endColor } = calculateRandomGradientColors();
  const gradientStyle = {
    background: `linear-gradient(71deg, ${startColor} 12%, ${endColor} 61%)`,
    // width: "200px",
    // height: "200px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    fontSize: "24px",
    fontWeight: "bold",
    textShadow: "1px 1px 1px rgba(0, 0, 0, 0.5)",
  };
  return (
    <>
      <Helmet>
        <title>
        Gallery - Phonebook | How to Create a Free Website, Website Builder
        </title>
        <meta
          name="description"
          content="Create a free website using WhatsApp and text messaging.  
Move all your files from phone to your website. Store endless amounts of data.  
Unlimited storage space."
        />
        <meta
          name="keywords"
          content="How to create a free website, website builder, unlimited storage space, easy uploads"
        />
      </Helmet>
    <main>
      {/* shop area start */}
      <section className="tp-shop-area pt-65 pb-120">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-lg-8 col-xl-8">
              <div className="tp-shop-main-wrapper bg-white mt-15">
                <h3 className="text-center mt-35 mb-45"> Gallery </h3>
                <div className="phonesites_gallery_list row m-0">
                  {listfolders
                    ? Object.entries(listfolders).map(
                        ([key, row], rowIndex) => {
                          const { startColor, endColor } =
                            calculateRandomGradientColors(); // Calculate colors dynamically
                          return (
                            <div
                              className="col-12 col-md-12 col-lg-12 phonesites_gallery_listbrdr"
                              key={key}
                            >
                              <a
                                className="crsr_pntr w-100 link"
                                href={`https://${row.Nick}.phonebook.today`}
                                target="_blank"
                              >
                                <div className="phnebk_sites mb-15">
                                  <div className="phnebk_sites__thumb pt-3">
                                    <div className="tp_profilephoto_wrapper">
                                      <div
                                        className="tp_profilephoto_imgwrapper"
                                        style={
                                          !row.ProfilePic ||
                                          row.ProfilePic === "None"
                                            ? {
                                                background: `linear-gradient(71deg, ${startColor} 12%, ${endColor} 61%)`,
                                                borderRadius: "50%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                color: "#fff",
                                                fontSize: "24px",
                                                fontWeight: "bold",
                                                textShadow:
                                                  "1px 1px 1px rgba(0, 0, 0, 0.5)",
                                              }
                                            : {}
                                        }
                                      >
                                        {row.ProfilePic !== "None" &&
                                        row.ProfilePic ? (
                                          <img
                                            src={row.ProfilePic}
                                            alt="Profile"
                                            border="0"
                                          />
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="phnebk_sites__content">
                                    <div className="content-top text-center">
                                      <h4 className="mt-15 mb-15 w-100">
                                        {row.Nick}
                                      </h4>
                                      <p className="w-100">
                                        {row.Tagline ? row.Tagline : ""}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          );
                        }
                      )
                    : null}

                  {/* <div className="w-100 d-flex justify-content-center mt-55">
              <a href="#" className="btn btn-dark">View More</a>
            </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    </>
  );
};

export default QRCodeComponent;

import React, { Component, useContext, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import faq from "../img/faq.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Helmet } from "react-helmet";
function FAQ() {
  const navigate = useNavigate();
  const [openCollapse, setOpenCollapse] = useState(null);

  // useEffect(() => {
  //   // Initialize Bootstrap components after the component has mounted
  //   // You can also use other lifecycle methods like componentDidMount for class components
  //   // or the useEffect hook with appropriate dependencies.
  //   window.$(".collapse").collapse(); // Initialize all collapse elements
  // }, []);
  const toggleCollapse = (collapseId) => {
    setOpenCollapse((prevOpenCollapse) =>
      prevOpenCollapse === collapseId ? null : collapseId
    );
  };
  return (
    <>
      <Helmet>
        <title>
          FAQ - What is Phonebook | Free Website Using Messaging apps
        </title>
        <meta
          name="description"
          content="To get started, simply send a message to the dedicated Phonebook number from your preferred messaging app. You will receive a welcome message with further instructions on how to set up your Phonebook website."
        />
        <meta
          name="keywords"
          content="website for free, what is phonebook, send a message, messaging apps, how to set up website"
        />
      </Helmet>
      <main>
        {/* shop area start */}
        <section className="tp-shop-area pt-65 pb-120">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-12 col-xl-12">
                <div className="tp-shop-main-wrapper bg-white p-3">
                  <h3 className="text-center mt-35 mb-45">FAQ</h3>
                  <div className="allcontent_wrapper">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 order-1 order-md-0">
                      <div className="content_faqimg">
                        <img src={faq} />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-7 order-0 order-md-1">
                      <div
                        className="accordion accordion-flush"
                        id="accordionFaq"
                      >
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="flush-headingOne"
                          >
                            <button
                              className={`accordion-button ${
                                openCollapse === "flush-collapseOne"
                                  ? ""
                                  : "collapsed"
                              }`}
                              type="button"
                              // data-bs-toggle="collapse"
                              // data-bs-target="#flush-collapseOne"
                              // aria-expanded="false"
                              // aria-controls="flush-collapseOne"
                              onClick={() =>
                                toggleCollapse("flush-collapseOne")
                              }
                            >
                              What is Phonebook?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseOne"
                            className={`accordion-collapse collapse ${
                              openCollapse === "flush-collapseOne" ? "show" : ""
                            }`}
                            aria-labelledby="flush-headingOne"
                            data-bs-parent="#accordionFaq"
                          >
                            <div className="accordion-body">
                              Phonebook is a platform that allows you to create
                              and manage your own website, called a Phonebook,
                              by sending messages to a dedicated number from
                              your messaging applications, such as SMS or
                              WhatsApp.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="flush-headingTwo"
                          >
                            <button
                              className={`accordion-button ${
                                openCollapse === "flush-collapseTwo"
                                  ? ""
                                  : "collapsed"
                              }`}
                              onClick={() =>
                                toggleCollapse("flush-collapseTwo")
                              }
                              type="button"
                              // data-bs-toggle="collapse"
                              // data-bs-target="#flush-collapseTwo"
                              // aria-expanded="false"
                              // aria-controls="flush-collapseTwo"
                            >
                              How do I get started with Phonebook?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseTwo"
                            className={`accordion-collapse collapse ${
                              openCollapse === "flush-collapseTwo" ? "show" : ""
                            }`}
                            aria-labelledby="flush-headingTwo"
                            data-bs-parent="#accordionFaq"
                          >
                            <div className="accordion-body">
                              To get started, simply send a message to the
                              dedicated Phonebook number from your preferred
                              messaging app. You will receive a welcome message
                              with further instructions on how to set up your
                              Phonebook website.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="flush-headingThree"
                          >
                            <button
                              className={`accordion-button ${
                                openCollapse === "flush-collapseThree"
                                  ? ""
                                  : "collapsed"
                              }`}
                              onClick={() =>
                                toggleCollapse("flush-collapseThree")
                              }
                              type="button"
                              // data-bs-toggle="collapse"
                              // data-bs-target="#flush-collapseThree"
                              // aria-expanded="false"
                              // aria-controls="flush-collapseThree"
                            >
                              What messaging apps are currently supported?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseThree"
                            className={`accordion-collapse collapse ${
                              openCollapse === "flush-collapseThree"
                                ? "show"
                                : ""
                            }`}
                            aria-labelledby="flush-headingThree"
                            data-bs-parent="#accordionFaq"
                          >
                            <div className="accordion-body">
                              As of now, Phonebook supports SMS and WhatsApp.
                              More apps, including but not limited to Telegram,
                              are planned for the future.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="flush-headingFour"
                          >
                            <button
                              className={`accordion-button ${
                                openCollapse === "flush-collapseFour"
                                  ? ""
                                  : "collapsed"
                              }`}
                              onClick={() =>
                                toggleCollapse("flush-collapseFour")
                              }
                              type="button"
                              // data-bs-toggle="collapse"
                              // data-bs-target="#flush-collapseFour"
                              // aria-expanded="false"
                              // aria-controls="flush-collapseFour"
                            >
                              How do I get help with Phonebook?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseFour"
                            className={`accordion-collapse collapse ${
                              openCollapse === "flush-collapseFour"
                                ? "show"
                                : ""
                            }`}
                            aria-labelledby="flush-headingFour"
                            data-bs-parent="#accordionFaq"
                          >
                            <div className="accordion-body">
                              To get help, send the command{" "}
                              <span className="f_bold">/help</span> to the
                              dedicated Phonebook number. You will receive a
                              list of available commands for various actions and
                              activities.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="flush-headingFive"
                          >
                            <button
                              className={`accordion-button ${
                                openCollapse === "flush-collapseFive"
                                  ? ""
                                  : "collapsed"
                              }`}
                              onClick={() =>
                                toggleCollapse("flush-collapseFive")
                              }
                              type="button"
                              // data-bs-toggle="collapse"
                              // data-bs-target="#flush-collapseFive"
                              // aria-expanded="false"
                              // aria-controls="flush-collapseFive"
                            >
                              What information can I get about my Phonebook
                              website?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseFive"
                            className={`accordion-collapse collapse ${
                              openCollapse === "flush-collapseFive"
                                ? "show"
                                : ""
                            }`}
                            aria-labelledby="flush-headingFive"
                            data-bs-parent="#accordionFaq"
                          >
                            <div className="accordion-body">
                              Send the command{" "}
                              <span className="f_bold">/info</span> to retrieve
                              information about your Phonebook website, such as
                              your unique URL and current settings.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="flush-headingSix"
                          >
                            <button
                              className={`accordion-button ${
                                openCollapse === "flush-collapseSix"
                                  ? ""
                                  : "collapsed"
                              }`}
                              onClick={() =>
                                toggleCollapse("flush-collapseSix")
                              }
                              type="button"
                              // data-bs-toggle="collapse"
                              // data-bs-target="#flush-collapseSix"
                              // aria-expanded="false"
                              // aria-controls="flush-collapseSix"
                            >
                              How can I customize my Phonebook website?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseSix"
                            className={`accordion-collapse collapse ${
                              openCollapse === "flush-collapseSix" ? "show" : ""
                            }`}
                            aria-labelledby="flush-headingSix"
                            data-bs-parent="#accordionFaq"
                          >
                            <div className="accordion-body">
                              Use the <span className="f_bold">/settings</span>{" "}
                              command to set up a custom domain, change your
                              profile photo, and adjust other preferences for
                              your Phonebook site.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="flush-headingSeven"
                          >
                            <button
                              className={`accordion-button ${
                                openCollapse === "flush-collapseSeven"
                                  ? ""
                                  : "collapsed"
                              }`}
                              onClick={() =>
                                toggleCollapse("flush-collapseSeven")
                              }
                              type="button"
                              // data-bs-toggle="collapse"
                              // data-bs-target="#flush-collapseSeven"
                              // aria-expanded="false"
                              // aria-controls="flush-collapseSeven"
                            >
                              Can I see how many people have visited my
                              Phonebook website?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseSeven"
                            className={`accordion-collapse collapse ${
                              openCollapse === "flush-collapseSeven"
                                ? "show"
                                : ""
                            }`}
                            aria-labelledby="flush-headingSeven"
                            data-bs-parent="#accordionFaq"
                          >
                            <div className="accordion-body">
                              Yes! Send the{" "}
                              <span className="f_bold">/analytics</span> command
                              to retrieve statistics for your Phonebook site,
                              including visitor count, page views, and
                              engagement metrics.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="flush-headingEight"
                          >
                            <button
                              className={`accordion-button ${
                                openCollapse === "flush-collapseEight"
                                  ? ""
                                  : "collapsed"
                              }`}
                              onClick={() =>
                                toggleCollapse("flush-collapseEight")
                              }
                              type="button"
                              // data-bs-toggle="collapse"
                              // data-bs-target="#flush-collapseEight"
                              // aria-expanded="false"
                              // aria-controls="flush-collapseEight"
                            >
                              How do I remove content from my Phonebook website?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseEight"
                            className={`accordion-collapse collapse ${
                              openCollapse === "flush-collapseEight"
                                ? "show"
                                : ""
                            }`}
                            aria-labelledby="flush-headingEight"
                            data-bs-parent="#accordionFaq"
                          >
                            <div className="accordion-body">
                              To remove selected items from your Phonebook site
                              and keep your content up-to-date, use the{" "}
                              <span className="f_bold">/delete</span> command.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="flush-headingNine"
                          >
                            <button
                              className={`accordion-button ${
                                openCollapse === "flush-collapseNine"
                                  ? ""
                                  : "collapsed"
                              }`}
                              onClick={() =>
                                toggleCollapse("flush-collapseNine")
                              }
                              type="button"
                              // data-bs-toggle="collapse"
                              // data-bs-target="#flush-collapseNine"
                              // aria-expanded="false"
                              // aria-controls="flush-collapseNine"
                            >
                              Can I add new features to my Phonebook website?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseNine"
                            className={`accordion-collapse collapse ${
                              openCollapse === "flush-collapseNine"
                                ? "show"
                                : ""
                            }`}
                            aria-labelledby="flush-headingNine"
                            data-bs-parent="#accordionFaq"
                          >
                            <div className="accordion-body">
                              Phonebook is constantly evolving, and new features
                              and commands will be added in the future to
                              enhance your experience. Stay tuned for updates!
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="flush-headingTen"
                          >
                            <button
                              className={`accordion-button ${
                                openCollapse === "flush-collapseTen"
                                  ? ""
                                  : "collapsed"
                              }`}
                              onClick={() =>
                                toggleCollapse("flush-collapseTen")
                              }
                              type="button"
                              // data-bs-toggle="collapse"
                              // data-bs-target="#flush-collapseTen"
                              // aria-expanded="false"
                              // aria-controls="flush-collapseTen"
                            >
                              Is there a limit to the number of messages I can
                              send to manage my Phonebook website?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseTen"
                            className={`accordion-collapse collapse ${
                              openCollapse === "flush-collapseTen" ? "show" : ""
                            }`}
                            aria-labelledby="flush-headingTen"
                            data-bs-parent="#accordionFaq"
                          >
                            <div className="accordion-body">
                              Currently, there are no limits on the number of
                              messages you can send to manage your Phonebook
                              website. However, please use the service
                              responsibly and refrain from sending excessive or
                              unnecessary messages.
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="pt-25 pb-25">
                        If you have any further questions or need assistance,
                        feel free to send the{" "}
                        <span className="f_bold">/help</span> command to the
                        dedicated Phonebook number, and we'll be happy to help!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* shop area end */}
      </main>
    </>
  );
}

export default FAQ;

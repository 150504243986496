import React, { useState, useEffect } from "react";
import axios from "axios";
import $ from "jquery";
import Media from "./media";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import bootstrap from "bootstrap";

import "bootstrap/dist/js/bootstrap.bundle";
import { Modal } from "bootstrap";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import avatar from "../img/avatar1.png";
import PDFViewerComponent from "./pdfviewer";

// type ValuePiece = Date | null;

// type Value = ValuePiece | [ValuePiece, ValuePiece];

const formatDate = (date, cal) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (cal === "cal") {
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const currentDate = new Date();
    const dateToCheck = new Date(date);
    if (dateToCheck.toDateString() === currentDate.toDateString()) {
      return "Today";
    } else {
      const year = date.getFullYear().toString().slice(-2);
      const months = month.toString().slice(0, 3); // Get the month index

      return `${day} ${months} '${year}`;
    }
  } else if (cal === "uri") {
    return `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(
      6,
      8
    )}`;
  } else if (cal === "copy") {
    const dateObject = new Date(date);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
    const day = String(dateObject.getDate()).padStart(2, "0");
    return `${year}${month}${day}`;
  } else if (cal === "post") {
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var givenDate = new Date(date);
    var localTimezoneOffset = givenDate.getTimezoneOffset();

    var localTime = new Date(givenDate.getTime() - localTimezoneOffset * 60000);
    var day = localTime.getDate();
    var monthIndex = localTime.getMonth();
    var year = localTime.getFullYear();

    // Get hours and minutes
    var hours = localTime.getHours();
    var minutes = localTime.getMinutes();

    // Convert hours to 12-hour format and determine AM/PM
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    // Pad minutes with leading zero if needed
    minutes = minutes < 10 ? "0" + minutes : minutes;

    // Construct the formatted date string
    var formattedString =
      day +
      " " +
      months[monthIndex] +
      " " +
      year +
      ", " +
      hours +
      ":" +
      minutes +
      ampm;

    return formattedString;
  } else {
    return `${monthNames[date.getMonth()]} - ${date.getFullYear()}`;
  }
};
function DeleteMediaList() {
  const navigate = useNavigate();
  const location = useLocation();
  const [datalist, setData] = useState([]);
  const [alldatalist, setAllData] = useState([]);
  const [subdomain, setSubdomain] = useState(null);
  const [modalImageUrl, setModalImageUrl] = useState("");
  const [modalAudioUrl, setModalAudioUrl] = useState("");
  const [modalVideoUrl, setModalVideoUrl] = useState("");
  const [modalPdfUrl, setModalPdfUrl] = useState("");
  const [modalMultiImageUrl, setModalMultiImageUrl] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [daydata, setDaydata] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [modalInstance, setModalInstance] = useState(null);
  const [modalmultiInstance, setModalMultiInstance] = useState(null);
  const [modaldeleteInstance, setModalDeleteInstance] = useState(null);
  const [isDelete, setIsDelete] = useState();

  const [uriId, setUriId] = useState(() => {
    const searchParams = new URLSearchParams(location.search);
    const encodedId = searchParams.get("id");
    if (encodedId) {
      return JSON.parse(atob(encodedId));
    } else {
      return "";
    }
  });
  const [uriKey, setUriKey] = useState(() => {
    const searchParams = new URLSearchParams(location.search);
    const encodedId = searchParams.get("key");
    if (encodedId) {
      return encodedId;
    } else {
      return "";
    }
  });
  const [selectDate, setSelectDate] = useState("");
  const [value, onChange] = useState(() => {
    let uridate = location.pathname.split("/")[1];
    var selectDate1 = selectDate;
    console.log("ssssss", selectDate1);

    if (selectDate1) {
      return selectDate1;
    } else {
      return new Date();
    }
  });
  const [pageIndex, setPageIndex] = useState(0);
  const [viewoption, setViewoption] = useState();
  const [smsqrCodeUrl, setSMSQRCodeUrl] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [userCountry, setUserCountry] = useState("");
  const [userIP, setUserIP] = useState("");
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [showDeleteOptions, setShowDeleteOptions] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState({});
  const [profile, setProfile] = useState("");
  const [about_me, setAboutMe] = useState("");
  const handleHover = () => {
    setShowCheckboxes(true);
  };
  const openModal = (imageUrl) => {
    setModalImageUrl(imageUrl);
  };

  const openMultiModal = (imageUrl) => {
    setModalMultiImageUrl(imageUrl);
  };
  const openModalPdf = (imageUrl) => {
    setModalPdfUrl(imageUrl);
  };
  useEffect(() => {
    console.log("intialized with convoId from conversation", location);
    // let urixor = location.pathname.split("/")[2];
    // console.log({ urixor });
    let urixor = uriKey;
    console.log({ urixor });
    if (urixor) {
      const encryptedString = urixor;
      const key = process.env.REACT_APP_XOR_KEY;
      const decryptedString = xorDecrypt(encryptedString, key);
      console.log({decryptedString})
      const parts = decryptedString.split("-!-");
      const dateTime = parts[0];
      const whatsappNumber = parts[1];
      var givenDate = new Date(dateTime);
      console.log({ givenDate });
      if (isNaN(givenDate.getTime())) {
        navigate("/");
      } else {
        var localTimezoneOffset = givenDate.getTimezoneOffset();

        var localTime = new Date(
          givenDate.getTime() - localTimezoneOffset * 60000
        );
        var currentDate = new Date();
        if (decryptedString && currentDate > localTime) {
          setIsDelete(false);
        } else if (currentDate < givenDate) {
          setIsDelete(true);
        } else {
          setIsDelete(true);
        }
      }
    } else {
      navigate("/");
    }
    // }
  }, [location, alldatalist, datalist]);

  useEffect(() => {
    // Accessing window.location to get the URL
    const currentURL = window.location.hostname;
    const parts = currentURL.split(".");
    if (parts.length >= 1) {
      setSubdomain(parts[0]);
      console.log("parts[0]", parts[0]);
    }
  }, [subdomain]);
  useEffect(() => {
    if (value instanceof Date && !isNaN(value)) {
      setPageIndex(0);
      const startDate = new Date(value);
      const endDate = new Date(value);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      const formattedStartDate =
        endDate.toISOString().slice(0, 10) + " 00:01:00.000000";
      const formattedEndDate =
        endDate.toISOString().slice(0, 10) + " 23:59:59.000000";
      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
    } else {
      console.log("Invalid date value:", value);
    }
  }, [value]);
  // Function to disable dates after the maximum date
  const tileDisabled = ({ date }) => {
    const maxDate = new Date();
    return date > maxDate;
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Your code to execute after one second
      // console.log("One second has passed", uriId);
      fetchData();
    }, 1000);

    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, [startDate, endDate]);

  const handleSubmit = () => {
    setData([]);
    // const timeoutId = setTimeout(() => {
    //   fetchData(pageIndex);
    // }, 1000);
    // return () => clearTimeout(timeoutId);
  };
  const fetchData = async () => {
    try {
      const currentURL = window.location.hostname;
      const parts = currentURL.split(".");
      if (parts.length >= 1 && parts[0] !== "www" && parts[0] !== "phonebook") {
        setSubdomain(parts[0]);
        // console.log("value1", value);
        // console.log("start1", startDate);
        let currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        let check_date = new Date(value);
        check_date.setHours(0, 0, 0, 0);
        // setSelectDate(value);
        console.log({ pageIndex });
        const response = await axios.post(
          process.env.REACT_APP_APIURL + "read-content-file",
          {
            ...(value && check_date.getTime() === currentDate.getTime()
              ? {
                  "site-name": parts[0],
                  "start-index": pageIndex * 20,
                  "end-index": pageIndex * 20 + 19,
                }
              : {
                  "site-name": parts[0],
                  "start-date": startDate,
                  "end-date": endDate,
                  "start-index": pageIndex * 20,
                  "end-index": pageIndex * 20 + 19,
                }),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        // handleClose(); // This should now work fine since handleClose is defined
        const jsonDataRes = response.data;
        const jsonData = JSON.parse(jsonDataRes);
        setProfile(jsonData.user_preferences.user_pfp);
        window.localStorage.setItem("domain", parts[0]);
        setAboutMe(jsonData.user_preferences.user_tagline);
        // console.log({ jsonData });
        // Check if post_content is empty or null
        const isPostContentNull = jsonData.post_content === null;
        const isPostContentEmpty =
          !isPostContentNull && Object.keys(jsonData.post_content).length === 0;
        if (isPostContentNull || isPostContentEmpty) {
          setDaydata(true);
          setViewoption(true);
          setData((prevDataList) => [...prevDataList, ...[]]);
        } else {
          if (Object.keys(jsonData.post_content).length < 20) {
            setViewoption(true);
          } else {
            setViewoption(false);
          }
          // console.log(typeof jsonData.post_content);

          setAllData((prevIndex) => ({
            ...prevIndex,
            ...jsonData.post_content,
          }));

          const chunks = Object.entries(jsonData.post_content)
            // .reverse()
            .reduce((acc, [key, value], index) => {
              if (
                (value.textual_content !== null ||
                  value.media_metadata.length !== 0) &&
                !(
                  value.textual_content === null &&
                  value.media_metadata.length === 0
                )
              ) {
                const chunkIndex = Math.floor(index / 2);
                if (!acc[chunkIndex]) {
                  acc[chunkIndex] = {};
                }
                acc[chunkIndex][key] = value;
              }
              return acc;
            }, []);
          console.log({ chunks });

          // setData("chunks");
          setData((prevDataList) => [...prevDataList, ...chunks]);

          setSelectDate(
            chunks[0][Object.keys(chunks[0])[0]].date
              ? chunks[0][Object.keys(chunks[0])[0]].date
              : value
          );
          const timeoutId = setTimeout(() => {
            // Your code to execute after one second
            scrollToActiveDiv();
          }, 2000);
          chunks.forEach((messages) => {
            if (uriId && messages[uriId]) {
              const message = messages[uriId];
              const media_metadata = message.media_metadata;
              if (media_metadata && media_metadata.length === 1) {
                var extension = media_metadata[0]
                  .split(".")
                  .pop()
                  .toLowerCase();
                if (
                  ["jpeg", "jpg", "png", "webp"].includes(extension) === true
                ) {
                  console.log({ media_metadata });
                  setModalImageUrl(media_metadata[0]);
                  openModalload();
                } else if (
                  ["ogg", "amr", "3gp", "aac", "mpeg", "mp3"].includes(
                    extension
                  ) === true
                ) {
                  setModalAudioUrl(media_metadata[0]);
                  openModalload();
                } else if (
                  ["mp4", "webm", "3gpp"].includes(extension) === true
                ) {
                  setModalVideoUrl(media_metadata[0]);
                  openModalload();
                }
              } else if (media_metadata && media_metadata.length >= 2) {
                setModalMultiImageUrl(media_metadata);
                console.log({ media_metadata });
                openMultiModalload();
              }
            }
          });
          // Increment page index
          setPageIndex((prevIndex) => prevIndex + 1);
          console.log({ alldatalist });
        }
      } else {
        navigate("/qrcode");
      }
    } catch (error) {
      navigate("/qrcode");
      console.log("Error fetching data:", error);
    }
  };
  // Function to handle copying the link
  const handleCopyLink = async (data) => {
    const linkToCopy = data; // Get the current page URL
    const textField = document.createElement("textarea");
    textField.value = linkToCopy;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    // Optionally, you can display a message to indicate that the link has been copied
    toast.success("Copied Successfully", {
      // position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000, // Adjust the duration as needed
    });
  };
  const scrollToActiveDiv = () => {
    const activeDiv = document.querySelector(".news-content.active");
    if (activeDiv) {
      activeDiv.scrollIntoView();
    }
  };
  const handlePrevious = () => {
    console.log("handlePrevious", selectDate);
    const currentDate = new Date(value); // Parse the date string into a Date object
    currentDate.setDate(currentDate.getDate() - 1); // Subtract one day
    onChange(currentDate);
    setSelectDate(currentDate);
    console.log("handlePrevious1", currentDate);
  };
  const handleNext = () => {
    console.log("handlePrevious", selectDate);
    const currentDate = new Date(value); // Parse the date string into a Date object
    currentDate.setDate(currentDate.getDate() + 1); // Subtract one day
    onChange(currentDate);
    setSelectDate(currentDate);
    console.log("handlePrevious1", currentDate);
  };

  // Function to open the modal
  const openModalload = () => {
    if (modalInstance) {
      modalInstance.show();
    }
  };
  const openMultiModalload = () => {
    if (modalmultiInstance) {
      modalmultiInstance.show();
    }
  };
  // Function to initialize the modal instance
  const initializeModalOnload = () => {
    const modalElement = document.getElementById("modalviewimageonload");
    if (modalElement) {
      const modal = new Modal(modalElement);
      setModalInstance(modal);
    }
  };
  useEffect(() => {
    initializeModalOnload();
  }, []);
  const initializemultiModalOnload = () => {
    const modalElement = document.getElementById("modalmultipleimagesonload");
    if (modalElement) {
      const modal = new Modal(modalElement);
      setModalMultiInstance(modal);
    }
  };
  useEffect(() => {
    initializemultiModalOnload();
  }, []);

  const calculateGradientColors = (length) => {
    const threshold = 1;
    const ratio = Math.min(length / threshold, 1);
    const startColor = [175, 232, 236]; // Lighter color
    const endColor = [32, 189, 204]; // Darker color
    const interpolatedColors = startColor.map((channel, index) => {
      return Math.round(channel + ratio * (endColor[index] - channel));
    });
    const interpolatedColorString = `rgba(${interpolatedColors.join(", ")}, 1)`;

    return interpolatedColorString;
  };

  const getRandomColor = () => Math.floor(Math.random() * 256);

  const generateRandomColor = () => {
    const red = getRandomColor();
    const green = getRandomColor();
    const blue = getRandomColor();
    return [red, green, blue];
  };

  const calculateRandomGradientColors = () => {
    const startColor = generateRandomColor();
    const endColor = generateRandomColor();
    return {
      startColor: `rgb(${startColor.join(", ")})`,
      endColor: `rgb(${endColor.join(", ")})`,
    };
  };

  const length = datalist ? datalist * 2 : 0; // Example length
  const { startColor, endColor } = calculateRandomGradientColors();

  const gradientStyle = {
    background: `linear-gradient(71deg, ${startColor} 12%, ${endColor} 61%)`,
    // width: "200px",
    // height: "200px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    fontSize: "24px",
    fontWeight: "bold",
    textShadow: "1px 1px 1px rgba(0, 0, 0, 0.5)",
  };
  const [error, setError] = useState(null);

  const handleError = (err) => {
    setError(err);
  };
  useEffect(() => {
    console.log("alldatalist1111", alldatalist);
    // const sortedEntries = Object.entries(alldatalist).sort((a, b) => {
    //   return a[0].localeCompare(b[0]); // Compare keys as strings
    // });
    const originalEntries = Object.entries(alldatalist);

    const sortedEntries = originalEntries.map((entry) => [...entry]);
    console.log({ sortedEntries });
    // Now process the sorted entries
    const chunks = sortedEntries.reduce((acc, [key, value], index) => {
      if (
        (value.textual_content !== null || value.media_metadata.length !== 0) &&
        !(value.textual_content === null && value.media_metadata.length === 0)
      ) {
        const chunkIndex = Math.floor(index / 2);
        if (!acc[chunkIndex]) {
          acc[chunkIndex] = {};
        }
        acc[chunkIndex][key] = value;
      }
      return acc;
    }, []);

    console.log("filterdelete", chunks);
    console.log({ chunks });
    // setData(chunks);
    setData(chunks);

    // Your logic here
  }, [alldatalist]); // Add alldatalist as a dependency
  const handleDelete = async (data) => {
    // Display a confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this post item?"
    );

    // If user confirms, proceed with the axios request
    if (isConfirmed) {
      try {
        console.log("delete", alldatalist);

        setAllData((prevIndex) => {
          const newData = { ...prevIndex };
          delete newData[data];
          return newData;
        });

        const response = await axios.post(
          process.env.REACT_APP_APIURL + "delete-post-item",
          {
            "site-name": subdomain,
            uuid: data,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log({ response });
        // console.log(response.data.Code);
        if (response.data && response.data.Code === "Success") {
          console.log("Delete request successful.");
        } else {
          console.log("Unexpected response1:", response.data);
        }
      } catch (error) {
        // Handle error
      }
    }
  };

  const xorDecrypt = (encryptedData, key) => {
    // Convert hexadecimal string to array of bytes
    const hexToBytes = (hex) => {
      const bytes = [];
      for (let i = 0; i < hex.length; i += 2) {
        bytes.push(parseInt(hex.substr(i, 2), 16));
      }
      return bytes;
    };

    // Perform XOR operation on two arrays of bytes
    const xorBytes = (arr1, arr2) => {
      const result = [];
      for (let i = 0; i < arr1.length; i++) {
        result.push(arr1[i] ^ arr2[i % arr2.length]);
      }
      return result;
    };

    const encryptedBytes = hexToBytes(encryptedData);
    const keyBytes = new TextEncoder().encode(key);

    // XOR operation
    const decryptedBytes = xorBytes(encryptedBytes, keyBytes);

    // Convert bytes to string
    const decryptedText = String.fromCharCode(...decryptedBytes);

    return decryptedText;
  };
  const openDeleteModalload = () => {
    if (modaldeleteInstance) {
      modaldeleteInstance.show();
    }
  };
  // Function to initialize the modal instance
  const initializeModaldelete = () => {
    const modalElement = document.getElementById("modaldelete");
    if (modalElement) {
      const modal = new Modal(modalElement);
      setModalDeleteInstance(modal);
    }
  };
  useEffect(() => {
    initializeModaldelete();
  }, []);

  useEffect(() => {
    const stripNonNumeric = (str) => {
      return str.replace(/\D/g, "");
    };

    // Check if the environment variable is available
    if (!process.env.REACT_APP_REDIRECT_PHONE) {
      console.error("REACT_APP_REDIRECT_PHONE environment variable not found.");
      return;
    }

    // Detect if the user is using a mobile device
    const userAgent = navigator.userAgent;
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      )
    );

    // Fetch user's IP address
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        setUserIP(data.ip);
        // Fetch geolocation information based on the user's IP address
        fetch(
          `https://ipinfo.io/${data.ip}/json?token=${process.env.REACT_APP_IP_TOKEN}`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("data", data);
            setUserCountry(data.country);
            if (data.country === "US") {
              // setQRCodeUrl(
              //   `sms:${process.env.REACT_APP_REDIRECT_PHONE}&body=Please set up a website for me`
              // );
              const phoneNumber = encodeURIComponent(
                process.env.REACT_APP_REDIRECT_PHONE
              );
              const messageBody = encodeURIComponent("");
              const userAgent =
                navigator.userAgent || navigator.vendor || window.opera;
              if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
                const smsUri = `sms:+${encodeURIComponent(
                  process.env.REACT_APP_REDIRECT_PHONE
                )}&body=${messageBody}`;
                setSMSQRCodeUrl(smsUri);
              } else if (/android/i.test(userAgent)) {
                const smsUri = `sms:+${phoneNumber}?body=${messageBody}`;
                setSMSQRCodeUrl(smsUri);
              } else {
                const smsUri = `sms:+${phoneNumber}?body=${messageBody}`;
                setSMSQRCodeUrl(smsUri);
              }
            } else if (data.country === "IN") {
              setSMSQRCodeUrl(
                `https://wa.me/${stripNonNumeric(
                  process.env.REACT_APP_REDIRECT_PHONE
                )}?text=${encodeURIComponent("")}`
              );
            } else {
              setSMSQRCodeUrl(
                `https://wa.me/${stripNonNumeric(
                  process.env.REACT_APP_REDIRECT_PHONE
                )}?text=${encodeURIComponent("")}`
              );
            }
          })
          .catch((error) => {
            console.error("Error fetching user's country:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching user's IP Address1:", error);
      });
  }, []);

  //multi delete
  const handleCheckboxChange = (e) => {
    console.log(e.target.value);
    const { id, checked } = e.target;
    setCheckboxValues((prevState) => ({
      ...prevState,
      [id]: checked,
    }));

    if (checked) {
      setShowCheckboxes(true);
      setShowDeleteOptions(true);
    } else {
      const updatedCheckboxValues = {
        ...checkboxValues,
        [id]: false, // Update the state directly before checking for any checkbox being checked
      };
      setCheckboxValues(updatedCheckboxValues);
      const anyCheckboxChecked = Object.values(updatedCheckboxValues).some(
        (value) => value
      );
      if (!anyCheckboxChecked) {
        setShowDeleteOptions(false);
      }
    }
  };

  const handleClosedelete = () => {
    setShowCheckboxes(false);
    setShowDeleteOptions(false);
    setCheckboxValues({});
  };

  const handlemultidelete = async () => {
    console.log({ checkboxValues });

    try {
      console.log("delete11", alldatalist);

      setAllData((prevIndex) => {
        const newData = { ...prevIndex };
        for (const key in checkboxValues) {
          if (checkboxValues[key]) {
            // Delete the property if its value is true
            delete newData[key];
          }
        }
        return newData;
      });
      for (const key in checkboxValues) {
        if (checkboxValues[key]) {
          const response = await axios.post(
            process.env.REACT_APP_APIURL + "delete-post-item",
            {
              "site-name": subdomain,
              uuid: key, // Set uuid to the key of the checkboxValues object
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          console.log({ response });
          if (response.data && response.data.Code === "Success") {
            console.log("Delete request successful for UUID:", key);

            // Perform any additional actions upon successful deletion
          } else {
            console.log("Unexpected response1:", response.data);
          }
        }
      }
      handleClosedelete();
    } catch (error) {
      // Handle error
    }
  };

  const handleCheckAll = (e) => {
    const isChecked = e.target.checked;

    // Select all checkboxes by their class name
    const checkboxes = document.querySelectorAll(".checkboxdel");

    checkboxes.forEach((checkbox) => {
      checkbox.checked = isChecked; // Set the checked attribute
    });

    const updatedCheckboxValues = {};

    // Iterate over all data items and set their checkboxes to isChecked
    datalist.forEach((row) => {
      Object.keys(row).forEach((postId) => {
        updatedCheckboxValues[postId] = isChecked;
      });
    });

    setCheckboxValues(updatedCheckboxValues);
    setShowCheckboxes(isChecked);
    setShowDeleteOptions(isChecked);
  };

  const downloadFile = async (src, extension) => {
    const fileName = `phonebook.${extension}`;
    const link = document.createElement("a");
    link.href = src;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <main>
      {/* shop area start */}
      <section className="tp-shop-area pt-65 pb-120">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-xl-12">
              <div className="tp-shop-main-wrapper bg-white">
                <div className="tp_profilephoto_wrapper">
                  <div
                    className="tp_profilephoto_imgwrapper"
                    style={!profile || profile === "None" ? gradientStyle : {}}
                  >
                    {profile !== "None" && profile ? (
                      <img
                        src={profile + "?timestamp=" + new Date().getTime()}
                        alt="Profile"
                        border="0"
                      />
                    ) : (
                      ""
                    )}
                  </div>

                  {/* <div className="w-100 text-center mt-4 mb-25">
                    <h2>
                      Today{" "}
                      <span
                        className="crsr_pntr f_lightblue"
                        data-bs-toggle="modal"
                        data-bs-target="#modalcalendar"
                      >
                        <i className="fa-regular fa-calendar-days ms-3 f_30" />
                      </span>
                    </h2>
                  </div> */}
                </div>
                <div className="aboutme_wrapper mt-15">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-10 col-md-8 col-lg-6">
                        <p>{about_me}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tp_image_gallery">
                  {/* <div className="container"> */}
                  <section className="white-bg padding-top-bottom">
                    <div className="container">
                      <div className="timeline">
                        {/* <div className="date-title">
                            <span>
                              <a href="#">
                                <span
                                  className="p-0 me-2 datearrow"
                                  data-bs-toggle="modal"
                                  data-bs-target="#modalcalendar"
                                >
                                  <i className="fa-regular fa-arrow-left-long" />
                                </span>
                              </a>
                              {datalist && datalist.length > 0
                                ? formatDate(
                                    new Date(
                                      datalist[0][
                                        Object.keys(datalist[0])[0]
                                      ].date
                                    ),
                                    "cal"
                                  )
                                : formatDate(value, "cal")}

                              <a href="#">
                                <span
                                  className="p-0 ms-2 datearrow"
                                  data-bs-toggle="modal"
                                  data-bs-target="#modalcalendar"
                                >
                                  <i className="fa-regular fa-arrow-right-long" />
                                </span>
                              </a>
                            </span>
                          </div> */}

                        {isDelete === true &&
                        datalist &&
                        datalist.length > 0 ? (
                          datalist.map((row, rowIndex) => (
                            <div className="row" key={rowIndex}>
                              {Object.entries(row).map(
                                ([postId, postData], index) => {
                                  let extension, extension1;
                                  if (index === 0) {
                                    extension = postData.media_metadata[0]
                                      ? postData.media_metadata[0]
                                          .split(".")
                                          .pop()
                                          .toLowerCase()
                                      : postData.textual_content.length !== 0
                                      ? postData.textual_content
                                          .split(".")
                                          .pop()
                                          .toLowerCase()
                                      : "";
                                  }
                                  if (index === 1) {
                                    extension1 = postData.media_metadata[0]
                                      ? postData.media_metadata[0]
                                          .split(".")
                                          .pop()
                                          .toLowerCase()
                                      : postData.textual_content.length !== 0
                                      ? postData.textual_content
                                          .split(".")
                                          .pop()
                                          .toLowerCase()
                                      : "";
                                  }
                                  return (
                                    <React.Fragment key={postId}>
                                      {["jpeg", "jpg", "png", "webp"].includes(
                                        extension
                                      ) === true && index === 0 ? (
                                        <div
                                          className="col-12 col-sm-12 col-md-6 news-item"
                                          onMouseEnter={handleHover}
                                        >
                                          <div
                                            className={`news-content ${
                                              uriId && uriId === postId
                                                ? "active"
                                                : ""
                                            }`}
                                          >
                                            {showCheckboxes && (
                                              <div className="chkbx_div">
                                                <div className="chkbx_innerdiv">
                                                  <input
                                                    className="form-check-input checkboxdel"
                                                    type="checkbox"
                                                    id={postId} // Use template literals for dynamic ids
                                                    value={postId}
                                                    aria-label="..."
                                                    onChange={
                                                      handleCheckboxChange
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            )}

                                            {postData.media_metadata.length >=
                                            2 ? (
                                              <div>
                                                <div
                                                  id={`carouselphotoIndicators${rowIndex}${index}`}
                                                  className="carousel slide"
                                                  data-bs-ride="carousel"
                                                  data-bs-pause="false"
                                                >
                                                  <div
                                                    className="carousel-inner"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#modalmultipleimages"
                                                    onClick={() =>
                                                      openMultiModal(
                                                        postData.media_metadata
                                                      )
                                                    }
                                                  >
                                                    {postData.media_metadata.map(
                                                      (rows, metaindex) => (
                                                        <div
                                                          key={metaindex}
                                                          className={`carousel-item ${
                                                            metaindex === 0
                                                              ? "active"
                                                              : ""
                                                          }`}
                                                        >
                                                          {/* <Media
                                                              path={`${rows}`}
                                                            /> */}

                                                          <img
                                                            className=""
                                                            src={rows}
                                                            alt="Media"
                                                          />
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                  <button
                                                    className="carousel-control-prev"
                                                    type="button"
                                                    data-bs-target={`#carouselphotoIndicators${rowIndex}${index}`}
                                                    data-bs-slide="prev"
                                                  >
                                                    <span
                                                      className="carousel-control-prev-icon"
                                                      aria-hidden="true"
                                                    >
                                                      <i className="fa-solid fa-chevron-left" />
                                                    </span>
                                                    <span className="visually-hidden">
                                                      Previous
                                                    </span>
                                                  </button>
                                                  <button
                                                    className="carousel-control-next"
                                                    type="button"
                                                    data-bs-target={`#carouselphotoIndicators${rowIndex}${index}`}
                                                    data-bs-slide="next"
                                                  >
                                                    <span
                                                      className="carousel-control-next-icon"
                                                      aria-hidden="true"
                                                    >
                                                      <i className="fa-solid fa-chevron-right" />
                                                    </span>
                                                    <span className="visually-hidden">
                                                      Next
                                                    </span>
                                                  </button>
                                                  <div className="dsply_type">
                                                    <i className="fa-regular fa-images me-2" />
                                                    {
                                                      postData.media_metadata
                                                        .length
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <div
                                                className="news-media"
                                                data-bs-toggle="modal"
                                                data-bs-target="#modalviewimage"
                                                onClick={() =>
                                                  openModal(
                                                    postData.media_metadata[0]
                                                  )
                                                }
                                              >
                                                <img
                                                  className="img-responsive"
                                                  src={
                                                    postData.media_metadata[0]
                                                  }
                                                  alt="Media"
                                                />
                                              </div>
                                            )}

                                            {postData.textual_content !==
                                              null &&
                                            postData.textual_content.length !==
                                              0 ? (
                                              <Media
                                                path={`${postData.textual_content}`}
                                              />
                                            ) : (
                                              ""
                                            )}
                                            <div class="allcontent_wrapper mt-3">
                                              <div className="doc_type">
                                                <span
                                                  className="crsr_pntr"
                                                  onClick={() =>
                                                    handleDelete(postId)
                                                  }
                                                >
                                                  <i className="fa-regular fa-trash-can" />
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="timestamp pt-2">
                                            {formatDate(postData.date, "post")}
                                          </div>
                                        </div>
                                      ) : index === 0 && extension === "txt" ? (
                                        <div
                                          className="col-12 col-sm-12 col-md-6 news-item"
                                          onMouseEnter={handleHover}
                                        >
                                          <div
                                            className={`news-content ${
                                              uriId && uriId === postId
                                                ? "active"
                                                : ""
                                            }`}
                                          >
                                            {showCheckboxes && (
                                              <div className="chkbx_div">
                                                <div className="chkbx_innerdiv">
                                                  <input
                                                    className="form-check-input checkboxdel"
                                                    type="checkbox"
                                                    id={postId} // Use template literals for dynamic ids
                                                    value={postId}
                                                    aria-label="..."
                                                    onChange={
                                                      handleCheckboxChange
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            )}

                                            <Media
                                              path={`${postData.textual_content}`}
                                            />
                                            <div class="allcontent_wrapper mt-3">
                                              <div className="doc_type">
                                                <span
                                                  className="crsr_pntr"
                                                  onClick={() =>
                                                    handleDelete(postId)
                                                  }
                                                >
                                                  <i className="fa-regular fa-trash-can" />
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="timestamp pt-2">
                                            {formatDate(postData.date, "post")}
                                          </div>
                                        </div>
                                      ) : [
                                          "ogg",
                                          "amr",
                                          "3gp",
                                          "aac",
                                          "mpeg",
                                          "mp3",
                                        ].includes(extension) === true &&
                                        index === 0 ? (
                                        <div
                                          className="col-12 col-sm-12 col-md-6 news-item"
                                          onMouseEnter={handleHover}
                                        >
                                          <div
                                            className={`news-content ${
                                              uriId && uriId === postId
                                                ? "active"
                                                : ""
                                            }`}
                                          >
                                            {showCheckboxes && (
                                              <div className="chkbx_div">
                                                <div className="chkbx_innerdiv">
                                                  <input
                                                    className="form-check-input checkboxdel"
                                                    type="checkbox"
                                                    id={postId} // Use template literals for dynamic ids
                                                    value={postId}
                                                    aria-label="..."
                                                    onChange={
                                                      handleCheckboxChange
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            )}

                                            <div>
                                              <div className="videoWrapper audio">
                                                <audio controls>
                                                  <source
                                                    src={`${postData.media_metadata[0]}`}
                                                    type="audio/mp3"
                                                  />
                                                  Your browser does not support
                                                  the audio element.
                                                </audio>
                                              </div>
                                              {postData.textual_content !==
                                                null &&
                                              postData.textual_content
                                                .length !== 0 ? (
                                                <Media
                                                  path={`${postData.textual_content}`}
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <div class="allcontent_wrapper mt-3">
                                              <div className="doc_type">
                                                <span
                                                  className="crsr_pntr"
                                                  onClick={() =>
                                                    handleDelete(postId)
                                                  }
                                                >
                                                  <i className="fa-regular fa-trash-can" />
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="timestamp pt-2">
                                            {formatDate(postData.date, "post")}
                                          </div>
                                        </div>
                                      ) : ["mp4", "webm", "3gpp"].includes(
                                          extension
                                        ) === true && index === 0 ? (
                                        <div
                                          className="col-12 col-sm-12 col-md-6 news-item"
                                          onMouseEnter={handleHover}
                                        >
                                          <div
                                            className={`news-content ${
                                              uriId && uriId === postId
                                                ? "active"
                                                : ""
                                            }`}
                                          >
                                            {showCheckboxes && (
                                              <div className="chkbx_div">
                                                <div className="chkbx_innerdiv">
                                                  <input
                                                    className="form-check-input checkboxdel"
                                                    type="checkbox"
                                                    id={postId} // Use template literals for dynamic ids
                                                    value={postId}
                                                    aria-label="..."
                                                    onChange={
                                                      handleCheckboxChange
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            )}

                                            <div>
                                              <div className="videoWrapper">
                                                <video controls>
                                                  <source
                                                    src={`${postData.media_metadata[0]}`}
                                                    type="video/mp4"
                                                  />
                                                  Your browser does not support
                                                  the video element.
                                                </video>
                                              </div>
                                              {/* <Media
                                                  path={`${postData.media_metadata[0]}`}
                                                /> */}
                                              {postData.textual_content !==
                                                null &&
                                              postData.textual_content
                                                .length !== 0 ? (
                                                <Media
                                                  path={`${postData.textual_content}`}
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <div class="allcontent_wrapper mt-3">
                                              <div className="doc_type">
                                                <span
                                                  className="crsr_pntr"
                                                  onClick={() =>
                                                    handleDelete(postId)
                                                  }
                                                >
                                                  <i className="fa-regular fa-trash-can" />
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="timestamp pt-2">
                                            {formatDate(postData.date, "post")}
                                          </div>
                                        </div>
                                      ) : ["pdf"].includes(extension) ===
                                          true && index === 0 ? (
                                        <div
                                          className="col-12 col-sm-12 col-md-6 news-item"
                                          onMouseEnter={handleHover}
                                        >
                                          <div
                                            className={`news-content ${
                                              uriId && uriId === postId
                                                ? "active"
                                                : ""
                                            }`}
                                          >
                                            {showCheckboxes && (
                                              <div className="chkbx_div">
                                                <div className="chkbx_innerdiv">
                                                  <input
                                                    className="form-check-input checkboxdel"
                                                    type="checkbox"
                                                    id={postId} // Use template literals for dynamic ids
                                                    value={postId}
                                                    aria-label="..."
                                                    onChange={
                                                      handleCheckboxChange
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            )}

                                            <div
                                              data-bs-toggle="modal"
                                              data-bs-target="#modalviewpdf"
                                              onClick={() =>
                                                openModalPdf(
                                                  postData.media_metadata[0]
                                                )
                                              }
                                            >
                                              <h2 className="news-title">
                                                <span className="pdf">
                                                  <i className="fa-regular fa-file-pdf" />
                                                </span>
                                              </h2>

                                              {/* <PDFViewerComponent
                                                  pdfUrl={
                                                    postData.media_metadata[0]
                                                  }
                                                /> */}

                                              {postData.textual_content !==
                                                null &&
                                              postData.textual_content
                                                .length !== 0 ? (
                                                <Media
                                                  path={`${postData.textual_content}`}
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <div class="allcontent_wrapper mt-3">
                                              <div className="doc_type">
                                                <span
                                                  className="crsr_pntr"
                                                  onClick={() =>
                                                    handleDelete(postId)
                                                  }
                                                >
                                                  <i className="fa-regular fa-trash-can" />
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="timestamp pt-2">
                                            {formatDate(postData.date, "post")}
                                          </div>
                                        </div>
                                      ) : index === 0 ? (
                                        <div
                                          className="col-12 col-sm-12 col-md-6 news-item"
                                          onMouseEnter={handleHover}
                                        >
                                          <div
                                            className={`news-content ${
                                              uriId && uriId === postId
                                                ? "active"
                                                : ""
                                            }`}
                                          >
                                            {showCheckboxes && (
                                              <div className="chkbx_div">
                                                <div className="chkbx_innerdiv">
                                                  <input
                                                    className="form-check-input checkboxdel"
                                                    type="checkbox"
                                                    id={postId} // Use template literals for dynamic ids
                                                    value={postId}
                                                    aria-label="..."
                                                    onChange={
                                                      handleCheckboxChange
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            )}

                                            <div>
                                              <h2 className="news-title">
                                                <span className="pdf">
                                                  {extension === "msword" ||
                                                  extension === "docx" ||
                                                  extension === "doc" ? (
                                                    <i className="fa-regular fa-file-word attachtype_word" />
                                                  ) : extension === "csv" ||
                                                    extension === "sheet" ||
                                                    extension === "xls" ||
                                                    extension === "ms-excel" ||
                                                    extension === "xlsx" ? (
                                                    <i className="fa-regular fa-file-excel" />
                                                  ) : extension === "pptx" ||
                                                    extension === "ppt" ? (
                                                    <i className="fa-regular fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                  ) : (
                                                    <i className="fa-regular fa-file" />
                                                  )}
                                                </span>
                                              </h2>

                                              {postData.textual_content !==
                                                null &&
                                              postData.textual_content
                                                .length !== 0 ? (
                                                <Media
                                                  path={`${postData.textual_content}`}
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <div class="allcontent_wrapper mt-3">
                                              <div className="doc_type">
                                                <span
                                                  className="crsr_pntr"
                                                  onClick={() =>
                                                    handleDelete(postId)
                                                  }
                                                >
                                                  <i className="fa-regular fa-trash-can" />
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="timestamp pt-2">
                                            {formatDate(postData.date, "post")}
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {/* Rightside */}
                                      {["jpeg", "jpg", "png", "webp"].includes(
                                        extension1
                                      ) === true && index === 1 ? (
                                        <div
                                          className="col-12 col-sm-12 col-md-6 news-item right"
                                          onMouseEnter={handleHover}
                                        >
                                          <div
                                            className={`news-content ${
                                              uriId && uriId === postId
                                                ? "active"
                                                : ""
                                            }`}
                                          >
                                            {showCheckboxes && (
                                              <div className="chkbx_div">
                                                <div className="chkbx_innerdiv">
                                                  <input
                                                    className="form-check-input checkboxdel"
                                                    type="checkbox"
                                                    id={postId} // Use template literals for dynamic ids
                                                    value={postId}
                                                    aria-label="..."
                                                    onChange={
                                                      handleCheckboxChange
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            )}

                                            {postData.media_metadata.length >=
                                            2 ? (
                                              <div>
                                                <div
                                                  id={`carouselphotoIndicators${rowIndex}${index}`}
                                                  className="carousel slide"
                                                  data-bs-ride="carousel"
                                                  data-bs-pause="false"
                                                >
                                                  <div
                                                    className="carousel-inner"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#modalmultipleimages"
                                                    onClick={() =>
                                                      openMultiModal(
                                                        postData.media_metadata
                                                      )
                                                    }
                                                  >
                                                    {postData.media_metadata.map(
                                                      (rows, metaindex) => (
                                                        <div
                                                          key={metaindex}
                                                          className={`carousel-item ${
                                                            metaindex === 0
                                                              ? "active"
                                                              : ""
                                                          }`}
                                                        >
                                                          {/* <Media
                                                              path={`${rows}`}
                                                            /> */}
                                                          <img
                                                            className=""
                                                            src={rows}
                                                            alt="Media"
                                                          />
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                  <button
                                                    className="carousel-control-prev"
                                                    type="button"
                                                    data-bs-target={`#carouselphotoIndicators${rowIndex}${index}`}
                                                    data-bs-slide="prev"
                                                  >
                                                    <span
                                                      className="carousel-control-prev-icon"
                                                      aria-hidden="true"
                                                    >
                                                      <i className="fa-solid fa-chevron-left" />
                                                    </span>
                                                    <span className="visually-hidden">
                                                      Previous
                                                    </span>
                                                  </button>
                                                  <button
                                                    className="carousel-control-next"
                                                    type="button"
                                                    data-bs-target={`#carouselphotoIndicators${rowIndex}${index}`}
                                                    data-bs-slide="next"
                                                  >
                                                    <span
                                                      className="carousel-control-next-icon"
                                                      aria-hidden="true"
                                                    >
                                                      <i className="fa-solid fa-chevron-right" />
                                                    </span>
                                                    <span className="visually-hidden">
                                                      Next
                                                    </span>
                                                  </button>
                                                  <div className="dsply_type">
                                                    <i className="fa-regular fa-images me-2" />
                                                    {
                                                      postData.media_metadata
                                                        .length
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <div
                                                className="news-media"
                                                data-bs-toggle="modal"
                                                data-bs-target="#modalviewimage"
                                                onClick={() =>
                                                  openModal(
                                                    postData.media_metadata[0]
                                                  )
                                                }
                                              >
                                                <img
                                                  className="img-responsive"
                                                  src={
                                                    postData.media_metadata[0]
                                                  }
                                                  alt="Media"
                                                />
                                              </div>
                                            )}
                                            {postData.textual_content !==
                                              null &&
                                            postData.textual_content.length !==
                                              0 ? (
                                              <Media
                                                path={`${postData.textual_content}`}
                                              />
                                            ) : (
                                              ""
                                            )}
                                            <div class="allcontent_wrapper mt-3">
                                              <div className="doc_type">
                                                <span
                                                  className="crsr_pntr"
                                                  onClick={() =>
                                                    handleDelete(postId)
                                                  }
                                                >
                                                  <i className="fa-regular fa-trash-can" />
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="timestamp txtalgn_rgt pt-2">
                                            {formatDate(postData.date, "post")}
                                          </div>
                                        </div>
                                      ) : index === 1 &&
                                        extension1 === "txt" ? (
                                        <div
                                          className="col-12 col-sm-12 col-md-6 news-item right"
                                          onMouseEnter={handleHover}
                                        >
                                          <div
                                            className={`news-content ${
                                              uriId && uriId === postId
                                                ? "active"
                                                : ""
                                            }`}
                                          >
                                            {showCheckboxes && (
                                              <div className="chkbx_div">
                                                <div className="chkbx_innerdiv">
                                                  <input
                                                    className="form-check-input checkboxdel"
                                                    type="checkbox"
                                                    id={postId} // Use template literals for dynamic ids
                                                    value={postId}
                                                    aria-label="..."
                                                    onChange={
                                                      handleCheckboxChange
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            )}

                                            <Media
                                              path={`${postData.textual_content}`}
                                            />
                                            <div class="allcontent_wrapper mt-3">
                                              <div className="doc_type">
                                                <span
                                                  className="crsr_pntr"
                                                  onClick={() =>
                                                    handleDelete(postId)
                                                  }
                                                >
                                                  <i className="fa-regular fa-trash-can" />
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="timestamp txtalgn_rgt pt-2">
                                            {formatDate(postData.date, "post")}
                                          </div>
                                        </div>
                                      ) : [
                                          "ogg",
                                          "amr",
                                          "3gp",
                                          "aac",
                                          "mpeg",
                                          "mp3",
                                        ].includes(extension1) === true &&
                                        index === 1 ? (
                                        <div
                                          className="col-12 col-sm-12 col-md-6 news-item right"
                                          onMouseEnter={handleHover}
                                        >
                                          <div
                                            className={`news-content ${
                                              uriId && uriId === postId
                                                ? "active"
                                                : ""
                                            }`}
                                          >
                                            {showCheckboxes && (
                                              <div className="chkbx_div">
                                                <div className="chkbx_innerdiv">
                                                  <input
                                                    className="form-check-input checkboxdel"
                                                    type="checkbox"
                                                    id={postId} // Use template literals for dynamic ids
                                                    value={postId}
                                                    aria-label="..."
                                                    onChange={
                                                      handleCheckboxChange
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            )}

                                            <div>
                                              <div className="videoWrapper audio">
                                                <audio controls>
                                                  <source
                                                    src={`${postData.media_metadata[0]}`}
                                                    type="audio/mp3"
                                                  />
                                                  Your browser does not support
                                                  the audio element.
                                                </audio>
                                              </div>
                                              {postData.textual_content !==
                                                null &&
                                              postData.textual_content
                                                .length !== 0 ? (
                                                <Media
                                                  path={`${postData.textual_content}`}
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <div class="allcontent_wrapper mt-3">
                                              <div className="doc_type">
                                                <span
                                                  className="crsr_pntr"
                                                  onClick={() =>
                                                    handleDelete(postId)
                                                  }
                                                >
                                                  <i className="fa-regular fa-trash-can" />
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="timestamp txtalgn_rgt pt-2">
                                            {formatDate(postData.date, "post")}
                                          </div>
                                        </div>
                                      ) : ["mp4", "webm", "3gpp"].includes(
                                          extension1
                                        ) === true && index === 1 ? (
                                        <div
                                          className="col-12 col-sm-12 col-md-6 news-item right"
                                          onMouseEnter={handleHover}
                                        >
                                          <div
                                            className={`news-content ${
                                              uriId && uriId === postId
                                                ? "active"
                                                : ""
                                            }`}
                                          >
                                            {showCheckboxes && (
                                              <div className="chkbx_div">
                                                <div className="chkbx_innerdiv">
                                                  <input
                                                    className="form-check-input checkboxdel"
                                                    type="checkbox"
                                                    id={postId} // Use template literals for dynamic ids
                                                    value={postId}
                                                    aria-label="..."
                                                    onChange={
                                                      handleCheckboxChange
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            )}

                                            <div>
                                              <div className="videoWrapper">
                                                <video controls>
                                                  <source
                                                    src={`${postData.media_metadata[0]}`}
                                                    type="video/mp4"
                                                  />
                                                  Your browser does not support
                                                  the video element.
                                                </video>
                                              </div>
                                              {postData.textual_content !==
                                                null &&
                                              postData.textual_content
                                                .length !== 0 ? (
                                                <Media
                                                  path={`${postData.textual_content}`}
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <div class="allcontent_wrapper mt-3">
                                              <div className="doc_type">
                                                <span
                                                  className="crsr_pntr"
                                                  onClick={() =>
                                                    handleDelete(postId)
                                                  }
                                                >
                                                  <i className="fa-regular fa-trash-can" />
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="timestamp txtalgn_rgt pt-2">
                                            {formatDate(postData.date, "post")}
                                          </div>
                                        </div>
                                      ) : ["pdf"].includes(extension1) ===
                                          true && index === 1 ? (
                                        <div
                                          className="col-12 col-sm-12 col-md-6 news-item right"
                                          onMouseEnter={handleHover}
                                        >
                                          <div
                                            className={`news-content ${
                                              uriId && uriId === postId
                                                ? "active"
                                                : ""
                                            }`}
                                          >
                                            {showCheckboxes && (
                                              <div className="chkbx_div">
                                                <div className="chkbx_innerdiv">
                                                  <input
                                                    className="form-check-input checkboxdel"
                                                    type="checkbox"
                                                    id={postId} // Use template literals for dynamic ids
                                                    value={postId}
                                                    aria-label="..."
                                                    onChange={
                                                      handleCheckboxChange
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            )}

                                            <div
                                              data-bs-toggle="modal"
                                              data-bs-target="#modalviewpdf"
                                              onClick={() =>
                                                openModalPdf(
                                                  postData.media_metadata[0]
                                                )
                                              }
                                            >
                                              {/* <PDFViewerComponent
                                                  pdfUrl={
                                                    postData.media_metadata[0]
                                                  }
                                                /> */}
                                              <h2 className="news-title">
                                                <span className="pdf">
                                                  <i className="fa-regular fa-file-pdf" />
                                                </span>
                                              </h2>
                                              {postData.textual_content !==
                                                null &&
                                              postData.textual_content
                                                .length !== 0 ? (
                                                <Media
                                                  path={`${postData.textual_content}`}
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <div class="allcontent_wrapper mt-3">
                                              <div className="doc_type">
                                                <span
                                                  className="crsr_pntr"
                                                  onClick={() =>
                                                    handleDelete(postId)
                                                  }
                                                >
                                                  <i className="fa-regular fa-trash-can" />
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="timestamp txtalgn_rgt pt-2">
                                            {formatDate(postData.date, "post")}
                                          </div>
                                        </div>
                                      ) : index === 1 ? (
                                        <div
                                          className="col-12 col-sm-12 col-md-6 news-item right"
                                          onMouseEnter={handleHover}
                                        >
                                          <div
                                            className={`news-content ${
                                              uriId && uriId === postId
                                                ? "active"
                                                : ""
                                            }`}
                                          >
                                            {showCheckboxes && (
                                              <div className="chkbx_div">
                                                <div className="chkbx_innerdiv">
                                                  <input
                                                    className="form-check-input checkboxdel"
                                                    type="checkbox"
                                                    id={postId} // Use template literals for dynamic ids
                                                    value={postId}
                                                    aria-label="..."
                                                    onChange={
                                                      handleCheckboxChange
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            )}

                                            <div>
                                              <h2 className="news-title">
                                                <span className="pdf">
                                                  {extension1 === "msword" ||
                                                  extension1 === "docx" ||
                                                  extension1 === "doc" ? (
                                                    <i className="fa-regular fa-file-word attachtype_word" />
                                                  ) : extension1 === "csv" ||
                                                    extension1 === "sheet" ||
                                                    extension1 === "xls" ||
                                                    extension1 === "ms-excel" ||
                                                    extension1 === "xlsx" ? (
                                                    <i className="fa-regular fa-file-excel" />
                                                  ) : extension1 === "pptx" ||
                                                    extension1 === "ppt" ? (
                                                    <i className="fa-regular fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                  ) : (
                                                    <i className="fa-regular fa-file" />
                                                  )}
                                                </span>
                                              </h2>

                                              {postData.textual_content !==
                                                null &&
                                              postData.textual_content
                                                .length !== 0 ? (
                                                <Media
                                                  path={`${postData.textual_content}`}
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <div class="allcontent_wrapper mt-3">
                                              <div className="doc_type">
                                                <span
                                                  className="crsr_pntr"
                                                  onClick={() =>
                                                    handleDelete(postId)
                                                  }
                                                >
                                                  <i className="fa-regular fa-trash-can" />
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="timestamp txtalgn_rgt pt-2">
                                            {formatDate(postData.date, "post")}
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </div>
                          ))
                        ) : (
                          <>
                            {daydata ? (
                              <div style={{ textAlign: "center" }}>
                                <p>
                                  No data is available for the selected day.
                                </p>
                              </div>
                            ) : isDelete === false ? (
                              openDeleteModalload()
                            ) : (
                              // <div style={{ textAlign: "center" }}>
                              //   <p>
                              //     No data is available for the selected day.
                              //   </p>
                              // </div>
                              <div style={{ textAlign: "center" }}>
                                <i className="fa-solid fa-spinner fa-spin-pulse fa-3x" />
                                <p>Loading...</p>
                              </div>
                            )}
                          </>
                        )}
                        {viewoption === false ? (
                          <div className="date-title">
                            <span
                              className="crsr_pntr"
                              onClick={() => fetchData(pageIndex + 1)}
                            >
                              View More
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                        {/* <div className="date-title">
                            <span>
                              <a href="#" onClick={() => handlePrevious()}>
                                <span
                                  className="datearrow"
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#modalcalendar"
                                >
                                  <i className="fa-regular fa-arrow-left-long" />
                                </span>
                              </a>
                              <a href="#" onClick={() => handleNext()}>
                                <span
                                  className="datearrow"
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#modalcalendar"
                                >
                                  <i className="fa-regular fa-arrow-right-long" />
                                </span>
                              </a>
                            </span>
                          </div> */}
                      </div>
                    </div>
                  </section>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
      {/* model calendar */}
      <div
        className="modal fade"
        id="modalcalendar"
        // data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="modalcalendarLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              // onClick={() => handleClose()}
            >
              <i className="fa-solid fa-xmark" />
            </button>
            <div className="modal-header">
              <h5 className="modal-title" id="modalcalendarLabel">
                Calendar
              </h5>
            </div>
            <div className="modal-body">
              <div className="elegant-calendar justify-content-center d-md-flex pt-25 pb-25">
                <div
                  className="wrap-header d-flex align-items-center img"
                  style={{ backgroundImage: "url(plugins/img/bg.jpg)" }}
                >
                  <p id="reset">Today</p>
                  <div id="header" className="p-0">
                    <div className="head-info text-center">
                      <div className="head-month">{formatDate(value)}</div>
                      <div className="head-day">{value.getDate()}</div>
                    </div>
                  </div>
                </div>
                <div>
                  <Calendar
                    onChange={onChange}
                    value={value}
                    tileDisabled={tileDisabled}
                  />{" "}
                </div>
              </div>
              <div className="w-100 d-flex justify-content-center mt-25">
                <button
                  type="button"
                  className="btn btn-lg btn-success"
                  data-bs-dismiss="modal"
                  onClick={() => handleSubmit()}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* image model */}
      <div
        className="modal fade"
        id="modalviewimage"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="modalviewimageLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fa-solid fa-xmark" />
            </button>

            <div className="modal-body">
              {modalImageUrl ? (
                <div className="modalviewfullimage">
                  <img src={modalImageUrl} alt />
                </div>
              ) : modalVideoUrl ? (
                <div className="videoWrapper">
                  <video controls>
                    <source src={modalVideoUrl} type="video/mp4" />
                    Your browser does not support the video element.
                  </video>
                </div>
              ) : modalAudioUrl ? (
                <div className="news-media">
                  <div className="videoWrapper audio">
                    <audio controls>
                      <source src={modalAudioUrl} type="audio/mp3" />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {/*multiple image model */}
      <div
        className="modal fade"
        id="modalmultipleimages"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="modalmultipleimagesLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fa-solid fa-xmark" />
            </button>
            {/* <div className="modal-header">
              <h5 className="modal-title" id="modalmultipleimagesLabel">
                Office Interior
              </h5>
            </div> */}
            <div className="modal-body">
              <div
                id="carouselphotoIndicators1"
                className="carousel slide"
                data-bs-ride="carousel"
                data-bs-pause="false"
              >
                <div className="carousel-inner">
                  {modalMultiImageUrl.map((rows, metaindex) => (
                    <div
                      key={metaindex}
                      className={`carousel-item ${
                        metaindex === 0 ? "active" : ""
                      }`}
                    >
                      {/* <Media
                        path={`${rows}`}
                      /> */}
                      <img className="" src={rows} alt="Media" />
                    </div>
                  ))}
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselphotoIndicators1"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    >
                      <i className="fa-solid fa-chevron-left" />
                    </span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselphotoIndicators1"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    >
                      <i className="fa-solid fa-chevron-right" />
                    </span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Onload image model */}
      <div
        className="modal fade"
        id="modalviewimageonload"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="modalviewimageLabelonload"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fa-solid fa-xmark" />
            </button>

            <div className="modal-body">
              {modalImageUrl ? (
                <div className="modalviewfullimage">
                  <img src={modalImageUrl} alt />
                </div>
              ) : modalVideoUrl ? (
                <div className="videoWrapper">
                  <video controls>
                    <source src={modalVideoUrl} type="video/mp4" />
                    Your browser does not support the video element.
                  </video>
                </div>
              ) : modalAudioUrl ? (
                <div className="news-media">
                  <div className="videoWrapper audio">
                    <audio controls>
                      <source src={modalAudioUrl} type="audio/mp3" />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {/* onload multiple image model */}
      <div
        className="modal fade"
        id="modalmultipleimagesonload"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="modalmultipleimagesLabelonload"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fa-solid fa-xmark" />
            </button>
            {/* <div className="modal-header">
              <h5 className="modal-title" id="modalmultipleimagesLabel">
                Office Interior
              </h5>
            </div> */}
            <div className="modal-body">
              <div
                id="carouselphotoIndicatorsonload"
                className="carousel slide"
                data-bs-ride="carousel"
                data-bs-pause="false"
              >
                <div className="carousel-inner">
                  {modalMultiImageUrl.map((rows, metaindex) => (
                    <div
                      key={metaindex}
                      className={`carousel-item ${
                        metaindex === 0 ? "active" : ""
                      }`}
                    >
                      {/* <Media
                        path={`${rows}`}
                      /> */}
                      <img className="" src={rows} alt="Media" />
                    </div>
                  ))}
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselphotoIndicatorsonload"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    >
                      <i className="fa-solid fa-chevron-left" />
                    </span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselphotoIndicatorsonload"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    >
                      <i className="fa-solid fa-chevron-right" />
                    </span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* PDF model*/}
      <div
        className="modal fade"
        id="modalviewpdf"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="modalviewpdfLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalPdfUrl("")}
            >
              <i className="fa-solid fa-xmark" />
            </button>

            <div className="modal-body modal_content_hgt">
              {modalPdfUrl ? (
                <div className="">
                  <PDFViewerComponent pdfUrl={modalPdfUrl} />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {/*delete model */}
      <div
        className="modal fade"
        id="modaldelete"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="modaldeleteLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => navigate("/")}
            >
              <i className="fa-solid fa-xmark" />
            </button>

            <div className="modal-body">
              Your /delete request is timed out. Please request again from your
              messaging app (
              <a
                className="f_black txt_underline"
                href={smsqrCodeUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                SMS or WhatsApp
              </a>
              )
            </div>
          </div>
        </div>
      </div>
      {/*multi delete */}
      {showDeleteOptions && (
        <div className="deleoptns_wrapper">
          <div className="deleoptns_content position-relative">
            <div className="me-3 mt-2">
              <a href="#">
                <i
                  className="fa-solid fa-xmark f_20 deleoptns_close f_white"
                  onClick={handleClosedelete}
                />
              </a>
            </div>
            <div className="chkbx_innerdiv">
              <div className="form-check form-check-inline mt-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="checkboxdelall"
                  defaultValue="option1"
                  onChange={handleCheckAll}
                />
                <label
                  className="form-check-label ms-2"
                  htmlFor="checkboxdelall"
                >
                  All
                </label>
              </div>
            </div>
            <div className="ms-2">
              {Object.values(checkboxValues).filter((value) => value).length}{" "}
              selected
            </div>

            <div className="ms-4 me-1">
              <a
                data-bs-toggle="modal"
                data-bs-target="#modalmultidelete"
                className="crsr_pntr"
              >
                <i className="fa-regular fa-trash-can f_20 f_white" />
              </a>
            </div>
          </div>
        </div>
      )}
      {/*multi delete confirm model */}
      <div
        class="modal fade"
        id="modalmultidelete"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="modalmultideleteLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa-solid fa-xmark"></i>
            </button>
            <div class="modal-header">
              <h5 class="modal-title" id="modalmultideleteLabel">
                Delete Items
              </h5>
            </div>
            <div class="modal-body">
              <p class="text-center f_17">
                Are you sure you want to delete the selected items?
              </p>
            </div>
            <div class="modal-footer">
              <a
                class="btn btn-success"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handlemultidelete}
              >
                Yes, delete
              </a>
              <a
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancel
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default DeleteMediaList;

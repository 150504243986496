import React, { useState, useEffect } from "react";
import axios from "axios";

const LinkPreview = ({ url }) => {
  const [loading, setLoading] = useState(false);
  const [previewData, setPreviewData] = useState(null);

  useEffect(() => {
    const fetchUrlMetadata = async () => {
      if (!url) return;

      setLoading(true);
      try {
        const response = await axios.get(
          `https://api.microlink.io/?url=${encodeURIComponent(url)}`
        );
        const { data } = response.data;
        setPreviewData(data);
      } catch (error) {
        console.error("Error fetching URL metadata:", error);
        setPreviewData(null);
      } finally {
        setLoading(false);
      }
    };

    fetchUrlMetadata();
  }, [url]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!previewData) {
    // return <div>No preview available</div>;
    return <div></div>;
  }

  const { title, description, image, video } = previewData;
  const truncateDescription = (text) => {
    if (text && text.length > 70) {
      return text.substring(0, 70) + "...";
    }
    return text;
  };

  return (
    <>
      {
        video ? (
          <div className="preview_content">
            <h2 className="news-title">{truncateDescription(title)}</h2>
            <p className="f_14">{truncateDescription(description)}</p>
            <div className="videoWrapper">
              <video controls>
                <source src={video.url} type="video/mp4" />
                Your browser does not support the video element.
              </video>
            </div>
          </div>
        ) : // Render image only if image is available and URL doesn't contain "youtu"
        (image && !url.includes("youtu")) ||
          // Render image if URL contains "youtu" and "/clip/"
          (image && url.includes("youtu") && url.includes("/clip/")) ? (
          <div className="preview_content">
            <h2 className="news-title">{truncateDescription(title)}</h2>
            <p className="f_14">{truncateDescription(description)}</p>
            <div className="preview_imgdiv">
              <img
                src={image.url}
                alt={title}
                style={{ maxWidth: "100%", maxHeight: "200px" }}
              />
            </div>
          </div>
        ) : null // Return null if conditions aren't met
      }
    </>
  );
};

export default LinkPreview;

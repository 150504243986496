import React, { useState, useEffect } from "react";

const Comments = ({ comurl }) => {
  console.log({ comurl });
  const [commentContent, setCommentContent] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTxtFile = async (url) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Failed to fetch file");
        }
        const text = await response.text();
        return text;
      } catch (error) {
        console.error("Error fetching file:", error);
        throw error; // Rethrow the error to be caught by fetchCommentContent
      }
    };

    const fetchCommentContent = async () => {
      setLoading(true);
      try {
        const text = await fetchTxtFile(comurl);
        setCommentContent(text);
      } catch (error) {
        console.error("Error fetching comment content:", error);
        setCommentContent(""); // Set empty string in case of error
      } finally {
        setLoading(false);
      }
    };

    if (comurl) {
      fetchCommentContent();
    }
  }, [comurl]);

  return (
    <>
      {commentContent !== null && (
        <p
          className="w-100 mt-0"
          dangerouslySetInnerHTML={{ __html: commentContent }}
        ></p>
      )}
    </>
  );
};

export default Comments;
